import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import { Link, useNavigate } from 'react-router-dom';
import ProfileSideMenu from './ProfileSideMenu';
import { Formik, Form } from 'formik';
import { profile } from '../services/web/webServices';
import Backdrop from '@mui/material/Backdrop';
import Spinner from 'react-bootstrap/Spinner';

export default function Profile() {
  const [userDetails, setUserDetails] = useState([]);
  // const [getEmail, setEmail] = useState('');
  const [getLoader, setLoader] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    //    start
    profile()
      .then((res) => {
        setUserDetails(res.data.response);

        setLoader(false);
      })
      .catch((err) => {
        if (err) {
          console.log('==>', err);
        }
      });
  }, []);
  return (
    <>
      <Header />
      <div
        className='section page-banner-section bg-color-1'
        style={{ marginTop: '29px' }}
      >
        <div className='container'>
          {/* <!-- Page Banner Content Start --> */}
          <div className='page-banner-content'>
            <h2 className='title'>View Profile</h2>
            <ul className='breadcrumb justify-content-center'>
              <li className='breadcrumb-item'>
                <Link to='/'>Home</Link>
              </li>
              <li className='breadcrumb-item active'>View Profile</li>
            </ul>
          </div>
          {/* <!-- Page Banner Content End --> */}
        </div>
      </div>
      {getLoader === true ? (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={getLoader}
        >
          <Spinner animation='grow' />
        </Backdrop>
      ) : (
        <>
          <div style={{ clear: 'both' }}></div>
          <div className='container  '>
            <div className='profile-container'>
              <div className='profile-grid'>
                {/* <?php include "profile-side-menu.php" ?> */}
                <ProfileSideMenu />
                <div
                  className='profile-grid-right wow fadeInUp'
                  data-wow-duration='1s'
                  data-wow-delay='.2s'
                  style={{
                    visibility: 'visible',
                    animationDuration: '1s',
                    animationDelay: '0.4s',
                  }}
                >
                  <h2>View Profile</h2>

                  <Formik>
                    <Form>
                      <div className='form-group'>
                        <div className='d-flex'>
                          <label>Name</label>
                          <span>{userDetails.name}</span>
                        </div>
                      </div>
                      <div className='form-group'>
                        <div className='d-flex'>
                          <label>Student Email ID</label>
                          <span>{userDetails?.email}</span>
                        </div>
                      </div>
                      <div className='form-group'>
                        <div className='d-flex'>
                          <label>Student Phone Number</label>
                          <span>{userDetails.phone}</span>
                        </div>
                      </div>
                      <div className='form-group'>
                        <div className='d-flex'>
                          <label>Student Age</label>
                          <span>{userDetails.age}</span>
                        </div>
                      </div>
                      {userDetails?.age < 16 ? (
                        <>
                          <div className='form-group'>
                            <div className='d-flex'>
                              <label>Father Email Id</label>
                              <span>{userDetails?.father_email}</span>
                            </div>
                          </div>
                          <div className='form-group'>
                            <div className='d-flex'>
                              <label>Father Phone Number</label>
                              <span>{userDetails?.father_phone_no}</span>
                            </div>
                          </div>
                        </>
                      ) : (
                        ''
                      )}

                      <div className='buttonOnetoOne edit-profile-button '>
                        <Link
                          to='/editprofile'
                          className='global-button w-100 mb-10 wow fadeInUp'
                          data-wow-duration='1s'
                          data-wow-delay='.3s'
                          style={{
                            visibility: 'visible',
                            animationDuration: '1s',
                            animationDelay: '0.3s',
                          }}
                        >
                          {' '}
                          Edit
                        </Link>
                        <Link
                          to='/changepassword'
                          className='global-button w-100 wow fadeInUp bor-btn'
                          data-wow-duration='1s'
                          data-wow-delay='.3s'
                          style={{
                            visibility: 'visible',
                            animationDuration: '1s',
                            animationDelay: '0.3s',
                          }}
                        >
                          Change Password
                        </Link>
                      </div>
                    </Form>
                  </Formik>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </>
      )}
    </>
  );
}
