import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import InsideHeaderForAbout from "./InsideHeader/InsideHeaderForAbout";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import {
  get_all_about_user,
  get_all_courses_video,
  getDashboard,
} from "../services/web/webServices";
import Backdrop from "@mui/material/Backdrop";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import Header from "./Header";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

export default function Blog() {
  const [getLoader, setLoader] = useState(true);
  const [getAboutUser, setAboutUser] = useState(null);
  const [getCourseVideo, setCourseVideo] = useState(null);
  const [getDetail, setDetail] = useState(null);
  const [userImage1, setUserImage1] = useState("");
  const [userImage2, setUserImage2] = useState("");
  const [userImage3, setUserImage3] = useState("");
  const [userImage4, setUserImage4] = useState("");
  const [userImage5, setUserImage5] = useState("");
  const [userImage6, setUserImage6] = useState("");

  const [openModal1, setOpenModal1] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [openModal3, setOpenModal3] = useState(false);
  const [openModal4, setOpenModal4] = useState(false);
  const [openModal5, setOpenModal5] = useState(false);
  const [openModal6, setOpenModal6] = useState(false);
  const [openModal7, setOpenModal7] = useState(false);
  const [openModal8, setOpenModal8] = useState(false);
  const [openModal9, setOpenModal9] = useState(false);
  const [openModal10, setOpenModal10] = useState(false);

  const handleOpenModal1 = () => setOpenModal1(true);
  const handleCloseModal1 = () => setOpenModal1(false);

  const handleOpenModal2 = () => setOpenModal2(true);
  const handleCloseModal2 = () => setOpenModal2(false);

  const handleOpenModal3 = () => setOpenModal3(true);
  const handleCloseModal3 = () => setOpenModal3(false);

  const handleOpenModal4 = () => setOpenModal4(true);
  const handleCloseModal4 = () => setOpenModal4(false);

  const handleOpenModal5 = () => setOpenModal5(true);
  const handleCloseModal5 = () => setOpenModal5(false);

  const handleOpenModal6 = () => setOpenModal6(true);
  const handleCloseModal6 = () => setOpenModal6(false);

  const handleOpenModal7 = () => setOpenModal7(true);
  const handleCloseModal7 = () => setOpenModal7(false);

  const handleOpenModal8 = () => setOpenModal8(true);
  const handleCloseModal8 = () => setOpenModal8(false);

  const handleOpenModal9 = () => setOpenModal9(true);
  const handleCloseModal9 = () => setOpenModal9(false);

  const handleOpenModal10 = () => setOpenModal10(true);
  const handleCloseModal10 = () => setOpenModal10(false);

  useEffect(() => {
    get_all_about_user().then((res) => {
      setAboutUser(res.data.response);
      console.log("image--------", res.data.response[0].profile_pic);
      setUserImage1(res.data.response[0].profile_pic);
      setUserImage2(res.data.response[1].profile_pic);
      setUserImage3(res.data.response[2].profile_pic);
      setUserImage4(res.data.response[3].profile_pic);
      setUserImage5(res.data.response[4].profile_pic);
      setUserImage6(res.data.response[5].profile_pic);

      // setLoader(false);
    });
    get_all_courses_video().then((res) => {
      setCourseVideo(res.data.response);
    });

    getDashboard()
      .then((res) => {
        setLoader(false);
        setDetail(res.data.response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  /////////////////////////////slinder
  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", right: "30px" }}
        onClick={onClick}
      >
        <span style={{ fontSize: "2rem", color: "black" }}>{">"}</span>
      </div>
    );
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", left: "1px", zIndex: 1 }}
        onClick={onClick}
      >
        <span style={{ fontSize: "2rem", color: "black" }}>{"<"}</span>
      </div>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <>
      {/* <InsideHeaderForAbout /> */}

      {getLoader == true ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={getLoader}
        >
          <Spinner animation="grow" />
        </Backdrop>
      ) : (
        <>
          {/* <div style={{ clear: "both" }}></div> */}
          <div className="slinder-blog">
            <Slider className="slider-section" {...settings}>
              <div
                className="section section-padding bk blog"
                style={{ overflow: "hidden" }}
              >
                {/* <!--   Wrapper Start --> */}
                <div className="call-to-action-wrapper abt ab">
                  <div className="container abt d-flex flex-column flex-lg-row justify-content-between align-items-center quic">
                    {/* <!--   Content Start --> */}

                    {/* <!--   Content End --> */}
                    {/* <!--   Image Start --> */}
                    <div
                      className="call-to-action-content abt wow fadeInLeft"
                      data-wow-duration="1s"
                      data-wow-delay=".2s"
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.4s",
                        maxWidth: "600px",
                        marginBottom: "20px",
                      }}
                    >
                      <div
                        className="section-heading wow fadeInUp"
                        data-wow-duration="1s"
                        data-wow-delay=".2s"
                        style={{
                          visibility: "visible",
                          animationDuration: "1s",
                          animationDelay: "0.2s",
                        }}
                      >
                        <h2 className="bookcoach abt">
                          {" "}
                          <span>How to Keep Kids Motivated </span>to Learn
                          During the School Year
                        </h2>
                        <p style={{ whiteSpace: "normal" }}>
                          {" "}
                          Navigating the school year can sometimes feel like a
                          marathon. The excitement of the first day often fades,
                          leaving students and their support systems, looking
                          for ways to keep the momentum going. Keeping kids
                          motivated to learn is essential for their success and
                          well-being...{" "}
                        </p>
                        {/* <span style={{ fontWeight: "bold", fontSize: "20px" ,fontFamily:"700" ,color:"black"}}>Here are some practical tips to help keep that spark alive throughout the school year...</span> */}
                      </div>

                      <div className="combinebutton">
                        <Link
                          to="/multicoach"
                          className="global-button startbtn"
                          style={{ borderRadius: "15px" }}
                        >
                          Select a Tutor{" "}
                        </Link>
                        <button
                          className="global-button startbtn"
                          style={{
                            borderRadius: "15px",
                            cursor: "pointer",
                            marginLeft: "40px",
                          }}
                          onClick={handleOpenModal1}
                        >
                          Read More
                        </button>
                      </div>
                    </div>

                    <div
                      className="call-to-action-image block-images col-lg-8 wow fadeInRight"
                      data-wow-duration="1s"
                      data-wow-delay=".2s"
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.4s",
                        maxWidth: "600px", // Restricting the image size
                        width: "100%", // Restricting the image size
                      }}
                    >
                      <div
                        className="image"
                        style={{
                          width: "90%",
                          height: "auto",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src="images/Keeping-kids-motivated.png"
                          alt=" "
                          style={{
                            width: "100%",
                            height: "auto",
                            objectFit: "cover",
                            borderRadius: "10px",
                          }}
                        />
                        <div className="dots-bg dots-bg-event  startdot "></div>
                      </div>
                      <div className="box-blog">
                        {/* Box 4: Educational Outings */}
                      </div>
                    </div>
                  </div>

                  {/* <!--   Image End --> */}
                </div>

                {/* <!--   Wrapper End --> */}
              </div>
              <div
                className="section section-padding bk blog"
                style={{ overflow: "hidden" }}
              >
                {/* Wrapper Start */}
                <div className="call-to-action-wrapper abt ab">
                  <div className="container abt d-flex flex-column flex-lg-row justify-content-between align-items-center quic">
                    {/* Content Start */}
                    <div
                      className="call-to-action-content abt wow fadeInLeft"
                      data-wow-duration="1s"
                      data-wow-delay=".2s"
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.4s",
                        maxWidth: "600px",
                        marginBottom: "20px", // Spacing for smaller screens
                      }}
                    >
                      <div
                        className="section-heading wow fadeInUp"
                        data-wow-duration="1s"
                        data-wow-delay=".2s"
                        style={{
                          visibility: "visible",
                          animationDuration: "1s",
                          animationDelay: "0.2s",
                        }}
                      >
                        <h2 className="bookcoach abt">
                          <span>
                            Exploring Different Educational Resources{" "}
                          </span>
                          for Extra Support
                        </h2>
                        <p>
                          Navigating the educational landscape can sometimes
                          feel overwhelming. With so many resources available,
                          how do you choose the best ones to support your
                          child's learning journey? Let’s dive into a selection
                          of books, websites, and apps that can enhance and
                          complement tutoring sessions, ensuring your child gets
                          the most out of their educational experience...
                        </p>
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "20px",
                            fontFamily: "700",
                            color: "black",
                          }}
                        >
                          {/* Books to Boost Learning...////////////// */}
                        </span>
                      </div>

                      <div className="combinebutton">
                        <Link
                          to="/multicoach"
                          className="global-button startbtn"
                          style={{ borderRadius: "15px", marginRight: "20px" }}
                        >
                          Select a Tutor
                        </Link>
                        <button
                          className="global-button startbtn"
                          style={{
                            borderRadius: "15px",
                            cursor: "pointer",
                            marginLeft: "40px",
                          }}
                          onClick={handleOpenModal2}
                        >
                          Read More
                        </button>
                      </div>
                    </div>
                    {/* Content End */}

                    {/* Image Start */}
                    <div
                      className="call-to-action-image block-images col-lg-8 wow fadeInRight"
                      data-wow-duration="1s"
                      data-wow-delay=".2s"
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.4s",
                        maxWidth: "600px", // Restricting the image size
                        width: "100%",
                      }}
                    >
                      <div
                        className="image"
                        style={{
                          width: "89%",
                          height: "auto",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src="images/Exploring-Different-Educational-Resources.png"
                          alt="Exploring Different Educational Resources"
                          style={{
                            width: "100%",
                            height: "auto",
                            objectFit: "cover",
                            borderRadius: "10px",
                          }}
                        />
                        <div className="dots-bg dots-bg-event startdot"></div>
                      </div>
                      <div className="box-blog">
                        {/* Box 4: Educational Outings */}
                      </div>
                    </div>
                    {/* Image End */}
                  </div>
                  {/* Wrapper End */}
                </div>
              </div>
              <div
                className="section section-padding bk blog"
                style={{ overflow: "hidden" }}
              >
                {/* <!--   Wrapper Start --> */}
                <div className="call-to-action-wrapper abt ab">
                  <div className="container abt d-flex flex-column flex-lg-row justify-content-between align-items-center quic">
                    {/* <!--   Content Start --> */}

                    {/* <!--   Content End --> */}
                    {/* <!--   Image Start --> */}
                    <div
                      className="call-to-action-content abt wow fadeInLeft"
                      data-wow-duration="1s"
                      data-wow-delay=".2s"
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.4s",
                        maxWidth: "600px",
                        marginBottom: "20px",
                      }}
                    >
                      <div
                        className="section-heading wow fadeInUp"
                        data-wow-duration="1s"
                        data-wow-delay=".2s"
                        style={{
                          visibility: "visible",
                          animationDuration: "1s",
                          animationDelay: "0.2s",
                        }}
                      >
                        <h2 className="bookcoach abt">
                          {" "}
                          <span>Understanding and Overcoming </span>Learning
                          Disabilities
                        </h2>
                        <p>
                          {" "}
                          Imagine this: your child comes home, backpack slung
                          over one shoulder, and you can see the frustration in
                          their eyes. Homework has become a battle, and no
                          matter how much time is spent, progress seems elusive.
                          If this scenario feels familiar, you’re not alone.
                          Many children face learning disabilities, but with the
                          right support and understanding, they can thrive
                          academically and emotionally...{" "}
                        </p>
                        {/* <span style={{ fontWeight: "bold", fontSize: "20px" ,fontFamily:"700",color:"black" }}>Common Learning Disabilities... </span> */}
                      </div>

                      <div className="combinebutton">
                        <Link
                          to="/multicoach"
                          className="global-button startbtn"
                          style={{ borderRadius: "15px" }}
                        >
                          Select a Tutor{" "}
                        </Link>
                        <button
                          className="global-button startbtn"
                          style={{
                            borderRadius: "15px",
                            cursor: "pointer",
                            marginLeft: "40px",
                          }}
                          onClick={handleOpenModal3}
                        >
                          Read More
                        </button>
                      </div>
                    </div>

                    <div
                      className="call-to-action-image block-images col-lg-8 wow fadeInRight"
                      data-wow-duration="1s"
                      data-wow-delay=".2s"
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.4s",
                        maxWidth: "600px", // Restricting the image size
                        width: "100%",
                      }}
                    >
                      <div
                        className="image"
                        style={{
                          width: "90%",
                          height: "auto",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src="images/Overcoming-Learning-Disabilities.png"
                          alt=" "
                          style={{
                            width: "100%",
                            height: "auto",
                            objectFit: "cover",
                            borderRadius: "10px",
                          }}
                        />
                        <div className="dots-bg dots-bg-event  startdot "></div>
                      </div>
                      <div className="box-blog">
                        {/* Box 4: Educational Outings */}
                      </div>
                    </div>
                  </div>

                  {/* <!--   Image End --> */}
                </div>

                {/* <!--   Wrapper End --> */}
              </div>
              <div
                className="section section-padding bk blog"
                style={{ overflow: "hidden" }}
              >
                {/* <!--   Wrapper Start --> */}
                <div className="call-to-action-wrapper abt ab">
                  <div className="container abt d-flex flex-column flex-lg-row justify-content-between align-items-center quic">
                    {/* <!--   Content Start --> */}

                    {/* <!--   Content End --> */}
                    {/* <!--   Image Start --> */}

                    <div
                      className="call-to-action-content abt wow fadeInLeft"
                      data-wow-duration="1s"
                      data-wow-delay=".2s"
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.4s",
                        maxWidth: "600px",
                        marginBottom: "20px",
                      }}
                    >
                      <div
                        className="section-heading wow fadeInUp"
                        data-wow-duration="1s"
                        data-wow-delay=".2s"
                        style={{
                          visibility: "visible",
                          animationDuration: "1s",
                          animationDelay: "0.2s",
                        }}
                      >
                        <h2 className="bookcoach abt">
                          {" "}
                          <span>How to Identify If Your Child Needs </span>Extra
                          Academic Support
                        </h2>
                        <p>
                          {" "}
                          Are you noticing a change in your child's enthusiasm
                          for school? Maybe the homework battles are becoming
                          more frequent, or their grades aren't reflecting the
                          effort they're putting in. These could be signs that
                          your child might benefit from extra academic support.
                          Identifying the need early can make a world of
                          difference in their confidence and academic journey...{" "}
                        </p>
                        {/* <span style={{ fontWeight: "bold", fontSize: "20px" ,fontFamily:"700",color:"black" }}>Let's explore some key indicators that it's time to consider additional help for your child's education... </span> */}
                      </div>

                      <div className="combinebutton">
                        <Link
                          to="/multicoach"
                          className="global-button startbtn"
                          style={{ borderRadius: "15px" }}
                        >
                          Select a Tutor{" "}
                        </Link>
                        <button
                          className="global-button startbtn"
                          style={{
                            borderRadius: "15px",
                            cursor: "pointer",
                            marginLeft: "40px",
                          }}
                          onClick={handleOpenModal4}
                        >
                          Read More
                        </button>
                      </div>
                    </div>
                    <div
                      className="call-to-action-image block-images col-lg-8 wow fadeInRight"
                      data-wow-duration="1s"
                      data-wow-delay=".2s"
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.4s",
                        maxWidth: "600px", // Restricting the image size
                        width: "100%",
                      }}
                    >
                      <div
                        className="image"
                        style={{
                          width: "90%",
                          height: "auto",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src="images/How-to-Identify-If-Your-Child-Needs-Extra-Academic-Support.png"
                          alt=" "
                          style={{
                            width: "100%",
                            height: "auto",
                            objectFit: "cover",
                            borderRadius: "10px",
                          }}
                        />
                        <div className="dots-bg dots-bg-event  startdot "></div>
                      </div>
                      <div className="box-blog">
                        {/* Box 4: Educational Outings */}
                        {/* <div
      className="how it-work-section wow fadeInUp"
      data-wow-duration="1.5s"
      dataWowDelay=".2s"
      style={{
        visibility: "visible",
        animationDuration: "1s",
        animationDelay: "0.4s",
        background: "#7ED957",
        textAlign: "center", // Center text
        margin: "0 auto", // Center the box
        padding: "20px", // Add padding for better spacing
        maxWidth: "350px", // Ensure the box has a max width
      }}
    >
      
      <h3 style={{ fontWeight: "bold", fontSize: "30px" }}>
      Homework Struggles 
      </h3>
      <p style={{ color: "#00006C", fontSize: "17px" }}>
      Does homework time feel like a daily struggle? If your child is spending excessive amounts of time on assignments or frequently needs help, this could indicate that they are not grasping the concepts as well as they should be. Frustration and avoidance behaviors during homework are clear signals that extra support may be necessary
      </p>
      
    </div> */}
                      </div>
                    </div>

                    {/* <!--   Image End --> */}
                  </div>

                  {/* <!--   Wrapper End --> */}
                </div>
              </div>
              <div
                className="section section-padding bk blog"
                style={{ overflow: "hidden" }}
              >
                {/* <!--   Wrapper Start --> */}
                <div className="call-to-action-wrapper abt ab">
                  <div className="container abt d-flex flex-column flex-lg-row justify-content-between align-items-center quic">
                    {/* <!--   Content Start --> */}

                    {/* <!--   Content End --> */}
                    {/* <!--   Image Start --> */}
                    <div
                      className="call-to-action-content abt wow fadeInLeft"
                      data-wow-duration="1s"
                      data-wow-delay=".2s"
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.4s",
                        maxWidth: "600px",
                        marginBottom: "20px",
                      }}
                    >
                      <div
                        className="section-heading wow fadeInUp"
                        data-wow-duration="1s"
                        data-wow-delay=".2s"
                        style={{
                          visibility: "visible",
                          animationDuration: "1s",
                          animationDelay: "0.2s",
                        }}
                      >
                        <h2 className="bookcoach abt">
                          {" "}
                          <span>The Benefits of Peer Tutoring and </span>Study
                          Groups to Students
                        </h2>
                        <p>
                          {" "}
                          Imagine a learning environment where your child not
                          only absorbs knowledge but also feels motivated,
                          engaged, and supported by their peers. This is the
                          essence of peer tutoring and study groups – a dynamic
                          approach to education that can transform your child's
                          academic journey...{" "}
                        </p>
                      </div>

                      <div className="combinebutton">
                        <Link
                          to="/multicoach"
                          className="global-button startbtn"
                          style={{ borderRadius: "15px" }}
                        >
                          Select a Tutor{" "}
                        </Link>
                        <button
                          className="global-button startbtn"
                          style={{
                            borderRadius: "15px",
                            cursor: "pointer",
                            marginLeft: "40px",
                          }}
                          onClick={handleOpenModal5}
                        >
                          Read More
                        </button>
                      </div>
                    </div>

                    <div
                      className="call-to-action-image block-images col-lg-8 wow fadeInRight"
                      data-wow-duration="1s"
                      data-wow-delay=".2s"
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.4s",
                        maxWidth: "600px", // Restricting the image size
                        width: "100%",
                      }}
                    >
                      <div
                        className="image"
                        style={{
                          width: "90%",
                          height: "auto",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src="images/Peer-Tutoring-and-Study-Groups.png"
                          alt=" "
                          style={{
                            width: "100%",
                            height: "auto",
                            objectFit: "cover",
                            borderRadius: "10px",
                          }}
                        />
                        <div className="dots-bg dots-bg-event  startdot "></div>
                      </div>
                      <div className="box-blog">
                        {/* Box 4: Educational Outings */}
                      </div>
                    </div>
                  </div>

                  {/* <!--   Image End --> */}
                </div>

                {/* <!--   Wrapper End --> */}
              </div>
              <div
                className="section section-padding bk blog"
                style={{ overflow: "hidden" }}
              >
                {/* <!--   Wrapper Start --> */}
                <div className="call-to-action-wrapper abt ab">
                  <div className="container abt d-flex flex-column flex-lg-row justify-content-between align-items-center quic">
                    {/* <!--   Content Start --> */}

                    {/* <!--   Content End --> */}
                    {/* <!--   Image Start --> */}

                    <div
                      className="call-to-action-content abt wow fadeInLeft"
                      data-wow-duration="1s"
                      data-wow-delay=".2s"
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.4s",
                        maxWidth: "600px",
                        marginBottom: "20px",
                      }}
                    >
                      <div
                        className="section-heading wow fadeInUp"
                        data-wow-duration="1s"
                        data-wow-delay=".2s"
                        style={{
                          visibility: "visible",
                          animationDuration: "1s",
                          animationDelay: "0.2s",
                        }}
                      >
                        <h2 className="bookcoach abt">
                          {" "}
                          <span>Unlocking Success: </span>Tips for Helping Your
                          Child Ace Standardized Tests
                        </h2>
                        <p>
                          {" "}
                          Standardized tests can be a nerve-wracking experience
                          for any child. These exams are not just about what
                          your child knows but also about how well they can
                          apply their knowledge under pressure. The good news is
                          that with the right preparation, your child can
                          approach these tests with confidence and perform their
                          best...{" "}
                        </p>
                        {/* <span style={{ fontWeight: "bold", fontSize: "20px" ,fontFamily:"700" ,color:"black"}}>Here are some practical tips to help your child prepare effectively for standardized tests... </span> */}
                      </div>

                      <div className="combinebutton">
                        <Link
                          to="/multicoach"
                          className="global-button startbtn"
                          style={{ borderRadius: "15px" }}
                        >
                          Select a Tutor{" "}
                        </Link>
                        <button
                          className="global-button startbtn"
                          style={{
                            borderRadius: "15px",
                            cursor: "pointer",
                            marginLeft: "40px",
                          }}
                          onClick={handleOpenModal6}
                        >
                          Read More
                        </button>
                      </div>
                    </div>

                    <div
                      className="call-to-action-image block-images col-lg-8 wow fadeInRight"
                      data-wow-duration="1s"
                      data-wow-delay=".2s"
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.4s",
                        maxWidth: "600px", // Restricting the image size
                        width: "100%",
                      }}
                    >
                      <div
                        className="image"
                        style={{
                          width: "90%",
                          height: "auto",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src="images/Helping-Your-Child-Ace-Standardized-Tests.png"
                          alt=" "
                          style={{
                            width: "100%",
                            height: "auto",
                            objectFit: "cover",
                            borderRadius: "10px",
                          }}
                        />
                        <div className="dots-bg dots-bg-event  startdot "></div>
                      </div>
                      <div className="box-blog">
                        {/* Box 4: Educational Outings */}
                      </div>
                    </div>

                    {/* <!--   Image End --> */}
                  </div>

                  {/* <!--   Wrapper End --> */}
                </div>
              </div>
              <div
                className="section section-padding bk blog"
                style={{ overflow: "hidden" }}
              >
                {/* <!--   Wrapper Start --> */}
                <div className="call-to-action-wrapper abt ab">
                  <div className="container abt d-flex flex-column flex-lg-row justify-content-between align-items-center quic">
                    {/* <!--   Content Start --> */}

                    {/* <!--   Content End --> */}
                    {/* <!--   Image Start --> */}
                    <div
                      className="call-to-action-content abt wow fadeInLeft"
                      data-wow-duration="1s"
                      data-wow-delay=".2s"
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.4s",
                        maxWidth: "600px",
                        marginBottom: "20px",
                      }}
                    >
                      <div
                        className="section-heading wow fadeInUp"
                        data-wow-duration="1s"
                        data-wow-delay=".2s"
                        style={{
                          visibility: "visible",
                          animationDuration: "1s",
                          animationDelay: "0.2s",
                        }}
                      >
                        <h2 className="bookcoach abt">
                          {" "}
                          <span>Unlocking Your Child's Potential: </span>Top
                          Test-Taking Strategies
                        </h2>
                        <p>
                          {" "}
                          Every parent wants to see their child succeed,
                          especially when it comes to academic performance.
                          Testing can be a significant source of stress, but
                          with the right strategies, your child can approach
                          their exams with confidence and poise...{" "}
                        </p>
                        {/* <span style={{ fontWeight: "bold", fontSize: "20px" ,fontFamily:"700",color:"black" }}>Here are some proven test-taking strategies to help your child excel... </span> */}
                      </div>

                      <div className="combinebutton">
                        <Link
                          to="/multicoach"
                          className="global-button startbtn"
                          style={{ borderRadius: "15px" }}
                        >
                          Select a Tutor{" "}
                        </Link>
                        <button
                          className="global-button startbtn"
                          style={{
                            borderRadius: "15px",
                            cursor: "pointer",
                            marginLeft: "40px",
                          }}
                          onClick={handleOpenModal7}
                        >
                          Read More
                        </button>
                      </div>
                    </div>

                    <div
                      className="call-to-action-image block-images col-lg-8 wow fadeInRight"
                      data-wow-duration="1s"
                      data-wow-delay=".2s"
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.4s",
                        maxWidth: "600px", // Restricting the image size
                        width: "100%",
                      }}
                    >
                      <div
                        className="image"
                        style={{
                          width: "90%",
                          height: "auto",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src="images/Top-Test-Taking-Strategies.png"
                          alt=" "
                          style={{
                            width: "100%",
                            height: "auto",
                            objectFit: "cover",
                            borderRadius: "10px",
                          }}
                        />
                        <div className="dots-bg dots-bg-event  startdot "></div>
                      </div>
                      <div className="box-blog">
                        {/* Box 4: Educational Outings */}
                      </div>
                    </div>
                  </div>

                  {/* <!--   Image End --> */}
                </div>

                {/* <!--   Wrapper End --> */}
              </div>
              <div
                className="section section-padding bk blog"
                style={{ overflow: "hidden" }}
              >
                {/* <!--   Wrapper Start --> */}
                <div className="call-to-action-wrapper abt ab">
                  <div className="container abt d-flex flex-column flex-lg-row justify-content-between align-items-center quic">
                    {/* <!--   Content Start --> */}

                    {/* <!--   Content End --> */}
                    {/* <!--   Image Start --> */}

                    <div
                      className="call-to-action-content abt wow fadeInLeft"
                      data-wow-duration="1s"
                      data-wow-delay=".2s"
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.4s",
                        maxWidth: "600px",
                        marginBottom: "20px",
                      }}
                    >
                      <div
                        className="section-heading wow fadeInUp"
                        data-wow-duration="1s"
                        data-wow-delay=".2s"
                        style={{
                          visibility: "visible",
                          animationDuration: "1s",
                          animationDelay: "0.2s",
                        }}
                      >
                        <h2 className="bookcoach abt">
                          {" "}
                          <span>Fun and Educational </span>Activities for School
                          Holidays
                        </h2>
                        <p>
                          {" "}
                          School holidays are here! It's the perfect time for
                          children to rest, recharge, and explore new interests.
                          While it might be tempting to let them spend their
                          days glued to screens, this break offers an
                          opportunity for both fun and learning...{" "}
                        </p>
                        {/* <span style={{ fontWeight: "bold", fontSize: "20px" ,fontFamily:"700" ,color:"black"}}>Here are some creative and educational activities that can make the holidays memorable and productive for your children... </span> */}
                      </div>

                      <div className="combinebutton">
                        <Link
                          to="/multicoach"
                          className="global-button startbtn"
                          style={{ borderRadius: "15px" }}
                        >
                          Select a Tutor{" "}
                        </Link>
                        <button
                          className="global-button startbtn"
                          style={{
                            borderRadius: "15px",
                            cursor: "pointer",
                            marginLeft: "40px",
                          }}
                          onClick={handleOpenModal8}
                        >
                          Read More
                        </button>
                      </div>
                    </div>

                    <div
                      className="call-to-action-image block-images col-lg-8 wow fadeInRight"
                      data-wow-duration="1s"
                      data-wow-delay=".2s"
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.4s",
                        maxWidth: "600px", // Restricting the image size
                        width: "100%",
                      }}
                    >
                      <div
                        className="image"
                        style={{
                          width: "90%",
                          height: "auto",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src="images/Fun-and-Educational-Activities-for-School-Holidays.png"
                          alt=" "
                          style={{
                            width: "100%",
                            height: "auto",
                            objectFit: "cover",
                            borderRadius: "10px",
                          }}
                        />
                        <div className="dots-bg dots-bg-event  startdot "></div>
                      </div>
                      <div className="box-blog">
                        {/* Box 4: Educational Outings */}
                      </div>
                    </div>

                    {/* <!--   Image End --> */}
                  </div>

                  {/* <!--   Wrapper End --> */}
                </div>
              </div>
              <div
                className="section section-padding bk blog"
                style={{ overflow: "hidden" }}
              >
                {/* <!--   Wrapper Start --> */}
                <div className="call-to-action-wrapper abt ab">
                  <div className="container abt d-flex flex-column flex-lg-row justify-content-between align-items-center quic">
                    {/* <!--   Content Start --> */}

                    {/* <!--   Content End --> */}
                    {/* <!--   Image Start --> */}
                    <div
                      className="call-to-action-content abt wow fadeInLeft"
                      data-wow-duration="1s"
                      data-wow-delay=".2s"
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.4s",
                        maxWidth: "600px",
                        marginBottom: "20px",
                      }}
                    >
                      <div
                        className="section-heading wow fadeInUp"
                        data-wow-duration="1s"
                        data-wow-delay=".2s"
                        style={{
                          visibility: "visible",
                          animationDuration: "1s",
                          animationDelay: "0.2s",
                        }}
                      >
                        <h2 className="bookcoach abt">
                          {" "}
                          <span>Top Study Tips for High School </span>and
                          Primary Students
                        </h2>
                        <p>
                          {" "}
                          In today's fast-paced world, helping children develop
                          effective study habits is more crucial than ever. With
                          the right strategies, students can excel academically,
                          build confidence, and enjoy learning...{" "}
                        </p>
                        {/* <span style={{ fontWeight: "bold", fontSize: "20px" ,fontFamily:"700" ,color:"black"}}>Here are some tried-and-true study tips tailored for both high school and primary students that can make a significant difference... </span> */}
                      </div>

                      <div className="combinebutton">
                        <Link
                          to="/multicoach"
                          className="global-button startbtn"
                          style={{ borderRadius: "15px" }}
                        >
                          Select a Tutor{" "}
                        </Link>
                        <button
                          className="global-button startbtn"
                          style={{
                            borderRadius: "15px",
                            cursor: "pointer",
                            marginLeft: "40px",
                          }}
                          onClick={handleOpenModal9}
                        >
                          Read More
                        </button>
                      </div>
                    </div>

                    <div
                      className="call-to-action-image block-images col-lg-8 wow fadeInRight"
                      data-wow-duration="1s"
                      data-wow-delay=".2s"
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.4s",
                        maxWidth: "600px", // Restricting the image size
                        width: "100%",
                      }}
                    >
                      <div
                        className="image"
                        style={{
                          width: "90%",
                          height: "auto",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src="images/Top-Study-Tips-for-High-School-and-Primary-Students.png"
                          alt=" "
                          style={{
                            width: "100%",
                            height: "auto",
                            objectFit: "cover",
                            borderRadius: "10px",
                          }}
                        />
                        <div className="dots-bg dots-bg-event  startdot "></div>
                      </div>
                      <div className="box-blog">
                        {/* Box 4: Educational Outings */}
                      </div>
                    </div>
                  </div>

                  {/* <!--   Image End --> */}
                </div>

                {/* <!--   Wrapper End --> */}
              </div>
              <div
                className="section section-padding bk blog"
                style={{ overflow: "hidden" }}
              >
                {/* <!--   Wrapper Start --> */}
                <div className="call-to-action-wrapper abt ab">
                  <div className="container abt d-flex flex-column flex-lg-row justify-content-between align-items-center quic">
                    {/* <!--   Content Start --> */}

                    {/* <!--   Content End --> */}
                    {/* <!--   Image Start --> */}
                    <div
                      className="call-to-action-content abt wow fadeInLeft"
                      data-wow-duration="1s"
                      data-wow-delay=".2s"
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.4s",
                        maxWidth: "600px",
                        marginBottom: "20px",
                      }}
                    >
                      <div
                        className="section-heading wow fadeInUp"
                        data-wow-duration="1s"
                        data-wow-delay=".2s"
                        style={{
                          visibility: "visible",
                          animationDuration: "1s",
                          animationDelay: "0.2s",
                        }}
                      >
                        <h2 className="bookcoach abt">
                          {" "}
                          <span>Top Study Tips for High School </span>and
                          Primary Students
                        </h2>
                        <p>
                          {" "}
                          In today's fast-paced world, helping children develop
                          effective study habits is more crucial than ever. With
                          the right strategies, students can excel academically,
                          build confidence, and enjoy learning...{" "}
                        </p>
                        {/* <span style={{ fontWeight: "bold", fontSize: "20px" ,fontFamily:"700" ,color:"black"}}>Here are some tried-and-true study tips tailored for both high school and primary students that can make a significant difference... </span> */}
                      </div>

                      <div className="combinebutton">
                        <Link
                          to="/multicoach"
                          className="global-button startbtn"
                          style={{ borderRadius: "15px" }}
                        >
                          Select a Tutor{" "}
                        </Link>
                        <button
                          className="global-button startbtn"
                          style={{
                            borderRadius: "15px",
                            cursor: "pointer",
                            marginLeft: "40px",
                          }}
                          onClick={handleOpenModal10}
                        >
                          Read More
                        </button>
                      </div>
                    </div>

                    <div
                      className="call-to-action-image block-images col-lg-8 wow fadeInRight"
                      data-wow-duration="1s"
                      data-wow-delay=".2s"
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.4s",
                        maxWidth: "600px", // Restricting the image size
                        width: "100%",
                      }}
                    >
                      <div
                        className="image"
                        style={{
                          width: "90%",
                          height: "auto",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src="images/Top-Study-Tips-for-High-School-and-Primary-Students.png"
                          alt=" "
                          style={{
                            width: "100%",
                            height: "auto",
                            objectFit: "cover",
                            borderRadius: "10px",
                          }}
                        />
                        <div className="dots-bg dots-bg-event  startdot "></div>
                      </div>
                      <div className="box-blog">
                        {/* Box 4: Educational Outings */}
                      </div>
                    </div>
                  </div>

                  {/* <!--   Image End --> */}
                </div>

                {/* <!--   Wrapper End --> */}
              </div>
            </Slider>
          </div>

          {/* Modal */}
          <Modal
            open={openModal1}
            onClose={handleCloseModal1}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: { xs: 400, sm: 400, md: 800 }, // Responsive width
                maxHeight: "100%", // Set maximum height
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
                borderRadius: "10px", // Add border radius
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* Scrollable content */}
              <Box
                sx={{
                  overflowY: "auto",
                  maxHeight: "100%", // Adjust to make room for the button
                  mb: 2,
                }}
              >
                <h2 className="modal-title1">
                  How to Keep Kids Motivated to Learn During the School Year
                </h2>
                <p className="modal-description">
                  Navigating the school year can sometimes feel like a marathon.
                  The excitement of the first day often fades, leaving
                  students—and their support systems—looking for ways to keep
                  the momentum going. Keeping kids motivated to learn is
                  essential for their success and well-being...
                </p>

                <h2 className="modal-title2" style={{ fontSize: "20px" }}>
                  Here are some practical tips to help keep that spark alive
                  throughout the school year.
                </h2>

                <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                  <li>
                    <h2 className="modal-title3" style={{ fontSize: "20px" }}>
                      Create a Positive Learning Environment
                    </h2>
                  </li>
                  <p className="modal-description">
                    A space dedicated to learning can make a world of
                    difference. It doesn’t have to be a full home office — just
                    a quiet, organized corner where your child can focus on
                    their studies. Stock it with essential supplies, encourage a
                    clutter-free desk, and ensure good lighting. A pleasant
                    learning environment can significantly boost a child's
                    motivation.
                  </p>

                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Set Realistic Goals
                    </h2>
                  </li>
                  <p>
                    Setting achievable goals helps children stay motivated and
                    gives them a sense of direction. These goals can be as
                    simple as completing a homework assignment on time or as
                    ambitious as improving grades in a challenging subject.
                    Celebrate the small victories along the way to keep the
                    momentum going.
                  </p>

                  <li>
                    <h2 className="modal-title5" style={{ fontSize: "20px" }}>
                      Encourage a Growth Mindset
                    </h2>
                  </li>
                  <p>
                    Teach kids that learning is a journey, not a destination.
                    Emphasize effort over innate ability. When they encounter
                    challenges, encourage them to view these as opportunities to
                    grow rather than insurmountable obstacles. Phrases like "You
                    worked really hard on that" or "What can we learn from
                    this?" foster resilience and a love of learning.
                  </p>

                  <li>
                    <h2 className="modal-title6" style={{ fontSize: "20px" }}>
                      Make Learning Fun
                    </h2>
                  </li>
                  <p>
                    Incorporate games, puzzles, and interactive activities into
                    the learning process. Educational apps and websites can make
                    subjects more engaging. Mix things up with hands-on projects
                    or experiments. The more varied and enjoyable the
                    activities, the more likely kids are to stay interested.
                  </p>

                  <li>
                    <h2 className="modal-title7" style={{ fontSize: "20px" }}>
                      Establish a Routine
                    </h2>
                  </li>
                  <p>
                    A consistent routine can help children know what to expect
                    and when to focus on their studies. Balance study time with
                    breaks to prevent burnout. Consistency helps build
                    discipline, and having set times for homework, reading, and
                    play can make the school year run more smoothly.
                  </p>

                  <li>
                    <h2 className="modal-title8" style={{ fontSize: "20px" }}>
                      Stay Involved
                    </h2>
                  </li>
                  <p>
                    Show interest in what your child is learning. Ask questions
                    about their day, discuss their favorite subjects, and help
                    them with homework when needed. Your involvement signals
                    that education is important, and your support can boost
                    their confidence and motivation.
                  </p>

                  <li>
                    <h2 className="modal-title9" style={{ fontSize: "20px" }}>
                      Encourage Extracurricular Activities
                    </h2>
                  </li>
                  <p>
                    Sometimes, a break from academic work is just what a child
                    needs to recharge. Encourage participation in sports, arts,
                    music, or other interests. These activities can provide a
                    sense of accomplishment and help develop skills that are
                    transferable to their academic work.
                  </p>

                  <li>
                    <h2 className="modal-title10" style={{ fontSize: "20px" }}>
                      Promote Healthy Habits
                    </h2>
                  </li>
                  <p>
                    Adequate sleep, a balanced diet, and regular physical
                    activity are crucial for maintaining energy and focus. Make
                    sure kids get enough rest and eat nutritious meals. A
                    healthy body supports a healthy mind, making it easier for
                    them to stay engaged in their studies.
                  </p>

                  <li>
                    <h2 className="modal-title11" style={{ fontSize: "20px" }}>
                      Provide Positive Reinforcement
                    </h2>
                  </li>
                  <p>
                    Recognize and praise efforts and accomplishments, no matter
                    how small. Positive reinforcement can boost self-esteem and
                    motivation. Whether it’s a verbal acknowledgment, a small
                    reward, or extra playtime, celebrating achievements can
                    inspire continued effort.
                  </p>

                  <li>
                    <h2 className="modal-title12" style={{ fontSize: "20px" }}>
                      Be Patient and Understanding
                    </h2>
                  </li>
                  <p>
                    Remember, everyone has off days. Be patient and
                    understanding when your child struggles or loses motivation.
                    Offer support, encouragement and help them get back on track
                    without adding pressure. Your patience can make a big
                    difference in how they approach challenges.
                  </p>
                </ul>
              </Box>

              {/* Fixed Done button */}
              <Box
                sx={{
                  borderTop: "1px solid #ccc", // Optional: add a separator
                  paddingTop: 2,
                  textAlign: "center",
                }}
              >
                <button
                  className="global-button"
                  style={{
                    borderRadius: "10px",
                    padding: "10px 20px",
                    cursor: "pointer",
                  }}
                  onClick={handleCloseModal1}
                >
                  Close
                </button>
              </Box>
            </Box>
          </Modal>

          <Modal
            open={openModal2}
            onClose={handleCloseModal2}
            aria-labelledby="second-modal-title"
            aria-describedby="second-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: { xs: 400, sm: 400, md: 800 },
                maxHeight: "100%",
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  overflowY: "auto",
                  maxHeight: "100%",
                  mb: 2,
                }}
              >
                <h2 className="second-modal-title">
                  Exploring Different Educational Resources for Extra Support{" "}
                </h2>
                <p className="second-modal-description">
                  Navigating the educational landscape can sometimes feel
                  overwhelming. With so many resources available, how do you
                  choose the best ones to support your child's learning journey?
                  Let’s dive into a selection of books, websites, and apps that
                  can enhance and complement tutoring sessions, ensuring your
                  child gets the most out of their educational experience.
                </p>
                <h2 className="second-modal-title">Books to Boost Learning </h2>
                <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                  <p className="second-modal-description">
                    <li>
                      <h2 className="modal-title5" style={{ fontSize: "20px" }}>
                        The Reading Promise" by Alice Ozma
                      </h2>
                    </li>
                    This heartwarming book chronicles a father and daughter’s
                    journey through daily reading sessions. It’s a wonderful
                    reminder of the power of shared reading and a great source
                    of inspiration for incorporating more books into daily
                    routines.
                    <br></br>
                    <li>
                      <h2 className="modal-title5" style={{ fontSize: "20px" }}>
                        Mathematics: An Illustrated History of Numbers" by Tom
                        Jackson
                      </h2>
                    </li>
                    A visually engaging book that makes math accessible and
                    interesting. It’s perfect for sparking curiosity in young
                    learners who might find traditional math textbooks dull.
                    <br></br>
                    <li>
                      <h2 className="modal-title5" style={{ fontSize: "20px" }}>
                        The Story of Science" series by Joy Hakim
                      </h2>
                    </li>
                    These books provide a captivating narrative of scientific
                    discovery, making complex concepts easier to understand and
                    appreciate. Ideal for students who are visual and narrative
                    learners.
                    <br></br>
                  </p>
                </ul>

                <h2 className="second-modal-title">
                  Engaging Educational Websites{" "}
                </h2>
                <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                  <p className="second-modal-description">
                    <li>
                      <h2 className="modal-title5" style={{ fontSize: "20px" }}>
                        Khan Academy
                      </h2>
                    </li>
                    This well-known resource offers free, high-quality
                    instructional videos and practice exercises on a wide range
                    of subjects. It’s an excellent tool for students who need a
                    little extra help with a specific topic or who want to
                    advance their knowledge.
                    <br></br>
                    <li>
                      <h2 className="modal-title5" style={{ fontSize: "20px" }}>
                        National Geographic Kids
                      </h2>
                    </li>
                    This site makes learning about geography, science, and
                    history fun with its interactive games, videos, and
                    articles. It’s a great way to encourage curiosity and
                    exploration outside of the standard curriculum.
                    <br></br>
                    <li>
                      <h2 className="modal-title5" style={{ fontSize: "20px" }}>
                        BBC Bitesize
                      </h2>
                    </li>
                    Tailored to different age groups, this resource provides a
                    wide array of learning materials, from videos and quizzes to
                    interactive lessons. It’s especially useful for reinforcing
                    concepts covered in tutoring sessions.
                    <br></br>
                  </p>
                </ul>

                <h2 className="second-modal-title">
                  Apps for On-the-Go Learning{" "}
                </h2>
                <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                  <p className="second-modal-description">
                    <li>
                      <h2 className="modal-title5" style={{ fontSize: "20px" }}>
                        Prodigy
                      </h2>
                    </li>
                    A math game that turns learning into an adventure. Students
                    solve math problems to progress through the game, making it
                    an engaging way to practice math skills outside of tutoring
                    hours.
                    <li>
                      <h2 className="modal-title5" style={{ fontSize: "20px" }}>
                        Duolingo
                      </h2>
                    </li>
                    For students learning a new language, Duolingo offers
                    bite-sized lessons that are easy to fit into a busy
                    schedule. It’s a fun and effective way to build vocabulary
                    and practice grammar.
                    <li>
                      <h2 className="modal-title5" style={{ fontSize: "20px" }}>
                        Kahoot
                      </h2>
                    </li>
                    This app allows students to participate in quizzes and games
                    on various subjects. It’s a fantastic tool for reviewing
                    material in a fun, interactive way, perfect for a quick
                    knowledge check before or after tutoring sessions.
                  </p>
                </ul>
                <h2 className="second-modal-title">
                  Integrating These Resources{" "}
                </h2>
                <p className="second-modal-description">
                  Combining these resources with regular tutoring can create a
                  well-rounded and robust learning experience. Books can provide
                  foundational knowledge and inspiration, websites offer
                  interactive and detailed explanations, and apps deliver
                  engaging practice opportunities. The key is to find a balance
                  that works best for your child’s unique learning style and
                  interests.
                  <br></br>
                  <br></br>
                  By leveraging these educational resources, you can help your
                  child build confidence, deepen their understanding, and
                  develop a genuine love for learning. Whether through the pages
                  of a book, the screen of a tablet, or the interactive world of
                  the web, the journey of learning can be an exciting adventure.
                </p>
              </Box>
              <Box
                sx={{
                  borderTop: "1px solid #ccc",
                  paddingTop: 2,
                  textAlign: "center",
                }}
              >
                <button
                  className="global-button"
                  style={{
                    borderRadius: "10px",
                    padding: "10px 20px",
                    cursor: "pointer",
                  }}
                  onClick={handleCloseModal2}
                >
                  Close
                </button>
              </Box>
            </Box>
          </Modal>
          <Modal
            open={openModal3}
            onClose={handleCloseModal3}
            aria-labelledby="second-modal-title"
            aria-describedby="second-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: { xs: 400, sm: 400, md: 800 },
                maxHeight: "100%",
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  overflowY: "auto",
                  maxHeight: "100%",
                  mb: 2,
                }}
              >
                <h2 className="modal-title1">
                  {" "}
                  Understanding and Overcoming Learning Disabilities{" "}
                </h2>
                <p className="modal-description">
                  Imagine this: your child comes home, backpack slung over one
                  shoulder, and you can see the frustration in their eyes.
                  Homework has become a battle, and no matter how much time is
                  spent, progress seems elusive. If this scenario feels
                  familiar, you’re not alone. Many children face learning
                  disabilities, but with the right support and understanding,
                  they can thrive academically and emotionally.
                </p>
                <h2 className="second-modal-title">
                  Common Learning Disabilities{" "}
                </h2>
                <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                  <p className="second-modal-description">
                    <li>
                      <h2 className="modal-title3" style={{ fontSize: "20px" }}>
                        Dyslexia
                      </h2>
                    </li>
                    This is one of the most common learning disabilities.
                    Children with dyslexia struggle with reading, writing, and
                    spelling. It’s not about intelligence but about the brain
                    processing written words differently.
                    <li>
                      <h2 className="modal-title3" style={{ fontSize: "20px" }}>
                        ADHD (Attention Deficit Hyperactivity Disorder)
                      </h2>
                    </li>
                    While not a learning disability per se, ADHD can
                    significantly impact learning. Children with ADHD may have
                    difficulty paying attention, staying on task, and
                    controlling impulses.
                    <li>
                      <h2 className="modal-title3" style={{ fontSize: "20px" }}>
                        Dyscalculia
                      </h2>
                    </li>
                    This condition affects a child’s ability to understand
                    numbers and learn math facts. It goes beyond simple
                    difficulties with arithmetic; it involves a fundamental
                    struggle with concepts like time, measurement, and spatial
                    reasoning.
                    <li>
                      <h2 className="modal-title3" style={{ fontSize: "20px" }}>
                        Dysgraphia
                      </h2>
                    </li>
                    Children with dysgraphia have trouble with writing. This can
                    mean messy handwriting, difficulty spelling, and problems
                    putting thoughts on paper.
                    <br></br>
                    <li>
                      <h2 className="modal-title3" style={{ fontSize: "20px" }}>
                        Auditory Processing Disorder (APD)
                      </h2>
                    </li>
                    Kids with APD have trouble processing the information they
                    hear. They might misinterpret instructions, have difficulty
                    following conversations, and struggle to distinguish similar
                    sounds.
                    <br></br>
                  </p>
                </ul>
                <h2 className="second-modal-title">
                  How to Support Children with Learning Disabilities{" "}
                </h2>
                <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                  <p className="second-modal-description">
                    <li>
                      <h2 className="modal-title3" style={{ fontSize: "20px" }}>
                        Early Identification and Assessment
                      </h2>
                    </li>
                    The sooner a learning disability is identified, the sooner
                    tailored interventions can begin. Professional assessments
                    can pinpoint specific challenges and guide the development
                    of effective strategies.
                    <li>
                      <h2 className="modal-title3" style={{ fontSize: "20px" }}>
                        Individualized Education Plans (IEPs)
                      </h2>
                    </li>
                    An IEP is a customized learning plan designed to meet a
                    child’s unique needs. Working closely with teachers and
                    specialists ensures that your child receives the
                    accommodations and modifications necessary for their
                    success.
                    <li>
                      <h2 className="modal-title3" style={{ fontSize: "20px" }}>
                        Use of Technology
                      </h2>
                    </li>
                    Assistive technology can be a game-changer. Audiobooks,
                    speech-to-text software, and educational apps can help
                    children with learning disabilities access the curriculum
                    and complete assignments more effectively.
                    <li>
                      <h2 className="modal-title3" style={{ fontSize: "20px" }}>
                        Encourage Strengths and Interests
                      </h2>
                    </li>
                    Every child has strengths and passions. Encouraging these
                    can boost confidence and motivation. Whether it’s art,
                    sports, or a particular subject, focusing on what they excel
                    at can provide a much-needed sense of achievement.
                    <li>
                      <h2 className="modal-title3" style={{ fontSize: "20px" }}>
                        Create a Supportive Home Environment
                      </h2>
                    </li>
                    Establish a structured routine, provide a quiet study space,
                    and break tasks into manageable chunks. Positive
                    reinforcement and patience go a long way in helping children
                    feel supported and understood.
                    <li>
                      <h2 className="modal-title3" style={{ fontSize: "20px" }}>
                        Seek Professional Help
                      </h2>
                    </li>
                    Sometimes, additional help from tutors, therapists, or
                    learning specialists can make a significant difference.
                    These professionals can offer targeted strategies and
                    support tailored to your child's needs.
                  </p>
                </ul>
                <h2 className="second-modal-title">Final Thoughts </h2>
                <p className="second-modal-description">
                  Every child’s learning journey is unique, and facing a
                  learning disability can feel like a daunting obstacle.
                  However, with the right tools, strategies, and support, your
                  child can overcome these challenges and unlock their full
                  potential. Remember, it's not about the destination but the
                  journey and the resilience and determination developed along
                  the way.
                </p>
              </Box>
              <Box
                sx={{
                  borderTop: "1px solid #ccc",
                  paddingTop: 2,
                  textAlign: "center",
                }}
              >
                <button
                  className="global-button"
                  style={{
                    borderRadius: "10px",
                    padding: "10px 20px",
                    cursor: "pointer",
                  }}
                  onClick={handleCloseModal3}
                >
                  Close
                </button>
              </Box>
            </Box>
          </Modal>
          <Modal
            open={openModal4}
            onClose={handleCloseModal4}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: { xs: 400, sm: 400, md: 800 }, // Responsive width
                maxHeight: "100%", // Set maximum height
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
                borderRadius: "10px", // Add border radius
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* Scrollable content */}
              <Box
                sx={{
                  overflowY: "auto",
                  maxHeight: "100%", // Adjust to make room for the button
                  mb: 2,
                }}
              >
                <h2 className="modal-title1">
                  How to Identify If Your Child Needs Extra Academic Support{" "}
                </h2>

                <p className="modal-description">
                  Are you noticing a change in your child's enthusiasm for
                  school? Maybe the homework battles are becoming more frequent,
                  or their grades aren't reflecting the effort they're putting
                  in. These could be signs that your child might benefit from
                  extra academic support. Identifying the need early can make a
                  world of difference in their confidence and academic journey.
                </p>
                <h2 className="modal-title">
                  Let's explore some key indicators that it's time to consider
                  additional help for your child's education.{" "}
                </h2>
                <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Declining Grades{" "}
                    </h2>
                  </li>
                  <p className="modal-description">
                    One of the most obvious signs is a drop in grades. If your
                    child's report card shows a downward trend or if they are
                    consistently scoring lower than their peers, it might be
                    time to investigate further. It’s essential to understand
                    whether the decline is due to a lack of understanding of the
                    material, difficulty with certain subjects, or other factors
                    such as test anxiety.
                  </p>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Homework Struggles{" "}
                    </h2>
                  </li>
                  <p>
                    Does homework time feel like a daily struggle? If your child
                    is spending excessive amounts of time on assignments or
                    frequently needs help, this could indicate that they are not
                    grasping the concepts as well as they should be. Frustration
                    and avoidance behaviors during homework are clear signals
                    that extra support may be necessary.
                  </p>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Lack of Confidence{" "}
                    </h2>
                  </li>
                  <p>
                    A sudden drop in your child's confidence in their academic
                    abilities can be a red flag. If they start expressing
                    negative feelings about their intelligence or abilities, it
                    can impact their performance and willingness to try.
                    Boosting their confidence through targeted support can help
                    them regain their self-esteem and enthusiasm for learning.
                  </p>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Behavioural Changes{" "}
                    </h2>
                  </li>
                  <p>
                    Behavioural changes, such as increased irritability,
                    withdrawal from school-related activities, or frequent
                    complaints of feeling unwell, can sometimes be linked to
                    academic stress. Pay attention to these shifts as they can
                    indicate that your child is struggling and needs assistance
                    to manage their schoolwork more effectively.
                  </p>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Teacher Feedback{" "}
                    </h2>
                  </li>
                  <p>
                    Teachers spend a significant amount of time with your child
                    and can provide valuable insights into their academic
                    progress and behaviour. If teachers are expressing concerns
                    about your child's performance or suggest that additional
                    support might be beneficial, it’s wise to consider their
                    recommendations.
                  </p>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Difficulty Staying Organized{" "}
                    </h2>
                  </li>
                  <p>
                    If your child often forgets assignments, struggles with time
                    management, or finds it hard to stay organized, these can be
                    signs of underlying academic difficulties. Learning
                    effective organizational skills and study habits through
                    tutoring can greatly improve their academic experience.
                  </p>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Avoidance of Schoolwork{" "}
                    </h2>
                  </li>
                  <p>
                    A strong reluctance to go to school or complete
                    school-related tasks is another indicator. If your child
                    consistently avoids schoolwork or makes excuses to skip it,
                    they may be feeling overwhelmed by their academic
                    responsibilities.
                  </p>
                </ul>

                <h2 className="modal-title1">Taking the Next Step </h2>

                <p className="modal-description">
                  Recognizing these signs is the first step towards helping your
                  child thrive academically. If you suspect your child needs
                  extra support, consider seeking a professional assessment to
                  pinpoint their specific needs. Tutoring can provide
                  personalized attention and tailored strategies to address
                  their challenges, making learning a more enjoyable and
                  successful experience.
                </p>
                <br></br>
                <p className="modal-description">
                  Remember, every child has unique strengths and areas for
                  improvement. Providing the right support can help unlock their
                  full potential and set them on a path to academic success. If
                  you’re unsure where to start, our tutoring services are here
                  to guide you and your child through this journey. Reach out to
                  us today to learn more about how we can help your child
                  achieve their best.
                </p>
              </Box>

              {/* Fixed Done button */}
              <Box
                sx={{
                  borderTop: "1px solid #ccc", // Optional: add a separator
                  paddingTop: 2,
                  textAlign: "center",
                }}
              >
                <button
                  className="global-button"
                  style={{
                    borderRadius: "10px",
                    padding: "10px 20px",
                    cursor: "pointer",
                  }}
                  onClick={handleCloseModal4}
                >
                  Close
                </button>
              </Box>
            </Box>
          </Modal>
          <Modal
            open={openModal5}
            onClose={handleCloseModal5}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: { xs: 400, sm: 400, md: 800 }, // Responsive width
                maxHeight: "100%", // Set maximum height
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
                borderRadius: "10px", // Add border radius
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* Scrollable content */}
              <Box
                sx={{
                  overflowY: "auto",
                  maxHeight: "100%", // Adjust to make room for the button
                  mb: 2,
                }}
              >
                <h2 className="modal-title">
                  The Benefits of Peer Tutoring and Study Groups to Students{" "}
                </h2>
                <p className="modal-description">
                  Imagine a learning environment where your child not only
                  absorbs knowledge but also feels motivated, engaged, and
                  supported by their peers. This is the essence of peer tutoring
                  and study groups – a dynamic approach to education that can
                  transform your child's academic journey.
                </p>
                <h2 className="modal-title">
                  Unlocking Potential through Peer Tutoring{" "}
                </h2>
                <p className="modal-description">
                  Peer tutoring is more than just students helping each other
                  with homework. It's a structured way for learners to
                  collaborate and deepen their understanding of subjects. When
                  your child participates in peer tutoring, they gain several
                  unique advantages:
                </p>
                <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Improved Academic Performance
                    </h2>
                  </li>
                  <p>
                    Peer tutors can often explain concepts in ways that resonate
                    more with fellow students. This leads to a clearer
                    understanding and better retention of material.
                  </p>

                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Enhanced Social Skills
                    </h2>
                  </li>
                  <p>
                    Working with peers fosters communication, teamwork, and
                    empathy. Your child learns to articulate their thoughts and
                    listen actively – skills that are invaluable in and out of
                    the classroom.
                  </p>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Increased Confidence
                    </h2>
                  </li>
                  <p>
                    Explaining concepts to others reinforces the tutor's own
                    knowledge and boosts their confidence. Simultaneously, the
                    tutee gains confidence as they receive guidance and
                    encouragement from a peer.
                  </p>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Positive Attitudes Toward Learning
                    </h2>
                  </li>
                  <p>
                    Learning becomes a shared journey. The camaraderie built
                    through peer tutoring can make studying more enjoyable and
                    less intimidating.
                  </p>
                </ul>

                <h2 className="modal-title">The Power of Study Groups </h2>
                <p className="modal-description">
                  Study groups take the principles of peer tutoring and expand
                  them into a collaborative learning environment.
                </p>
                <h2 className="modal-title">
                  Study groups take the principles of peer tutoring and expand
                  them into a collaborative learning environment.
                </h2>
                <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Shared Knowledge and Resources
                    </h2>
                  </li>
                  <p>
                    Each member of the study group brings unique strengths and
                    perspectives. This pooling of knowledge can provide a more
                    comprehensive understanding of the subject matter.
                  </p>

                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Motivation and Accountability
                    </h2>
                  </li>
                  <p>
                    It's easier to stay motivated when studying with others.
                    Study groups create a sense of accountability, encouraging
                    your child to stay on track with their studies.
                  </p>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Development of Critical Thinking Skills
                    </h2>
                  </li>
                  <p>
                    Group discussions challenge students to think critically,
                    ask questions, and defend their ideas. This deepens their
                    understanding and sharpens their analytical skills.
                  </p>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Stress Reduction
                    </h2>
                  </li>
                  <p>
                    Knowing they are not alone in their academic challenges can
                    alleviate stress and anxiety for your child. Study groups
                    provide emotional support and a sense of community.
                  </p>
                </ul>
                <h2 className="modal-title">
                  Creating a Supportive Learning Environment{" "}
                </h2>
                <p>
                  By integrating these methods, you’ll help your child not only
                  achieve academic success but also develop skills that will
                  serve them throughout their lives. Invest in your child's
                  education today by exploring the benefits of peer tutoring and
                  study groups. Together, we can create a brighter, more
                  connected future for our learners.
                </p>
              </Box>

              {/* Fixed Done button */}
              <Box
                sx={{
                  borderTop: "1px solid #ccc", // Optional: add a separator
                  paddingTop: 2,
                  textAlign: "center",
                }}
              >
                <button
                  className="global-button"
                  style={{
                    borderRadius: "10px",
                    padding: "10px 20px",
                    cursor: "pointer",
                  }}
                  onClick={handleCloseModal5}
                >
                  Close
                </button>
              </Box>
            </Box>
          </Modal>
          <Modal
            open={openModal6}
            onClose={handleCloseModal6}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: { xs: 400, sm: 400, md: 800 }, // Responsive width
                maxHeight: "100%", // Set maximum height
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
                borderRadius: "10px", // Add border radius
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* Scrollable content */}
              <Box
                sx={{
                  overflowY: "auto",
                  maxHeight: "100%", // Adjust to make room for the button
                  mb: 2,
                }}
              >
                <h2 className="modal-title">
                  Unlocking Success: Tips for Helping Your Child Ace
                  Standardized Tests{" "}
                </h2>
                <p className="modal-description">
                  Standardized tests can be a nerve-wracking experience for any
                  child. These exams are not just about what your child knows
                  but also about how well they can apply their knowledge under
                  pressure. The good news is that with the right preparation,
                  your child can approach these tests with confidence and
                  perform their best.
                </p>
                <h2 className="modal-title">
                  Here are some practical tips to help your child prepare
                  effectively for standardized tests.{" "}
                </h2>
                <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Create a Study Schedule{" "}
                    </h2>
                  </li>
                  <p className="modal-description">
                    Consistency is key when it comes to studying for
                    standardized tests. Develop a study schedule that allocates
                    time each day to review different subjects. This helps in
                    building a routine and ensures that all subjects are covered
                    without last-minute cramming.
                  </p>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Break Down the Material{" "}
                    </h2>
                  </li>
                  <p>
                    Standardized tests often cover a wide range of topics. Break
                    down the material into manageable chunks and focus on one
                    chunk at a time. This makes studying less overwhelming and
                    helps your child retain information better.
                  </p>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Practice with Past Papers{" "}
                    </h2>
                  </li>
                  <p>
                    Utilize study aids such as flashcards, educational apps, and
                    workbooks. These tools can make learning fun and
                    interactive, helping your child to stay engaged and retain
                    information more effectively.
                  </p>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Use Study Aids{" "}
                    </h2>
                  </li>
                  <p>
                    Studying for long periods can lead to burnout. Encourage
                    your child to take regular breaks to relax and recharge. A
                    short break every hour can help maintain focus and prevent
                    fatigue.
                  </p>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Encourage Regular Breaks{" "}
                    </h2>
                  </li>
                  <p>
                    A healthy diet, regular exercise, and adequate sleep are
                    crucial for optimal brain function. Ensure your child is
                    eating nutritious meals, getting plenty of physical
                    activity, and sleeping well. A balanced lifestyle can
                    greatly enhance concentration and memory.
                  </p>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Maintain a Balanced Lifestyle{" "}
                    </h2>
                  </li>
                  <p>
                    A positive mindset can make a big difference in how your
                    child approaches their studies and the test itself.
                    Encourage your child to stay positive and remind them that
                    it’s okay to make mistakes – they are part of the learning
                    process.
                  </p>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Foster a Positive Attitude{" "}
                    </h2>
                  </li>
                  <p>
                    Standardized tests can be stressful. Be there to provide
                    emotional support and reassurance. Let your child know that
                    their worth is not determined by their test scores and that
                    you believe in their abilities.
                  </p>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Provide Emotional Support{" "}
                    </h2>
                  </li>
                  <p>
                    If your child needs extra help, consider enrolling them in a
                    tutoring program. Tutors can provide personalized
                    assistance, help with difficult topics, and teach effective
                    test-taking strategies.
                  </p>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Utilize Tutoring Services{" "}
                    </h2>
                  </li>
                  <p>
                    If your child needs extra help, consider enrolling them in a
                    tutoring program. Tutors can provide personalized
                    assistance, help with difficult topics, and teach effective
                    test-taking strategies.
                  </p>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Teach Test-Taking Strategies{" "}
                    </h2>
                  </li>
                  <p>
                    Equip your child with test-taking strategies such as time
                    management, reading instructions carefully, and eliminating
                    obviously wrong answers. These strategies can help them
                    navigate the test more efficiently.
                    <br></br>
                    <br></br>
                    Preparing for standardized tests doesn’t have to be a
                    daunting task. With a well-structured plan, the right
                    resources, and a supportive environment, your child can
                    tackle these exams with confidence and achieve their best
                    possible results. Remember, the goal is not just to pass the
                    test but to build a strong foundation for future learning
                    and success. Happy studying!
                  </p>
                </ul>
              </Box>

              {/* Fixed Done button */}
              <Box
                sx={{
                  borderTop: "1px solid #ccc", // Optional: add a separator
                  paddingTop: 2,
                  textAlign: "center",
                }}
              >
                <button
                  className="global-button"
                  style={{
                    borderRadius: "10px",
                    padding: "10px 20px",
                    cursor: "pointer",
                  }}
                  onClick={handleCloseModal6}
                >
                  Close
                </button>
              </Box>
            </Box>
          </Modal>
          <Modal
            open={openModal7}
            onClose={handleCloseModal7}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: { xs: 400, sm: 400, md: 800 }, // Responsive width
                maxHeight: "100%", // Set maximum height
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
                borderRadius: "10px", // Add border radius
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* Scrollable content */}
              <Box
                sx={{
                  overflowY: "auto",
                  maxHeight: "100%", // Adjust to make room for the button
                  mb: 2,
                }}
              >
                <h2 className="modal-title">
                  Unlocking Your Child's Potential: Top Test-Taking Strategies{" "}
                </h2>
                <p className="modal-description">
                  Every parent wants to see their child succeed, especially when
                  it comes to academic performance. Testing can be a significant
                  source of stress, but with the right strategies, your child
                  can approach their exams with confidence and poise.
                </p>
                <h2 className="modal-title">
                  Here are some proven test-taking strategies to help your child
                  excel.{" "}
                </h2>
                <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Establish a Study Routine{" "}
                    </h2>
                  </li>
                  <p className="modal-description">
                    Routine is the backbone of effective study habits. Encourage
                    your child to set aside specific times each day dedicated to
                    reviewing their notes and homework. This not only helps with
                    information retention but also reduces last-minute cramming,
                    leading to a more relaxed test-taking experience.
                  </p>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Create a Distraction-Free Study Environment{" "}
                    </h2>
                  </li>
                  <p>
                    A quiet, organized space can make all the difference. Ensure
                    your child has a well-lit area free from distractions like
                    TV, mobile phones, and noisy siblings. A dedicated study
                    space helps them focus and signals that it’s time to get
                    serious about their studies.
                  </p>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Practice, Practice, Practice{" "}
                    </h2>
                  </li>
                  <p>
                    Familiarity with test formats can significantly reduce
                    anxiety. Provide your child with practice tests or past
                    papers. This not only helps them get used to the types of
                    questions they might encounter but also improves their time
                    management skills.
                  </p>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Teach Effective Notetaking{" "}
                    </h2>
                  </li>
                  <p>
                    Good notes are invaluable. Encourage your child to highlight
                    key concepts, summarize information in their own words, and
                    use visual aids like charts and diagrams. Effective
                    notetaking can make revision sessions more productive and
                    less overwhelming.
                  </p>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Encourage Regular Breaks{" "}
                    </h2>
                  </li>
                  <p>
                    Studying for long periods can be counterproductive.
                    Encourage your child to take regular breaks to rest and
                    recharge. A short walk, a healthy snack, or even a quick
                    chat can help them return to their studies with renewed
                    focus and energy.
                  </p>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Promote Healthy Sleep Habits{" "}
                    </h2>
                  </li>
                  <p>
                    A well-rested mind is sharper and more alert. Ensure your
                    child gets a good night’s sleep, especially before test
                    days. Late-night cramming can lead to fatigue and decreased
                    performance, so emphasize the importance of a regular sleep
                    schedule.
                  </p>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Teach Test-Taking Techniques{" "}
                    </h2>
                  </li>
                  <p>
                    Simple strategies can make a big difference during the test.
                    Teach your child to read through the entire test first,
                    answer the questions they know well, and then tackle the
                    more challenging ones. Remind them to stay calm, manage
                    their time wisely, and review their answers if time permits.
                    ?
                  </p>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Emphasize the Importance of a Healthy Lifestyle{" "}
                    </h2>
                  </li>
                  <p>
                    A balanced diet, regular exercise, and proper hydration can
                    significantly impact cognitive function and overall
                    well-being. Encourage your child to eat nutritious meals,
                    stay active, and drink plenty of water, especially during
                    the test preparation period.
                  </p>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Provide Positive Reinforcement{" "}
                    </h2>
                  </li>
                  <p>
                    Encouragement and praise can boost your child's confidence
                    and motivation. Celebrate their efforts and progress, no
                    matter how small. Positive reinforcement can inspire them to
                    keep striving for excellence.
                  </p>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Stay Involved and Supportive{" "}
                    </h2>
                  </li>
                  <p>
                    Lastly, be an active participant in your child’s academic
                    journey. Regularly check in on their progress, offer help
                    when needed, and provide emotional support. Your involvement
                    shows them that their education is important and valued.
                    <br></br>
                    <br></br>
                    Implementing these strategies can transform the test-taking
                    experience for your child, turning it from a stressful
                    ordeal into an opportunity to shine. With your support and
                    the right approach, they can develop the skills and
                    confidence needed to excel in their exams and beyond.
                  </p>
                </ul>
              </Box>

              {/* Fixed Done button */}
              <Box
                sx={{
                  borderTop: "1px solid #ccc", // Optional: add a separator
                  paddingTop: 2,
                  textAlign: "center",
                }}
              >
                <button
                  className="global-button"
                  style={{
                    borderRadius: "10px",
                    padding: "10px 20px",
                    cursor: "pointer",
                  }}
                  onClick={handleCloseModal7}
                >
                  Close
                </button>
              </Box>
            </Box>
          </Modal>
          <Modal
            open={openModal8}
            onClose={handleCloseModal8}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: { xs: 400, sm: 400, md: 800 }, // Responsive width
                maxHeight: "100%", // Set maximum height
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
                borderRadius: "10px", // Add border radius
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* Scrollable content */}
              <Box
                sx={{
                  overflowY: "auto",
                  maxHeight: "100%", // Adjust to make room for the button
                  mb: 2,
                }}
              >
                <h2 className="modal-title">
                  Fun and Educational Activities for School Holidays{" "}
                </h2>
                <p className="modal-description">
                  School holidays are here! It's the perfect time for children
                  to rest, recharge, and explore new interests. While it might
                  be tempting to let them spend their days glued to screens,
                  this break offers an opportunity for both fun and learning.
                </p>
                <h2 className="modal-title">
                  Here are some creative and educational activities that can
                  make the holidays memorable and productive for your children.{" "}
                </h2>
                <li>
                  <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                    Science Experiments at Home{" "}
                  </h2>
                </li>
                <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                  <p className="modal-description">
                    Transform your kitchen into a science lab with simple
                    experiments. Making a volcano with baking soda and vinegar,
                    creating slime, or even growing crystals can ignite a
                    passion for science. These hands-on activities are not only
                    fascinating but also educational, helping kids understand
                    basic scientific principles.
                  </p>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Reading Adventures{" "}
                    </h2>
                  </li>
                  <p>
                    Encourage a love for reading by creating a cozy reading
                    nook. Stock it with a variety of books that cater to your
                    child's interests. From fantasy adventures to informative
                    non-fiction, reading can transport them to different worlds
                    and expand their knowledge. Consider a reading challenge
                    with a small reward at the end to keep them motivated.
                  </p>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Art and Craft Projects{" "}
                    </h2>
                  </li>
                  <p>
                    Unleash their creativity with art and craft projects.
                    Whether it's painting, making jewellery, or constructing
                    models, these activities enhance fine motor skills and
                    foster creativity. You can find numerous tutorials online to
                    inspire new projects and keep things fresh.
                  </p>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Educational Outings{" "}
                    </h2>
                  </li>
                  <p>
                    Take advantage of local museums, science centres, and
                    historical sites. These outings provide hands-on learning
                    experiences that are both educational and fun. Many places
                    offer interactive exhibits and special programs for
                    children, making learning an adventure.
                  </p>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Gardening{" "}
                    </h2>
                  </li>
                  <p>
                    Start a small garden together. Planting seeds, watering
                    plants, and watching them grow teaches responsibility and
                    patience. Plus, it's a great way to introduce children to
                    the basics of biology and the environment. If space is
                    limited, indoor herb gardens are a fantastic alternative.
                  </p>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Cooking and Baking{" "}
                    </h2>
                  </li>
                  <p>
                    Get kids involved in the kitchen. Cooking and baking teach
                    important life skills such as measuring, following
                    instructions, and even basic chemistry. Plus, it's a fun way
                    to spend time together and enjoy the delicious results of
                    their efforts.
                  </p>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Educational Games and Apps{" "}
                    </h2>
                  </li>
                  <p>
                    Not all screen time is bad! There are numerous educational
                    games and apps designed to make learning fun. From math
                    puzzles to language learning games, these digital tools can
                    reinforce school subjects and introduce new concepts in an
                    engaging way.
                  </p>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Physical Activities{" "}
                    </h2>
                  </li>
                  <p>
                    Encourage physical activity through sports, dance, or even
                    family hikes. Physical exercise is crucial for overall
                    well-being and can be a great way to explore the outdoors.
                    It also helps children develop teamwork and discipline.
                  </p>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      DIY Projects{" "}
                    </h2>
                  </li>
                  <p>
                    Tackle a DIY project that interests your child. Building a
                    birdhouse, creating a scrapbook, or designing their own
                    board game can be both challenging and rewarding. These
                    projects enhance problem-solving skills and boost
                    creativity.
                  </p>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Online Courses and Workshops{" "}
                    </h2>
                  </li>
                  <p>
                    Explore online courses and workshops tailored for children.
                    Many platforms offer interactive lessons in subjects like
                    coding, music, and art. These can be a great way for
                    children to discover new interests and develop new skills.
                  </p>
                </ul>
                <h2 className="modal-title">Conclusion </h2>

                <p>
                  School holidays can be a perfect blend of fun and learning
                  with a bit of creativity and planning. By engaging in these
                  activities, children can make the most of their break, explore
                  new interests, and continue learning in an enjoyable and
                  relaxed environment. Let's make these holidays unforgettable
                  and enriching for our young learners.
                </p>
              </Box>

              {/* Fixed Done button */}
              <Box
                sx={{
                  borderTop: "1px solid #ccc", // Optional: add a separator
                  paddingTop: 2,
                  textAlign: "center",
                }}
              >
                <button
                  className="global-button"
                  style={{
                    borderRadius: "10px",
                    padding: "10px 20px",
                    cursor: "pointer",
                  }}
                  onClick={handleCloseModal8}
                >
                  Close
                </button>
              </Box>
            </Box>
          </Modal>
          <Modal
            open={openModal9}
            onClose={handleCloseModal9}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: { xs: 400, sm: 400, md: 800 }, // Responsive width
                maxHeight: "100%", // Set maximum height
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
                borderRadius: "10px", // Add border radius
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* Scrollable content */}
              <Box
                sx={{
                  overflowY: "auto",
                  maxHeight: "100%", // Adjust to make room for the button
                  mb: 2,
                }}
              >
                <h2 className="modal-tittle">
                  Top Study Tips for High School and Primary Students{" "}
                </h2>
                <p className="modal-description">
                  In today's fast-paced world, helping children develop
                  effective study habits is more crucial than ever. With the
                  right strategies, students can excel academically, build
                  confidence, and enjoy learning.
                </p>
                <h2 className="modal-tittle" style={{ fontSize: "20px" }}>
                  Here are some tried-and-true study tips tailored for both high
                  school and primary students that can make a significant
                  difference.{" "}
                </h2>
                <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                  <li>
                    <h2 className="modal-title3" style={{ fontSize: "20px" }}>
                      Create a Dedicated Study Space{" "}
                    </h2>
                  </li>
                  <p className="modal-description">
                    A clutter-free, quiet study area can help minimize
                    distractions and boost concentration. Ensure this space is
                    well-lit and equipped with necessary supplies, like
                    notebooks, pens, and a computer. For younger children,
                    adding colourful and educational decorations can make the
                    space inviting and fun.
                  </p>
                  <li>
                    <h2 className="modal-title3" style={{ fontSize: "20px" }}>
                      Establish a Consistent Routine{" "}
                    </h2>
                  </li>
                  <p className="modal-description">
                    Consistency is key. Set a regular study schedule that fits
                    your child’s natural rhythm, balancing study time with
                    breaks and leisure activities. For high school students,
                    encourage them to identify their peak productivity times,
                    whether it’s early in the morning or late at night, and plan
                    their study sessions accordingly.
                  </p>
                  <li>
                    <h2 className="modal-title3" style={{ fontSize: "20px" }}>
                      Break Down Tasks{" "}
                    </h2>
                  </li>
                  <p className="modal-description">
                    Large projects and study material can overwhelm students.
                    Teach them to break down tasks into manageable chunks. This
                    approach, known as "chunking," can make studying more
                    efficient and less intimidating. Primary students might
                    benefit from visual aids like charts or diagrams to segment
                    their tasks.
                  </p>
                  <li>
                    <h2 className="modal-title3" style={{ fontSize: "20px" }}>
                      Encourage Active Learning{" "}
                    </h2>
                  </li>
                  <p className="modal-description">
                    Passive reading is often less effective than active
                    engagement. Encourage your child to summarize information in
                    their own words, create flashcards, or teach the material to
                    someone else. For high school students, discussing topics
                    with peers or joining study groups can also be highly
                    beneficial.
                  </p>
                  <li>
                    <h2 className="modal-title3" style={{ fontSize: "20px" }}>
                      Use Technology Wisely
                    </h2>
                  </li>
                  <p className="modal-description">
                    There are numerous educational apps and online resources
                    that can enhance learning. However, it’s important to
                    monitor screen time and ensure it’s being used productively.
                    Tools like educational games for primary students or online
                    quizzes and videos for high schoolers can make studying more
                    interactive and enjoyable.
                  </p>
                  <li>
                    <h2 className="modal-title3" style={{ fontSize: "20px" }}>
                      Prioritize Rest and Nutrition{" "}
                    </h2>
                  </li>
                  <p className="modal-description">
                    Adequate sleep and a balanced diet are critical for
                    cognitive function. Make sure your child gets enough rest,
                    especially before exams. Healthy snacks like fruits, nuts,
                    and whole grains can also keep their energy levels stable
                    and their minds sharp.
                  </p>
                  <li>
                    <h2 className="modal-title3" style={{ fontSize: "20px" }}>
                      Practice Time Management
                    </h2>
                  </li>
                  <p className="modal-description">
                    Teach your child the importance of planning and prioritizing
                    tasks. High school students can benefit from planners or
                    digital calendars to keep track of assignments and
                    deadlines. Primary students can start with simple to-do
                    lists to help them manage their time effectively.
                  </p>
                  <li>
                    <h2 className="modal-title3" style={{ fontSize: "20px" }}>
                      Celebrate Progress{" "}
                    </h2>
                  </li>
                  <p className="modal-description">
                    Recognize and celebrate your child’s efforts and
                    achievements, no matter how small. Positive reinforcement
                    can boost their motivation and self-esteem. This could be as
                    simple as verbal praise, a fun outing, or a small reward for
                    their hard work.
                    <br></br>
                    <br></br>
                    By implementing these strategies, you can help your child
                    develop strong study habits that will serve them well
                    throughout their academic journey. Remember, the goal is to
                    create a supportive environment that fosters a love for
                    learning and helps them reach their full potential. With
                    your guidance and the right tools, your child can achieve
                    great things.
                  </p>
                </ul>
              </Box>

              {/* Fixed Done button */}
              <Box
                sx={{
                  borderTop: "1px solid #ccc", // Optional: add a separator
                  paddingTop: 2,
                  textAlign: "center",
                }}
              >
                <button
                  className="global-button"
                  style={{
                    borderRadius: "10px",
                    padding: "10px 20px",
                    cursor: "pointer",
                  }}
                  onClick={handleCloseModal9}
                >
                  Close
                </button>
              </Box>
            </Box>
          </Modal>
          <Modal
            open={openModal10}
            onClose={handleCloseModal10}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: { xs: 400, sm: 400, md: 800 }, // Responsive width
                maxHeight: "100%", // Set maximum height
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
                borderRadius: "10px", // Add border radius
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* Scrollable content */}
              <Box
                sx={{
                  overflowY: "auto",
                  maxHeight: "100%", // Adjust to make room for the button
                  mb: 2,
                }}
              >
                <h2 className="modal-tittle">
                  Unlock the Joy of Reading: Boost Your Child's Skills Today{" "}
                </h2>
                <p className="modal-description">
                  Reading is the gateway to endless worlds and infinite
                  knowledge. Watching your child struggle with reading can be
                  tough, but with a little guidance, you can help them turn the
                  page to success. Here are some practical tips and engaging
                  activities to enhance your child’s reading comprehension and
                  fluency, setting them on the path to becoming confident
                  readers.
                </p>
                <h2 className="modal-tittle">
                  Create a Reading-Friendly Environment{" "}
                </h2>
                <p className="modal-description">
                  A cozy reading nook can make all the difference. Fill it with
                  a variety of books that cater to your child's interests.
                  Comfortable seating, good lighting, and a quiet space free
                  from distractions will make reading time something they look
                  forward to.
                </p>
                <h2 className="modal-tittle">Set a Daily Reading Routine </h2>
                <p className="modal-description">
                  Consistency is key. Establish a daily reading routine, even if
                  it’s just for 15 minutes. This regular practice helps build
                  their reading stamina and makes reading a natural part of
                  their day.
                </p>
                <h2 className="modal-tittle">Read Aloud Together </h2>
                <p className="modal-description">
                  Reading aloud is a powerful tool. It not only improves your
                  child’s listening skills but also exposes them to the rhythm
                  and melody of the language. Choose books that are slightly
                  above their reading level to introduce new vocabulary and
                  concepts.
                </p>
                <h2 className="modal-tittle">Discuss the Story </h2>
                <p className="modal-description">
                  Engage in discussions about the book. Ask open-ended questions
                  like, “What do you think will happen next?” or “Why do you
                  think the character acted that way?” This encourages critical
                  thinking and deepens their understanding of the story.
                </p>
                <h2 className="modal-tittle">Use Technology Wisely </h2>
                <p className="modal-description">
                  Educational apps and audiobooks can be wonderful supplements
                  to traditional reading. Interactive stories and games can make
                  learning fun and reinforce reading skills in a playful manner.
                </p>
                <h2 className="modal-tittle">Encourage Writing </h2>
                <p className="modal-description">
                  Writing and reading go hand in hand. Encourage your child to
                  write stories, letters, or even a daily journal. This practice
                  enhances their understanding of language structure and
                  improves their ability to express ideas clearly.
                </p>
                <h2 className="modal-tittle">Play Word Games </h2>
                <p className="modal-description">
                  Incorporate word games like Scrabble, Boggle, or crossword
                  puzzles into your family time. These games build vocabulary
                  and spelling skills while keeping your child engaged and
                  entertained.
                </p>
                <h2 className="modal-tittle">Visit the Library </h2>
                <p className="modal-description">
                  Make library visits a regular adventure. Let your child
                  explore different genres and pick out books that catch their
                  interest. Libraries often have reading programs and events
                  that can further spark their love for reading.
                </p>
                <h2 className="modal-tittle">Be a Reading Role Model </h2>
                <p className="modal-description">
                  Children often mimic the behaviour of adults around them. Show
                  your enthusiasm for reading by letting your child see you
                  read. Share your favourite books and discuss them together.
                </p>
                <h2 className="modal-tittle">Celebrate Progress </h2>
                <p className="modal-description">
                  Acknowledge your child’s efforts and celebrate their reading
                  milestones. Positive reinforcement boosts their confidence and
                  motivates them to keep improving.
                </p>
                <h2 className="modal-tittle">Play Word Games </h2>
                <p className="modal-description">
                  Engage in discussions about the book. Ask open-ended questions
                  like, “What do you think will happen next?” or “Why do you
                  think the character acted that way?” This encourages critical
                  thinking and deepens their understanding of the story.
                </p>
                <h2 className="modal-title">Fun Reading Activities</h2>
                <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Book Bingo
                    </h2>
                    <p>
                      Create a bingo card with different genres or themes. Each
                      time your child reads a book that fits a category, they
                      can mark it off. Offer a small reward for completing a row
                      or the entire card.
                    </p>
                  </li>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Reading Scavenger Hunt
                    </h2>
                    <p>
                      Make a list of items or themes for your child to find in
                      their books. For example, “Find a story with a talking
                      animal” or “Find a book set in a magical world.” This
                      keeps reading exciting and interactive.
                    </p>
                  </li>
                  <li>
                    <h2 className="modal-title4" style={{ fontSize: "20px" }}>
                      Story Mapping
                    </h2>
                    <p>
                      After reading a book, have your child draw a map of the
                      story’s setting or a timeline of events. This visual
                      representation helps with comprehension and retention.
                    </p>
                  </li>
                </ul>
                <br></br>
                <p>
                  By incorporating these tips and activities, you'll create a
                  supportive environment that fosters your child's love for
                  reading. Remember, the goal is to make reading a joyful and
                  enriching experience that opens new worlds for them to
                  explore. Happy reading!
                </p>
              </Box>

              {/* Fixed Done button */}
              <Box
                sx={{
                  borderTop: "1px solid #ccc", // Optional: add a separator
                  paddingTop: 2,
                  textAlign: "center",
                }}
              >
                <button
                  className="global-button"
                  style={{
                    borderRadius: "10px",
                    padding: "10px 20px",
                    cursor: "pointer",
                  }}
                  onClick={handleCloseModal10}
                >
                  Close
                </button>
              </Box>
            </Box>
          </Modal>

          <Footer />
          <div className="blog-header">
            <Header />
          </div>
        </>
      )}
    </>
  );
}
