import { Formik, Form } from 'formik';
import React, { useEffect, useState } from 'react';
import { MySelect, MyTextInput } from '../services/web/inputServices';
import {
  addGrade,
  getAllGrade,
  delete_grade,
  getCourses,
} from '../services/web/webServices';
import Footer from './Footer';
import Header from './Header';
import ProfileSideMenu from './ProfileSideMenu';
import * as Yup from 'yup';
import { Modal } from 'react-bootstrap';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { Loader } from '../helper/Loader';
import LinearProgress from '@mui/material/LinearProgress';
import { Store } from 'react-notifications-component';
import { MenuItem, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
const css = `
.css-eglki6-MuiLinearProgress-root{
  width:23% !important;
  
  .profile-list li:nth-child(3) a {
    color: #0086db !important;
  }
}
`;
export default function Grade() {
  const [getAllPopularCourse, setAllPopularCourse] = useState({});

  const [getGradeDetails, setGradeDetails] = useState([]);
  const [show, setShow] = useState(false);
  const [getLoader, setLoader] = useState(true);
  const [studentGrade, setStudentGrade] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const getApi = () => {
    getAllGrade()
      .then((res) => {
        // console.log("helloadsadsdd===>", res.data.response);
        setGradeDetails(
          res.data.response.map((el, index) => ({
            ...el,
            id: el._id,
            i: index,
          }))
        );
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
    getCourses()
      .then((res) => {
        setAllPopularCourse(res.data.response);
        console.log('------getpopular course', res.data.response);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getApi();
    getCourses()
      .then((res) => {
        setAllPopularCourse(res.data.response);
        console.log('------getpopular course', res.data.response);
      })
      .catch((err) => {
        console.log(err);
      });

  }, []);

  const handleChange = (event) => {
    setStudentGrade(event.target.value);
  };

  const columns = [
    {
      field: 'school',
      headerName: 'School / University',
      width: 250,
      editable: true,
    },
    {
      field: 'country',
      headerName: 'Country',
      width: 250,
      editable: true,
    },
    {
      field: 'province',
      headerName: 'Province',
      width: 250,
      editable: true,
    },
    {
      field: 'city',
      headerName: 'City',
      width: 250,
      editable: true,
    },
    {
      field: 'town',
      headerName: 'Suburb/township',
      width: 250,
      editable: true,
    },
    {
      field: 'grade',
      headerName: 'Grade',
      width: 250,
      editable: true,
    },
    {
      field: 'subjects',
      headerName: 'Subjects',
      width: 250,
      editable: true,
    },
    {
      field: 'home_scholar',
      headerName: 'Home Schooler',
      width: 250,
      editable: true,
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 250,
      editable: false,
      renderCell: (item) => {
        return (
          <>
            <i
              className='fa fa-trash'
              aria-hidden='true'
              style={{ color: 'red', cursor: 'pointer' }}
              onClick={() => {
                delete_grade(item?.row?._id)
                  .then((res) => {
                    Store.addNotification({
                      title: 'Success',
                      message: res?.data?.message,
                      type: 'success',
                      insert: 'top',
                      container: 'top-right',
                      animationIn: ['animate__animated', 'animate__fadeIn'],
                      animationOut: ['animate__animated', 'animate__fadeOut'],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                      },
                    });
                    getApi();
                  })
                  .catch((err) => {
                    if (err) {
                      Store.addNotification({
                        title: 'Error!',
                        message: err?.response?.data?.message,
                        type: 'danger',
                        insert: 'top',
                        container: 'top-right',
                        animationIn: ['animate__animated', 'animate__fadeIn'],
                        animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                          duration: 5000,
                          onScreen: true,
                        },
                      });
                    }
                  });
              }}
            ></i>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Header />
      <style>{css}</style>
      <div
        className='section page-banner-section bg-color-1'
        style={{ marginTop: '29px' }}
      >
        <div className='container'>
          {/* <!-- Page Banner Content Start --> */}
          <div className='page-banner-content'>
            <h2 className='title'>My Course</h2>
            <ul className='breadcrumb justify-content-center'>
              <li className='breadcrumb-item'>
                <a href=''>Home</a>
              </li>
              <li className='breadcrumb-item active'>My Course</li>
            </ul>
          </div>
          {/* <!-- Page Banner Content End --> */}
        </div>
      </div>
      <div style={{ clear: 'both' }}></div>
      <div className='container new-grade-width '>
        <div className='profile-container'>
          <div className='profile-grid'>
            <ProfileSideMenu />
            <div className='profile-grid-right'>
              <div
                className='profile-heading-flex wow fadeInUp'
                data-wow-duration='1s'
                data-wow-delay='.2s'
                style={{
                  visibility: 'visible',
                  animationDuration: '1s',
                  animationDelay: '0.4s',
                }}
              >
                <h2>Grade</h2>
                <div className='course-filter new-filter'>
                  <a style={{ cursor: 'pointer' }} onClick={handleShow}>
                    <i className='fas fa-plus-circle'></i> Add Grade
                  </a>
                </div>
              </div>

              {getLoader === true ? (
                <Box sx={{ width: '100%' }}>
                  <LinearProgress />
                </Box>
              ) : (
                <Box sx={{ height: 500, width: '100%' }}>
                  {getGradeDetails.length > 0 ? (
                    <DataGrid
                      rows={getGradeDetails}
                      columns={columns}
                      pageSize={5}
                      rowsPerPageOptions={[5]}
                    />
                  ) : (
                    'No Grade Found!!!'
                  )}
                </Box>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Modal  Grade*/}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Grade</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='modal-body grade-pop new-grade-pop '>
            <div className='login-right  share-que'>
              <div className='login-ins-sec'>
                <Formik
                  initialValues={{
                    school: '',
                    country: '',
                    province: '',
                    city: '',
                    town: '',
                    grade: '',
                    subjects: '',
                    home_scholar: '',
                  }}
                  validationSchema={Yup.object({
                    school: Yup.string().required(),
                    country: Yup.string().required(),
                    province: Yup.string().required(),
                    city: Yup.string().required(),
                    town: Yup.string().required(),
                    grade: Yup.string().required(),
                    subjects: Yup.string().required(),
                    home_scholar: Yup.string().required(),
                  })}
                  onSubmit={(values) => {
                    // values.grade = studentGrade;
                    console.log(values);
                    addGrade(values)
                      .then((res) => {
                        getAllGrade()
                          .then((res) => {
                            setGradeDetails(
                              res.data.response.map((el, index) => ({
                                ...el,
                                id: el._id,
                                i: index,
                              }))
                            );
                            setShow(false);
                          })
                          .catch((err) => {
                            console.log(err);
                          });
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }}
                >
                  {(props) => (
                    <Form>
                      <div className='gr-g'>
                        <div>
                          <div className='form-group'>
                            <label>School / University</label>
                            <MyTextInput type='text' name='school' />
                          </div>
                        </div>
                        <div>
                          <div className='form-group'>
                            <label>Country</label>
                            <MyTextInput type='text' name='country' />
                          </div>
                        </div>
                        <div>
                          <div className='form-group'>
                            <label>Province</label>

                            <select
                              name='province'
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              value={props.values.province}
                            >
                              <option value='Not Selected'>
                                Select Province
                              </option>
                              <option value='Free State'>Free State</option>
                              <option value='Gauteng'>Gauteng</option>
                              <option value='KwaZulu-Natal'>
                                KwaZulu-Natal
                              </option>
                              <option value='Limpopo'>Limpopo</option>
                              <option value='Mpumalanga'>Mpumalanga</option>
                              <option value='Northern Cape'>
                                Northern Cape
                              </option>
                              <option value='North West'>North West</option>
                              <option value='Western Cape'>Western Cape</option>
                              <option value='Eastern Cape'>Eastern Cape</option>
                            </select>
                            {props.errors.province &&
                              props.touched.province && (
                                <div style={{ color: 'red' }}>
                                  {props.errors.province}
                                </div>
                              )}
                          </div>
                        </div>
                        <div>
                          <div className='form-group'>
                            <label>City</label>
                            <MyTextInput type='text' name='city' />
                          </div>
                        </div>
                        <div>
                          <div className='form-group'>
                            <label>Suburb/township</label>
                            <MyTextInput type='text' name='town' />
                          </div>
                        </div>
                        <div>
                          <div className='form-group'>
                            <label>Grade</label>

                            <select
                              name='grade'
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              value={props.values.grade}
                            >
                              <option value='Not Selected'>Select Grade</option>
                              <option value='1'>1</option>
                              <option value='2'>2</option>
                              <option value='3'>3</option>
                              <option value='4'>4</option>
                              <option value='5'>5</option>
                              <option value='6'>6</option>
                              <option value='7'>7</option>
                              <option value='8'>8</option>
                              <option value='9'>9</option>
                              <option value='10'>10</option>
                              <option value='11'>11</option>
                              <option value='12'>12</option>
                            </select>
                            {props.errors.grade && props.touched.grade && (
                              <div style={{ color: 'red' }}>
                                {props.errors.grade}
                              </div>
                            )}
                          </div>
                        </div>
                        <div>
                          <div className='form-group'>
                            <label>Subjects</label>
                            {/* <MyTextInput type="text" name="subjects" /> */}

                            <MySelect type='text' name='subjects'>
                              <option readOnly>Select</option>
                              {getAllPopularCourse.length > 0
                                ? getAllPopularCourse.map((item) => {
                                    return (
                                      <option value={item?.course_title}>
                                        {item?.course_title}
                                      </option>
                                    );
                                  })
                                : ''}
                            </MySelect>
                          </div>
                        </div>
                        <div>
                          <div className='form-group'>
                            <label>Home Schooler</label>
                            <select
                              name='home_scholar'
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              value={props.values.home_scholar}
                            >
                              <option value='Not Selected'>
                                Select Home Schooler
                              </option>
                              <option value='Yes'>Yes</option>
                              <option value='No'>No</option>
                            </select>
                            {props.errors.home_scholar &&
                              props.touched.home_scholar && (
                                <div style={{ color: 'red' }}>
                                  {props.errors.home_scholar}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <button className='btn btn-primary btn-hover-heading-color cmn-btn'>
                        Submit
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  );
}
