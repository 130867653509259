import { Store } from 'react-notifications-component';
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { coach_forgot_email_verification } from '../services/web/webServices';
import base_uri from '../api/base_url';
export default function CoachForgotPassword() {
  const { token } = useParams();
  const data = token
  const type = "password";
  localStorage.setItem("auth_token_coach",token);
  useEffect(() => {
    coach_forgot_email_verification(type, data).then((res) => {
      window.location.href = `${base_uri.coach_base_url}reset_password`;
      // Store.addNotification({
      //   title: "Success",
      //   message: res?.data?.message,
      //   type: "success",
      //   insert: "top",
      //   container: "top-right",
      //   animationIn: ["animate__animated", "animate__fadeIn"],
      //   animationOut: ["animate__animated", "animate__fadeOut"],
      //   dismiss: {
      //     duration: 5000,
      //     onScreen: true,
      //   },
      // });
    }
    ).catch((err) => {
      Store.addNotification({
        title: "Error!",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });

    })
  }, []);
}
