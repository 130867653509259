import React, { useState } from "react";
import { Link } from "react-router-dom";
import { signup } from "../services/web/webServices";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import { MyTextInput } from "../services/web/inputServices";
import * as Yup from "yup";
import { Store } from "react-notifications-component";
export default function SignUp() {
  const [getButton, setButton] = useState(true);
  const navigate = useNavigate();
  const [getType, setType] = useState("password");
  const handle_password = () => {
    if (getType === "text") {
      setType("password");
    } else {
      setType("text");
    }
  };
  return (
    <>
      <div className="  login-main-outer">
        {/* <!-- Preloader Start  --> */}

        <div className="login-container">
          <div className="log-s">
            <div className="login-left">
              <div className="comlog abs-login1">
                <img src="images/q1.png" />
              </div>
              <div className="comlog  abs-login2">
                <img src="images/q2.png" />
              </div>
              <div className="comlog  abs-login3">
                <img src="images/q3.png" />
              </div>
              <div className="comlog  abs-login6">
                <img src="images/q3.png" />
              </div>
              <div className="comlog  abs-login4">
                <img src="images/q4.jpg" />
              </div>
              <div className="comlog  abs-login5">
                <img src="images/q1.png" />
              </div>
              <h3>
                Engineering, Sales, ICT, and Self development / coaching
                expertise.
              </h3>
            </div>
            <div className="login-right  share-que">
              <div className="login-logo">
                <Link to="/">
                  <img src="images/logo.webp" alt="" />
                </Link>
              </div>
              <div className="login-ins-sec">
                <div className="section-title  ">
                  <h2 className="title">Sign Up!</h2>
                </div>

                <Formik
                  initialValues={{
                    name: "",
                    email: "",
                    phone: "",
                    password: "",
                    age: "",
                    father_email: "",
                    father_phone_no: "",
                    privacy_policy: false,
                    terms_conditions: false,
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (values.age < 16) {
                      if (values.father_email == "") {
                        errors.father_email = "Parent email id is required";
                      }
                    }
                    if (values.age < 16) {
                      if (values.father_phone_no == "") {
                        errors.father_phone_no =
                          "Parent contact detail is required";
                      }
                    }
                    return errors;
                  }}
                  validationSchema={Yup.object({
                    name: Yup.string()
                      .max(40)
                      .matches(/^[A-Za-z ]*$/, "Please enter valid name")
                      .required("Name is required"),
                    email: Yup.string()
                      .email()
                      .required("Student Email is required"),
                    phone: Yup.number()
                      .typeError("That doesn't look like a phone number")
                      .positive("A phone number can't start with a minus")
                      .integer("A phone number can't include a decimal point")
                      .min(6)
                      .required("Phone number is required"),
                    father_phone_no: Yup.number()
                      .typeError("That doesn't look like a phone number")
                      .positive("A phone number can't start with a minus")
                      .integer("A phone number can't include a decimal point")
                      .min(6),
                    age: Yup.number()
                      .typeError("That doesn't look like a Age ")
                      .positive(" Age  can't start with a minus")
                      .integer(" Age  can't include a decimal point")
                      .max(60)
                      .required("Age is required"),

                    password: Yup.string()
                      .min(
                        8,
                        "Password is too short - should be 8 Characters minimum."
                      )
                      .required("Password is required"),
                    privacy_policy: Yup.bool().oneOf(
                      [true],
                      "Pravacy policy agreement is required"
                    ),
                    terms_conditions: Yup.bool().oneOf(
                      [true],
                      "Terms and conditions agreement is required"
                    ),
                  })}
                  onSubmit={(values, { resetForm }) => {
                    setButton(false);
                    signup(values)
                      .then((res) => {
                        setButton(true);

                        resetForm({ values: "" });
                        Store.addNotification({
                          title: "Success",
                          message: res?.data?.message,
                          type: "success",
                          insert: "top",
                          container: "top-right",
                          animationIn: ["animate__animated", "animate__fadeIn"],
                          animationOut: [
                            "animate__animated",
                            "animate__fadeOut",
                          ],
                          dismiss: {
                            duration: 5000,
                            onScreen: true,
                          },
                        });
                        navigate("/login");
                      })
                      .catch((err) => {
                        setButton(true);
                        if (err) {
                          Store.addNotification({
                            title: "Error!",
                            message: err?.response?.data?.message,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: [
                              "animate__animated",
                              "animate__fadeIn",
                            ],
                            animationOut: [
                              "animate__animated",
                              "animate__fadeOut",
                            ],
                            dismiss: {
                              duration: 5000,
                              onScreen: true,
                            },
                          });
                        }
                      });
                  }}
                >
                  {(props) => (
                    <Form>
                      <div>
                        <div className="form-group">
                          <label>Name</label>
                          <MyTextInput
                            type="text"
                            name="name"
                            placeholder="Name *"
                            // required="true"
                          />
                        </div>
                      </div>

                      <div>
                        <div className="form-group">
                          <label>Student Email</label>
                          <MyTextInput
                            type="email"
                            name="email"
                            placeholder="Student Email *"
                            // required="true"
                          />
                        </div>
                      </div>
                      <div>
                        <div className="form-group">
                          <label>Student Phone Number</label>
                          <MyTextInput
                            type="text"
                            name="phone"
                            placeholder="Student Phone Number *"
                            // required="true"
                          />
                        </div>
                      </div>
                      <div>
                        <div className="form-group">
                          <label>Age</label>
                          <MyTextInput
                            type="text"
                            name="age"
                            placeholder="Age  *"
                            // required="true"
                          />
                        </div>
                      </div>
                      {props.values.age < 16 && !props.values.age == "" ? (
                        <div>
                          <div className="form-group">
                            <label>Parent Email ID</label>
                            <MyTextInput
                              type="email"
                              name="father_email"
                              placeholder="Parent Email ID *"
                              // required="true"
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {props.values.age < 16 && !props.values.age == "" ? (
                        <div>
                          <div className="form-group">
                            <label>Parent Contact Number</label>
                            <MyTextInput
                              type="text"
                              name="father_phone_no"
                              placeholder="Parent Phone Number *"
                              // required="true"
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      <div>
                        <div className="form-group">
                          <label>Password</label>
                          {getType === "text" ? (
                            <span
                              className="fas fa-eye toggle-password"
                              onClick={handle_password}
                              style={{
                                cursor: "pointer",
                                position: "absolute",
                                left: "91%",
                                top: "53%",
                              }}
                            ></span>
                          ) : (
                            <i
                              className="fa fa-eye-slash"
                              aria-hidden="true"
                              onClick={handle_password}
                              style={{
                                cursor: "pointer",
                                position: "absolute",
                                left: "91%",
                                top: "53%",
                              }}
                            ></i>
                          )}
                          <input
                            type={getType}
                            name="password"
                            placeholder="Password*"
                            onChange={(e) => {
                              props.values.password = e.target.value;
                            }}
                          />
                          {props.errors.password && props.touched.password && (
                            <div
                              style={{
                                color: "red",
                                position: "absolute",
                                fontSize: "12px",
                                bottom: "-17px",
                              }}
                            >
                              {props.errors.password}
                            </div>
                          )}
                        </div>
                      </div>
                      <div>
                        <div>
                          {getType === "text" ? "" : ""}
                          <label
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <input
                              type="checkbox"
                              name="privacy_policy"
                              checked={props.values.privacy_policy}
                              onChange={(e) => {
                                props.setFieldValue(
                                  "privacy_policy",
                                  e.target.checked
                                );
                              }}
                              style={{ width: "20px", marginRight: "5px" }}
                            />
                            I agree to the ethuta
                            <a
                              href="https://ethutalearning.blob.core.windows.net/admincontainer/Standard Website Privacy and Cookies Policy.pdf"
                              target="_blank"
                              style={{ marginLeft: "3px", color: "blue" }}
                            >
                              Privacy Policy
                            </a>
                          </label>
                          {props.errors.privacy_policy &&
                            props.touched.privacy_policy && (
                              <div style={{ color: "red", fontSize: "12px" }}>
                                {props.errors.privacy_policy}
                              </div>
                            )}
                          <label
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <input
                              type="checkbox"
                              name="terms_conditions"
                              checked={props.values.terms_conditions}
                              onChange={(e) => {
                                props.setFieldValue(
                                  "terms_conditions",
                                  e.target.checked
                                );
                              }}
                              style={{ width: "20px", marginRight: "5px" }}
                            />
                            I agree to the ethuta
                            <a
                              href="https://ethutalearning.blob.core.windows.net/admincontainer/Client Terms & Conditions for Tutoring  .pdf"
                              target="_blank"
                              style={{ marginLeft: "3px", color: "blue" }}
                            >
                              Terms and Conditions
                            </a>
                          </label>
                          {props.errors.terms_conditions &&
                            props.touched.terms_conditions && (
                              <div style={{ color: "red", fontSize: "12px" }}>
                                {props.errors.terms_conditions}
                              </div>
                            )}
                        </div>
                      </div>
                      {getButton === true ? (
                        <button
                          className="btn btn-primary btn-hover-heading-color cmn-btn"
                          type="submit"
                          disabled={
                            !props.values.privacy_policy ||
                            !props.values.terms_conditions
                          }
                        >
                          Sign Up
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary btn-hover-heading-color cmn-btn"
                          disabled
                        >
                          wait please!!
                        </button>
                      )}

                      <div className="tester_bug">
                        Already have an account?{" "}
                        <Link to="/login" className="forgot-pass alr">
                          Login
                        </Link>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
