import React, { useState } from "react";
import InsideHeaderForBecomeCoach from "./InsideHeader/InsideHeaderForBecomeCoach";
import { Link } from "react-router-dom";
import { MyTextInput, MyTextArea } from "../services/web/inputServices";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { coach_signup } from "../services/web/webServices";
import { Store } from "react-notifications-component";
import Footer from "./Footer";
import InsideHeader from "./InsideHeader";
import Header from "./Header";
export default function BecomeCoach() {
  const [getButton, setButton] = useState(true);
  const [getCv, setCv] = useState(null);
  return (
    <>
      <Header />
      <div style={{ clear: "both" }}></div>
      <div className="abt-become">
        <div className="container">
          <div className="abt-become-grid">
            <div
              className="joinbecome wow fadeInLeft"
              data-wow-duration="1s"
              data-wow-delay=".5s"
              style={{
                visibility: "visible",
                animationDuration: "1s",
                animationDelay: "0.5s",
              }}
            >
              <h2>
                Join the eThuta team to <span>Become an Academic Coach</span>
              </h2>
              <p>
              Unlock rewarding opportunities with our tutoring jobs. We offer fulfilling roles that empower you to make a difference in students' lives.
              </p>
              {/* <Link
                to='/becomecoach'
                className='global-button wow fadeInUp'
                data-wow-duration='1s'
                data-wow-delay='.3s'
                style={{
                  visibility: 'visible',
                  animationDuration: '1s',
                  animationDelay: '0.3s',
                }}
              >
                Become a Coach
              </Link> */}
            </div>
            <div
              className="video-image  wow fadeInRight"
              data-wow-duration="1s"
              data-wow-delay=".2s"
              style={{
                visibility: "visible",
                animationDuration: "1s",
                animationDelay: "0.2s",
              }}
            >
              <figure>
                <img src="images/BecomeACoach2.jpg" />
                <div className="video-gallery-wrapper edu-section-gapTop video-section-overlayto-another ">
                  <div className="video-gallery-1">
                    <div className="thumbnail video-popup-wrapper">
                      <button className="video-play-btn with-animation position-to-top video-popup-activation btn-secondary-color size-80">
                        <span>
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 448 512"
                            className="play-icon"
                            height="1em"
                            width="1em"
                          >
                            <path d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"></path>
                          </svg>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </figure>
            </div>
          </div>
        </div>
      </div>
      <div className="about-top pt-120">
        <div className="container become-coach">
          <div className="abt-top-grid becomeAcoachRightTop">
            <div className="about-top-left">
              <h2
                className="wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
                style={{
                  visibility: "visible",
                  animationDuration: "1s",
                  animationDelay: "0.2s",
                }}
              >
                Become an Academic Coach Now!
              </h2>
              <p
                className="wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
                style={{
                  visibility: "visible",
                  animationDuration: "1s",
                  animationDelay: "0.2s",
                }}
              >
                We are searching for flexible academic coaches for one on one
                tutoring and academic video creators focused on Primary, High
                school and University subjects.{" "}
              </p>
              <ul>
                <li
                  className="wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay=".2s"
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: "0.2s",
                  }}
                >
                  <i className="fas fa-check-circle"></i>Mentors
                </li>
                <li
                  className="wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay=".3s"
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: "0.3s",
                  }}
                >
                  <i className="fas fa-check-circle"></i>Lesson Planning
                </li>
                <li
                  className="wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay=".4s"
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: "0.4s",
                  }}
                >
                  <i className="fas fa-check-circle"></i> Flexibility
                </li>
              </ul>
            </div>
            <div className="about-top-right ">
              <div className="login-ins-sec">
                <div className="section-title  ">
                  <h2 className="title">
                    We need a few details to set up things for you!
                  </h2>
                </div>
                <Formik
                  initialValues={{
                    coach_name: "",
                    coach_email: "",
                    phone: "",
                    coach_password: "",
                    confirmPassword: "",
                    coach_alternative_phone: "",
                    cv: "",
                    short_bio: "",
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!getCv) {
                      errors.cv = "Fill your cv it is required!";
                    }
                    return errors;
                  }}
                  validationSchema={Yup.object({
                    coach_name: Yup.string()
                      .max(40)
                      .matches(/^[A-Za-z ]*$/, "Please enter valid name")
                      .required("Please fill name"),
                    short_bio: Yup.string().required("Please fill short bio"),
                    phone: Yup.number()
                      .typeError("That doesn't look like a phone number")
                      .positive("A phone number can't start with a minus")
                      .integer("A phone number can't include a decimal point")
                      .min(6)
                      .required("Please fill  phone number"),
                    coach_alternative_phone: Yup.number()
                      .typeError("That doesn't look like a phone number")
                      .positive("A phone number can't start with a minus")
                      .integer("A phone number can't include a decimal point")
                      .min(6),
                    coach_email: Yup.string()
                      .email("Invalid email address")
                      .required("Please fill email id"),
                    coach_password: Yup.string()
                      .min(
                        8,
                        "Password is too short - should be 8 chars minimum."
                      )
                      .required("Please fill password"),
                    confirmPassword: Yup.string()
                      .min(
                        8,
                        "Password is too short - should be 8 chars minimum."
                      )
                      .required("Please fill confirm password"),
                  })}
                  onSubmit={(values, { resetForm }) => {
                    values.cv = getCv;
                    setButton(false);
                    let formData = new FormData();
                    formData.append("coach_name", values.coach_name);
                    formData.append("coach_email", values.coach_email);
                    formData.append("phone", values.phone);
                    if (values?.coach_alternative_phone != "") {
                      formData.append(
                        "coach_alternative_phone",
                        values.coach_alternative_phone
                      );
                    }
                    formData.append("coach_password", values.coach_password);
                    formData.append("confirmPassword", values.confirmPassword);
                    formData.append("cv", values.cv);
                    formData.append("short_bio", values.short_bio);
                    coach_signup(formData)
                      .then((res) => {
                        Store.addNotification({
                          title: "Success",
                          message: res?.data?.message,
                          type: "success",
                          insert: "top",
                          container: "top-right",
                          animationIn: ["animate__animated", "animate__fadeIn"],
                          animationOut: [
                            "animate__animated",
                            "animate__fadeOut",
                          ],
                          dismiss: {
                            duration: 5000,
                            onScreen: true,
                          },
                        });
                        setButton(true);
                        resetForm({ values: "" });
                      })
                      .catch((err) => {
                        setButton(true);
                        if (err) {
                          Store.addNotification({
                            title: "Error!",
                            message: err?.response?.data?.message,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: [
                              "animate__animated",
                              "animate__fadeIn",
                            ],
                            animationOut: [
                              "animate__animated",
                              "animate__fadeOut",
                            ],
                            dismiss: {
                              duration: 5000,
                              onScreen: true,
                            },
                          });
                          setButton(true);
                        }
                      });
                  }}
                >
                  {(props) => (
                    <Form>
                      <div className="becFlex">
                        <div>
                          <div className="form-group">
                            <label>Full Name</label>
                            <MyTextInput type="text" name="coach_name" />
                          </div>
                        </div>
                      </div>
                      <div className="becFlex">
                        <div>
                          <div className="form-group">
                            <label>Email</label>
                            <MyTextInput type="email" name="coach_email" />
                          </div>
                        </div>
                        <div>
                          <div className="form-group">
                            <label>Phone. No.</label>
                            <MyTextInput type="text" name="phone" />
                          </div>
                        </div>
                      </div>
                      <div className="becFlex">
                        <div>
                          <div className="form-group">
                            <label>Alternative Phone Number (* Optional)</label>
                            <MyTextInput
                              type="text"
                              className="form-control"
                              name="coach_alternative_phone"
                            />
                          </div>
                        </div>
                        <div>
                          <div className="form-group">
                            <label className="form-control-label">Add CV</label>
                            <input
                              type="file"
                              accept="application/pdf,application/vnd.ms-excel"
                              className="form-control"
                              name="cv"
                              onChange={(e) => {
                                setCv(e.target.files[0]);
                              }}
                            />
                            {!getCv && props.errors.cv && props.touched.cv && (
                              <div style={{ color: "red" }}>
                                {props.errors.cv}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="becFlex">
                        <div>
                          <div className="form-group">
                            <label>Short Bio</label>
                            <MyTextArea
                              type="password"
                              className="form-control "
                              name="short_bio"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="becFlex">
                        <div>
                          <div className="form-group">
                            <label>Password</label>
                            <MyTextInput
                              type="password"
                              name="coach_password"
                            />
                          </div>
                        </div>
                        <div>
                          <div className="form-group">
                            <label>Confirm Password</label>
                            <MyTextInput
                              type="password"
                              name="confirmPassword"
                            />
                          </div>
                        </div>
                      </div>
                      {getButton === true ? (
                        <button
                          className="btn btn-primary btn-hover-heading-color cmn-btn"
                          type="submit" style={{borderRadius:"15px", marginLeft:"13em"}}
                        >
                          Submit
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary btn-hover-heading-color cmn-btn"
                          disabled
                        >
                          {" "}
                          wait please
                        </button>
                      )}
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ clear: "both" }}></div>
      <div className="container">
        <div className="here-become">
          <div className="here-grid">
            <div className="here-left">
              <h3>#Benefits</h3>
              <h4>The value we offer our Tutors!</h4>
            </div>
            <div className="here-middle"></div>

            <div className="here-right">
              <h2>Gain various benefits by joining the eThuta team.</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="howitwork container">
        <div className="howitwork-grid become-4Grid">
          <div className="howitwork-container">
            <div
              className="how it-work-section bc1 wow fadeInUp"
              data-wow-duration=".5s"
              data-wow-delay=".2s"
              style={{
                visibility: "visible",
                animationDuration: "0.5s",
                animationDelay: "0.2s",
              }}
            >
              <figure >
                <img src="images/leadership.svg" className="bc-images" />
              </figure>
              <h3>Team</h3>
              <p>
                A dedicated team to help tutors coordinate their activities with
                learners.{" "}
              </p>
            </div>
            <div
              className="how it-work-section bc2 wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay=".2s"
              style={{
                visibility: "visible",
                animationDuration: "1s",
                animationDelay: "0.2s",
              }}
            >
              <figure>
                <img src="images/empowerment.svg" className="bc-images" />
              </figure>
              <h3>Mentorship</h3>
              <p>
                In-house experienced teachers and tutors who provide support to
                new tutors.{" "}
              </p>
            </div>
            <div
              className="how it-work-section bc3 wow fadeInUp"
              data-wow-duration="1.5s"
              data-wow-delay=".2s"
              style={{
                visibility: "visible",
                animationDuration: "1.5s",
                animationDelay: "0.2s",
              }}
            >
              <figure >
                <img src="images/social.svg" className="bc-images" />
              </figure>
              <h3>Tools</h3>
              <p>Access to booking, claiming and learning tools. </p>
            </div>
            <div
              className="how it-work-section bc4 wow fadeInUp"
              data-wow-duration="2s"
              data-wow-delay=".2s"
              style={{
                visibility: "visible",
                animationDuration: "2s",
                animationDelay: "0.2s",
              }}
            >
              <figure >
                <img src="images/quality.svg" className="bc-images" />
              </figure>
              <h3>Training</h3>
              <p>
                Training is provided for tutoring delivery and online tools to
                engage students effectively.{" "}
              </p>
            </div>
            <div
              className="how it-work-section bc5 wow fadeInUp"
              data-wow-duration="2s"
              data-wow-delay=".2s"
              style={{
                visibility: "visible",
                animationDuration: "2s",
                animationDelay: "0.2s",
              }}
            >
              <figure >
                <img src="images/result.svg" className="bc-images" />
              </figure>
              <h3>Earnings</h3>
              <p>
                Potential to earn a consistent income from tutoring and video
                creation.{" "}
              </p>
            </div>
            <div
              className="how it-work-section bc6 wow fadeInUp"
              data-wow-duration="2s"
              data-wow-delay=".2s"
              style={{
                visibility: "visible",
                animationDuration: "2s",
                animationDelay: "0.2s",
              }}
            >
              <figure >
                <img src="images/quality1.svg" className="bc-images" />
              </figure>
              <h3>Feedback</h3>
              <p>Get continuous feedback to improve your tutoring skills. </p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className='howitwork container'>
        <h2>
          How it <span>Work </span>
        </h2>
        <div className='howitwork-grid'>
          <div className='howitwork-container'>
            <div
              className='how it-work-section wow fadeInUp'
              data-wow-duration='.5s'
              data-wow-delay='.2s'
              style={{
                visibility: 'visible',
                animationDuration: '0.5s',
                animationDelay: '0.2s',
              }}
            >
              <figure style={{ background: '#95d2e9' }}>
                <img src='images/plan.svg' />
              </figure>
              <h3>Set Your Plan</h3>
              <p>
                But also the leap into electronic typesetting, remaining
                essentially unchanged.{' '}
              </p>
            </div>
            <div
              className='how it-work-section wow fadeInUp'
              data-wow-duration='1s'
              data-wow-delay='.2s'
              style={{
                visibility: 'visible',
                animationDuration: '1s',
                animationDelay: '0.2s',
              }}
            >
              <figure style={{ background: '#bfb863' }}>
                <img src='images/coach1.svg' />
              </figure>
              <h3>Find Your Course</h3>
              <p>
                But also the leap into electronic typesetting, remaining
                essentially unchanged.{' '}
              </p>
            </div>
            <div
              className='how it-work-section wow fadeInUp'
              data-wow-duration='1.5s'
              data-wow-delay='.2s'
              style={{
                visibility: 'visible',
                animationDuration: '1.5s',
                animationDelay: '0.2s',
              }}
            >
              <figure style={{ background: '#c5c5fb' }}>
                <img src='images/coach2.svg' />
              </figure>
              <h3>Find Your Coach</h3>
              <p>
                But also the leap into electronic typesetting, remaining
                essentially unchanged.{' '}
              </p>
            </div>
            <div
              className='how it-work-section wow fadeInUp'
              data-wow-duration='2s'
              data-wow-delay='.2s'
              style={{
                visibility: 'visible',
                animationDuration: '2s',
                animationDelay: '0.2s',
              }}
            >
              <figure style={{ background: '#f5bbc5' }}>
                <img src='images/book1.svg' />
              </figure>
              <h3>Book Coach</h3>
              <p>
                But also the leap into electronic typesetting, remaining
                essentially unchanged.{' '}
              </p>
            </div>
          </div>
        </div>
      </div> */}

      <Footer />
      {/* <?php include "footer.php" ?> */}
    </>
  );
}
