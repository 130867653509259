import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getQna } from '../../services/web/webServices';

export default function Qna() {
    const params = useParams();
    const [getData, setData] = useState(null);
    useEffect(() => {
        getQna(params?.id).then((res) => {
            setData(res.data.response[0])
        }).catch((err) => {
            console.log(err);
        })
    }, [])
    return (
        <>
            <div className="tab-pane fade" id="monthly">
                <div className=" ">
                    <h3>Questions & Answer</h3>
                    <div
                        className="course-accordion accordion"
                        id="accordionCourse">
                        {getData?.qna?.length > 0 ? getData?.qna?.map((item,i) => {
                            return (
                                <>
                                    <div className="accordion-item">
                                        <button
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#collapse${i}`}
                                        >
                                            {item?.question}
                                        </button>
                                        <div
                                            id={`collapse${i}`}
                                            className="accordion-collapse collapse"
                                            data-bs-parent="#accordionCourse"
                                        >
                                            <div className="accordion-body">
                                                <p>
                                                    {item?.answer}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }) : "No Qna Found :("}
                    </div>
                    {/* <!-- Course Accordion End --> */}
                </div>
            </div>
        </>
    )
}
