import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Store } from "react-notifications-component";
import { MyTextInput } from "../services/web/inputServices";
import { login } from "../services/web/webServices";
export default function Login() {
  const navigate = useNavigate();
  const [getButton, setButton] = useState(true);
  const [getType, setType] = useState("password");
  const handle_password = () => {
    if (getType === "text") {
      setType("password");
    } else {
      setType("text");
    }
  };

  return (
    <>
      {/* <ReactNotifications /> */}
      <div className='  login-main-outer'>
        {/* <!-- Preloader Start  --> */}

        <div className='login-container'>
          <div className='log-s'>
            <div className='login-left'>
              <div className='comlog abs-login1'>
                <img src='images/q1.png' />
              </div>
              <div className='comlog  abs-login2'>
                <img src='images/q2.png' />
              </div>
              <div className='comlog  abs-login3'>
                <img src='images/q3.png' />
              </div>
              <div className='comlog  abs-login6'>
                <img src='images/q3.png' />
              </div>
              <div className='comlog  abs-login4'>
                <img src='images/q4.jpg' />
              </div>
              <div className='comlog  abs-login5'>
                <img src='images/q1.png' />
              </div>
              <h3>
                Engineering, Sales, ICT, and Self development / coaching
                expertise.
              </h3>
            </div>
            <div className='login-right  share-que'>
              <div className='login-logo '>
                <Link to='/'>
                  <img src='images/logo.webp' alt='' />
                </Link>
              </div>
              <div className='login-ins-sec'>
                <div className='section-title  '>
                  <h2 className='title' style={{ marginTop: "10px" }}>
                    Welcome Back !
                  </h2>
                </div>

                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validationSchema={Yup.object({
                    email: Yup.string()
                      .email("Invalid email address")
                      .required(),
                    password: Yup.string()
                      .min(
                        8,
                        "Password is too short - should be 8 chars minimum."
                      )
                      .required(),
                  })}
                  onSubmit={(values, { resetForm }) => {
                    setButton(false);

                    login(values)
                      .then((res) => {
                        setButton(true);
                        // console.log("Res=====>", res);
                        Store.addNotification({
                          title: "Success",
                          message: res?.data?.message,
                          type: "success",
                          insert: "top",
                          container: "top-right",
                          animationIn: ["animate__animated", "animate__fadeIn"],
                          animationOut: [
                            "animate__animated",
                            "animate__fadeOut",
                          ],
                          dismiss: {
                            duration: 5000,
                            onScreen: true,
                          },
                        });
                        resetForm({ values: "" });
                        localStorage.setItem(
                          "auth_token",
                          res?.data?.response?.access_token
                        );
                        localStorage.setItem("state", true);
                        navigate("/");
                      })
                      .catch((err) => {
                        setButton(true);
                        if (err) {
                          Store.addNotification({
                            title: "Error!",
                            message: err?.response?.data?.message,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: [
                              "animate__animated",
                              "animate__fadeIn",
                            ],
                            animationOut: [
                              "animate__animated",
                              "animate__fadeOut",
                            ],
                            dismiss: {
                              duration: 5000,
                              onScreen: true,
                            },
                          });
                        }
                      });
                  }}
                >
                  {(props) => (
                    <Form>
                      <div className='form-group'>
                        <label>Email </label>
                        <MyTextInput
                          className='form-group'
                          type='email'
                          name='email'
                          placeholder='Email *'
                        />
                      </div>
                      <div className='form-group'>
                        <label>Password</label>
                        {getType === "text" ? (
                          <span
                            className='fas fa-eye toggle-password'
                            onClick={handle_password}
                            style={{
                              cursor: "pointer",
                              position: "absolute",
                              left: "91%",
                              top: "40%",
                              zIndex: "999999",
                            }}
                          ></span>
                        ) : (
                          <i
                            className='fa fa-eye-slash'
                            aria-hidden='true'
                            onClick={handle_password}
                            style={{
                              cursor: "pointer",
                              position: "absolute",
                              left: "91%",
                              top: "40%",
                              zIndex: "999999",
                            }}
                          ></i>
                        )}
                        <input
                          className='form-group'
                          type={getType}
                          name='password'
                          placeholder='Password *'
                          onChange={(e) => {
                            props.values.password = e.target.value;
                          }}
                        />
                        {props.errors.password && props.touched.password && (
                          <div
                            style={{
                              color: "red",
                              position: "absolute",
                              fontSize: "12px",
                              bottom: "-17px",
                            }}
                          >
                            {props.errors.password}
                          </div>
                        )}
                      </div>

                      <Link to='/forgotpassword' className='forgot-pass'>
                        Forgot Password ?
                      </Link>
                      {getButton === true ? (
                        <button
                          className='btn btn-primary btn-hover-heading-color cmn-btn'
                          type='submit'
                        >
                          Login
                        </button>
                      ) : (
                        <button
                          className='btn btn-primary btn-hover-heading-color cmn-btn'
                          disabled
                        >
                          wait please!!
                        </button>
                      )}
                      <div className='tester_bug'>
                        Don't have an account?{" "}
                        <Link to='/signup' className='forgot-pass alr'>
                          Signup
                        </Link>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
