import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useLocation,useNavigate } from 'react-router-dom';
import Footer from '../Footer';
import InsideHeader from '../InsideHeader';
import ProfileSideMenu from '../ProfileSideMenu'

export default function ShowDocument() {
    const navigate=useNavigate();
    const { state } = useLocation();
    const { data } = state;


    const [getData, setData] = useState(null);
    const [lgShow, setLgShow] = useState(false);
    const css = `
    .document-grid.new-coach-listGrid {
        grid - template - columns: repeat(4, 1fr);
    }
    `
    return (
        <>
        <style>{css}</style>
            <InsideHeader />
            <div style={{ clear: "both" }}></div>
            <div className="container">
                <div className="profile-container">
                    <div className="profile-grid">
                        <ProfileSideMenu />
                        <div className="profile-grid-right wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
                            <div className="profile-heading-flex">
                                <h2>{data?.course_id?.course_title}</h2>
                                <a style={{cursor:"pointer"}} onClick={()=>navigate('/coach_reply')} >Go Back <i className="fa fa-reply" aria-hidden="true" style={{color:"#2e80e0"}}></i></a>
                              
                            </div>

                            <div className="document-grid new-coach-listGrid">
                                {data?.query_file?.query_url?.length > 0 ? data?.query_file?.query_url?.map((item, index) => {
                                    return (
                                        <>
                                            <div className="document-section pdf" key={index}>

                                                {item?.split('.')?.pop() === "pdf" ?<img src="images/pdf.png"/>:<embed src={item} width="100%" height="90px"/>}

                                                <h4>{data?.query_file?.query_name[index]?.split('.')?.slice(0, -1)?.join('.')}</h4>
                                                    

                                                {item?.split('.')?.pop() === "pdf" ? <a style={{ color: "blue" }} onClick={() => {
                                                    setData(item);
                                                    setLgShow(true)
                                                }}>click to see</a>: <a style={{ color: "blue" }} onClick={() => {
                                                    setData(item);
                                                    setLgShow(true)
                                                }}>Show Image</a>}


                                               
                                            </div>
                                        </>
                                    )
                                }) : "No Reply Found"}


                            </div>
                        </div>

                    </div>
                </div>
            </div>




            <Footer />


            {/* Modal */}
            <Modal
                size="lg"
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Query
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        {getData?.split('.')?.pop() === "pdf"?<embed src={getData} width="100%" height="600px" />:<embed src={getData} width="100%" height="400px" />}
                        
                    </>
                </Modal.Body>
            </Modal>
        </>
    )
}
