import AllRoute from '../src/routes/AllRoute';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { useEffect } from 'react';
import ScrollToTop from './scroll';

function App() {
  
  useEffect(() => {
      
    const script = document.createElement('script');
  

      script.src = "js/wow.js";
      script.async = true;
  
      document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    }
  }, []);
  return (
      <>
       <Router basename='/'>
        <ScrollToTop />
        <ReactNotifications />
          <AllRoute/>
        </Router>
       
      </>
  );
}

export default App;
