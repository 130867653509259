import axios from "axios";
import base_uri from "../../api/base_url";

export const login = (data) => {
  return axios({
    method: "post",
    url: `${base_uri.base_uri}/user/login`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  });
};
export const signup = (data) => {
  return axios({
    method: "post",
    url: `${base_uri.base_uri}/user/signup`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  });
};

export const forgot = (data) => {
  return axios({
    method: "post",
    url: `${base_uri.base_uri}/user/forgot_password`,
    data: data,
  });
};

export const reset_password = (values, token, type) => {
  return axios({
    method: "post",
    url: `${base_uri.base_uri}/user/reset_password?token=${token}&type=${type}`,
    data: values,
  });
};

export const emailVerification = (type, token) => {
  return axios({
    method: "get",
    url: `${base_uri.base_uri}/email/u/verification?token=${token}&type=${type}`,
  });
};

export const forgotpasswordmove = (type, token) => {
  return axios({
    method: "get",
    url: `${base_uri.base_uri}/email/u1/verification?token=${token}&type=${type}`,
  });
};
export const profile = () => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "get",
    url: `${base_uri.base_uri}/user/profile`,
    headers: { Authorization: `Bearer ${token}` },
  });
};
export const editprofile = (data) => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "put",
    url: `${base_uri.base_uri}/user/editprofile`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};
export const editpassword = (values) => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "put",
    url: `${base_uri.base_uri}/user/editpassword`,
    data: values,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
export const contactUs = (values) => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "post",
    url: `${base_uri.base_uri}/user/contactus`,
    data: values,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
export const getCourses = () => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "get",
    url: `${base_uri.base_uri}/get-all-course`,
    headers: { Authorization: `Bearer ${token}` },
  });
};
export const getSingleCourse = (id) => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "get",
    url: `${base_uri.base_uri}/get-single-course/${id}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};
export const updateSingleCourse = (id) => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "put",
    url: `${base_uri.base_uri}/update-single-course/${id}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
export const bookCourse = (data) => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "post",
    url: `${base_uri.base_uri}/user/book-course`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const rescheduleBookedSession = (data) => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "post",
    url: `${base_uri.base_uri}/user/reschedule-coach-session`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};
export const checkRescheduleSession = (data) => {
  console.log(data);
  const token = localStorage.getItem("auth_token");
  const params = new URLSearchParams(data).toString();
  return axios({
    method: "get",
    url: `${base_uri.base_uri}/user/get-rescheduled-slot?${params}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const getEnrolledCourse = () => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "get",
    url: `${base_uri.base_uri}/get-all-my-enrolled-courses`,
    headers: { Authorization: `Bearer ${token}` },
  });
};
export const addFav = (data) => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "post",
    url: `${base_uri.base_uri}/user/add-favourite-course`,
    headers: { Authorization: `Bearer ${token}` },
    data,
  });
};
export const removeFav = (id) => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "put",
    url: `${base_uri.base_uri}/user/remove-favourite-course/${id}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};
export const getAllFav = () => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "get",
    url: `${base_uri.base_uri}/user/get-all-favourite-course`,
    headers: { Authorization: `Bearer ${token}` },
  });
};
export const getSingleCoach = (id) => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "get",
    url: `${base_uri.base_uri}/get-single-coach/${id}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};
export const getSingleCourseForCoach = (id, course_id) => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "get",
    url: `${base_uri.base_uri}/get-single-course-for-coach/${id}/${course_id}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};
export const needHelp = (data) => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "post",
    url: `${base_uri.base_uri}/user/need-help`,
    headers: { Authorization: `Bearer ${token}` },
    data,
  });
};
export const comment = (data) => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "post",
    url: `${base_uri.base_uri}/user/comment`,
    headers: { Authorization: `Bearer ${token}` },
    data,
  });
};

export const getCommentById = (id) => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "get",
    url: `${base_uri.base_uri}/user/get-comment-by-id/${id}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};
export const bookCoach = (data) => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "post",
    url: `${base_uri.base_uri}/user/book-coach`,
    headers: { Authorization: `Bearer ${token}` },
    data,
  });
};

export const bookCoachForMe = (values) => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "post",
    url: `${base_uri.base_uri}/user/course-enquiry`,
    data: values,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const bookCoachForOnline = (id, data) => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "post",
    url: `${base_uri.base_uri}/user/book-coach-for-online/${id}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};
export const bookCoachForOffline = (id, data) => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "post",
    url: `${base_uri.base_uri}/user/book-coach-for-offline/${id}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const getAllCoach = () => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "get",
    url: `${base_uri.base_uri}/get-all-coach`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const profileNeedHelp = (data) => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "post",
    url: `${base_uri.base_uri}/user/admin-need-help`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const addGrade = (data) => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "post",
    url: `${base_uri.base_uri}/user/add-grade`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const getAllGrade = () => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "get",
    url: `${base_uri.base_uri}/user/get-all-grade`,
    headers: { Authorization: `Bearer ${token}` },
  });
};
export const delete_grade = (id) => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "delete",
    url: `${base_uri.base_uri}/user/delete_grade/${id}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

// Coach Signup
export const coach_signup = (data) => {
  return axios({
    method: "post",
    url: `${base_uri.base_uri_coach}/signup`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
};
export const coach_email_verification = (type, token) => {
  return axios({
    method: "get",
    url: `${base_uri.base_uri}/email/u/verification?token=${token}&type=${type}`,
  });
};

// Add Query for Coach
export const addQueryForCoach = (data) => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "post",
    url: `${base_uri.base_uri}/query-for-coach`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
    data,
  });
};

// ends
// get Document from Coach
export const getDocument = (id) => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "get",
    url: `${base_uri.base_uri}/user/get-document${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// ends

// getQna
export const getQna = (id) => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "get",
    url: `${base_uri.base_uri}/user/get-course-qna/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// ends

// getCoachReply
export const getReply = () => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "get",
    url: `${base_uri.base_uri}/user/get-reply`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// ends

// Course Mcq
export const getMcq = (course_id, video_id) => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "get",
    url: `${base_uri.base_uri}/user/get-course-mcq/${course_id}/${video_id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const getMcqInfo = (course_id) => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "get",
    url: `${base_uri.base_uri}/user/get-course-mcq-info/${course_id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Ends

// DashBoard
export const getDashboard = () => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "get",
    url: `${base_uri.base_uri}/user/get_dashboard`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const getMostViewed = () => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "get",
    url: `${base_uri.base_uri}/user/get-most-view`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
// ends

// Admin Side course And Category data
export const getAdminSideCourseAndCategory = () => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "get",
    url: `${base_uri.base_uri}/user/get-course-and-category`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
// Ends

// Coach Time Slot

export const getCoachTimeSlot = (id, course_id, class_type) => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "get",
    url: `${base_uri.base_uri}/get-single-coach-timeslot/${id}/${course_id}/${class_type}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getCoachTimeSlotv2 = (id) => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "get",
    url: `${base_uri.base_uri}/get-single-coach-timeslot/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
// Ends
// Coach Time Slot Notification

export const getInviteLink = () => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "get",
    url: `${base_uri.base_uri}/user/get-invite-link`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const getAcceptedRequest = () => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "get",
    url: `${base_uri.base_uri}/user/get-accepted-request`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const getInviteLinkCheck = () => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "get",
    url: `${base_uri.base_uri}/user/get-invite-link-check`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
// Ends
// Get popular Course
export const getPopularCourse = () => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "get",
    url: `${base_uri.base_uri}/get-popular-course`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
// Ends

// Searching
export const seaarch_detail = (values) => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "get",
    url: `${base_uri.base_uri}/user/search-detail?textSearch=${values}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
// Ends

export const filtered_language = () => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "get",
    url: `${base_uri.base_uri}/user/get-fltered-language`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const get_filter_data = (data) => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "post",
    url: `${base_uri.base_uri}/user/get-filter-data`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};
export const get_course_comment_reply = () => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "get",
    url: `${base_uri.base_uri}/get_course_comment_reply`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const get_course_comment_saw = () => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "get",
    url: `${base_uri.base_uri}/get_course_comment_saw`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const get_all_student_saw_comment = () => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "get",
    url: `${base_uri.base_uri}/get_all_student_saw_comment`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const user_payment = () => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "get",
    url: `${base_uri.base_uri}/fast-pay-token`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const get_all_about_user = () => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "get",
    url: `${base_uri.base_uri}/get_all_about_user`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Filter For Coach
export const filter_for_coach = (level, language, id) => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "get",
    url: `${base_uri.base_uri}/user/filter_for_coach/${id}?level=${level}&language=${language}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
// Ends
//Push Notification
export const get_all_push_notification = () => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "get",
    url: `${base_uri.base_uri}/get_all_push_notification`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const saw_all_push_notification = () => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "put",
    url: `${base_uri.base_uri}/saw_all_push_notification`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const get_all_saw_push_notification = () => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "get",
    url: `${base_uri.base_uri}/get_all_saw_push_notification`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
// Ends

// Rating
export const addRating = (data) => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "post",
    url: `${base_uri.base_uri}/add_rating`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data,
  });
};
export const getRating = (data) => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "post",
    url: `${base_uri.base_uri}/get_rating`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data,
  });
};
// Ends
// course Video
export const get_all_courses_video = () => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "get",
    url: `${base_uri.base_uri}/get_all_courses_video`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
// Ends

export const coach_forgot_email_verification = (type, token) => {
  return axios({
    method: "get",
    url: `${base_uri.base_uri_coach}/email/u/forgot/verification?token=${token}&type=${type}`,
  });
};

// Resource
export const get_resource = (courseId, videoId) => {
  const token = localStorage.getItem("auth_token");
  return axios({
    method: "get",
    url: `${base_uri.base_uri}/get_resource/${courseId}/${videoId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
// Ends
