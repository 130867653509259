import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ScrollToTop from '../../scroll';

export default function Document(props) {
    const [modalShow, setModalShow] = React.useState(false);
    const [getData, setData] = useState(null);
    function MyVerticallyCenteredModal(props) {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Document
                    </Modal.Title>
                </Modal.Header> 
                <Modal.Body>
                    <>
                        <ScrollToTop />
                        <embed src={getData} width="100%" height="600px" />
                    </>
                </Modal.Body>
            </Modal>
        );
    }
    return (
        <>
            <div className="tab-pane fade" id="document" style={{marginBottom:"40px"}}>
                <h3>Questions & Answer</h3>

                <div className="document-grid">
                    {props?.data?.documents?.length > 0 ? props?.data?.documents.map((item, index) => {
                        return (
                            <>
                                <div className="document-section pdf">
                                    <img src="/images/pdf.png" />
                                    <h4>{item?.document_name?.split('.').slice(0, -1).join('.')}</h4>
                                    <a style={{color:"blue"}} onClick={() => {
                                        setData(item.document_url)
                                        setModalShow(true);
                                    }
                                    }>Download</a>
                                </div>
                            </>
                        )
                    }) : "No Document Found :( "}
                </div>

            </div>

            {/* Modal Vertical */}
            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
            {/* ends */}
        </>
    )
}
