import React, { useState, useEffect } from 'react'
import Footer from './Footer'
import { useNavigate } from 'react-router'
import InsideHeader from './InsideHeader'
import ProfileSideMenu from './ProfileSideMenu'
import { Store } from 'react-notifications-component';
import { profile } from '../services/web/webServices';
import { Formik, Form } from 'formik'
import * as Yup from 'yup';
import { MyTextInput } from '../services/web/inputServices'
import { editpassword } from '../services/web/webServices'
export default function ChangePassword() {
    const [getButton, setButton] = useState(true);
    const navigate = useNavigate()

    const [userDetails, setUserDetails] = useState({});

    useEffect(() => {
        //    start
        profile()
            .then((res) => {
                setUserDetails(res.data.response)
            })
            .catch((err) => {

               console.log(err);
            });

    }, [])
    return (
        <>
            <InsideHeader />
            <div className="container pt-120">
                <div className="profile-container">
                    <div className="profile-grid">
                        {/* <?php include "profile-side-menu.php" ?> */}
                        <ProfileSideMenu />
                        <div className="profile-grid-right">
                            <h2>Edit Profile</h2>



                            <Formik
                                initialValues={{
                                    oldPassword: "",
                                    newPassword: "",
                                    confirmPassword: "",
                                }}
                                validationSchema={Yup.object({
                                    oldPassword: Yup.string().required(),
                                    newPassword: Yup.string().min(
                                        8,
                                        "Password is too short - should be 8 chars minimum."
                                    ).required(),
                                    confirmPassword: Yup.string().min(
                                        8,
                                        "Password is too short - should be 8 chars minimum."
                                    ).required()
                                       
                                })}
                                onSubmit={(values, { resetForm }) => {
                                    setButton(false);
                                    console.log(values)
                                    editpassword(values)
                                        .then((res) => {
                                            setButton(true);
                                            Store.addNotification({
                                                title: "Success",
                                                message: res?.data?.message,
                                                type: "success",
                                                insert: "top",
                                                container: "top-right",
                                                animationIn: ["animate__animated", "animate__fadeIn"],
                                                animationOut: [
                                                    "animate__animated",
                                                    "animate__fadeOut",
                                                ],
                                                dismiss: {
                                                    duration: 5000,
                                                    onScreen: true,
                                                },
                                            });
                                            resetForm({ values: "" });
                                            // localStorage.removeItem('auth_token');
                                            // localStorage.removeItem('name');
                                            // localStorage.removeItem('state');

                                            // navigate("/");
                                        })
                                        .catch((err) => {
                                            setButton(true);
                                            if (err) {
                                                Store.addNotification({
                                                    title: "Error!",
                                                    message: err?.response?.data?.message,
                                                    type: "danger",
                                                    insert: "top",
                                                    container: "top-right",
                                                    animationIn: ["animate__animated", "animate__fadeIn"],
                                                    animationOut: ["animate__animated", "animate__fadeOut"],
                                                    dismiss: {
                                                        duration: 5000,
                                                        onScreen: true,
                                                    },
                                                });

                                            }
                                           console.log(err);
                                        });
                                }}
                            >

                                <Form className="share-que">
                                    <div>
                                        <div className="form-group">
                                            <label>Old Password</label>
                                            <MyTextInput
                                                className="form-group"
                                                type="password"
                                                name="oldPassword"
                                                placeholder="Old Password *"
                                               
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="form-group">
                                            <label>New Password</label>
                                            <MyTextInput
                                                className="form-group"
                                                type="password"
                                                name="newPassword"
                                                placeholder="New Password *"
                                                
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="form-group">
                                            <label>Confirm Password</label>
                                            <MyTextInput
                                                className="form-group"
                                                type="password"
                                                name="confirmPassword"
                                                placeholder="Confirm Password *"
                                               
                                            />
                                        </div>
                                    </div>
                                    {getButton===true? <button className="btn btn-primary btn-hover-heading-color cmn-btn" type='submit'>Update Password</button>:<button className="btn btn-primary btn-hover-heading-color cmn-btn" disabled>wait please!!</button>}
                                </Form>
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}
