import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { Link } from "react-router-dom";
import ProfileSideMenu from "./ProfileSideMenu";
import { getEnrolledCourse } from "../services/web/webServices";
import { useNavigate } from "react-router";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { Loader } from "../helper/Loader";
import LinearProgress from "@mui/material/LinearProgress";

const css = `
.css-eglki6-MuiLinearProgress-root{
  width:25% !important;
  
  .profile-list li:nth-child(2) a {
    color: #0086db !important;
  }
}`;
export default function MyCourse() {
  const navigate = useNavigate();
  const [getDetail1, setDetail1] = useState([]);
  const [getLoader, setLoader] = useState(true);
  const CoachProfile = (k) => {
    navigate(`/coachprofile/${getDetail1[k].course_id.coach._id}`);
  };
  const CoachName = (k) => {
    navigate(`/coursedetail/${getDetail1[k].course_id._id}`);
  };

  useEffect(() => {
    getEnrolledCourse()
      .then((res) => {
        setDetail1(
          res.data.response.map((el, index) => ({
            ...el,
            id: el._id,
            i: index,
          }))
        );
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  }, []);

  const columns = [
    /* {
            field: "coach_image",
            headerName: "Image",
            width: 80,
            renderCell: (params) => {
                return (
                    <div>
                        {params?.row?.course_id?.coach.coach_image == " " ? (
                            <img
                                className="circular_image"
                                style={{ width: "62px" }}
                                src="images/profile.png"
                                alt="Not Found "
                            />
                        ) : (
                            <img
                                className="circular_image"

                                style={{ width: "62px", borderRadius: "47%" }}
                                src={params?.row?.course_id?.coach.coach_image}
                                alt=""
                            />
                        )}
                    </div>
                );
            },
        },*/
    {
      field: "coach_name",
      headerName: "Coach Name",
      width: 160,
      valueGetter: (index) => index.row?.course_id?.coach.coach_name,
    },
    {
      field: "#2",
      headerName: "Course Name",
      width: 250,
      valueGetter: (index) => index?.row?.course_id?.course_title,
    },
    {
      field: "#3",
      headerName: "Book Date",
      width: 150,
      valueGetter: (index) => index?.row?.created_at?.slice(0, 10),
    },
    // {
    //     field: "#4",
    //     headerName: "Total Payment",
    //     width: 150,
    //     valueGetter: (i) => `${i?.row?.course_id?.currency_type} ${i?.row?.course_id?.price}`
    // },
    {
      field: "#5",
      headerName: "Course",
      width: 150,

      renderCell: (index) => {
        return (
          <>
            <div className="start-course">
              <Link to={`/startcourse/${index?.row?.course_id?._id}`}>
                Start Course
              </Link>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <style>{css}</style>
      <Header />
      <div
        className="section page-banner-section bg-color-1"
        style={{ marginTop: "29px" }}
      >
        <div className="container">
          {/* <!-- Page Banner Content Start --> */}
          <div className="page-banner-content">
            <h2 className="title">My Course</h2>
            <ul className="breadcrumb justify-content-center">
              <li className="breadcrumb-item">
                <a href="">Home</a>
              </li>
              <li className="breadcrumb-item active">My Course</li>
            </ul>
          </div>
          {/* <!-- Page Banner Content End --> */}
        </div>
      </div>

      <div style={{ clear: "both" }}></div>
      <div className="container  ">
        <div className="profile-container">
          <div className="profile-grid">
            {/* <?php include "profile-side-menu.php" ?> */}
            <ProfileSideMenu />
            <div
              className="profile-grid-right wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay=".2s"
              style={{
                visibility: "visible",
                animationDuration: "1s",
                animationDelay: "0.4s",
              }}
            >
              <div className="profile-heading-flex">
                <h2>My Course</h2>
              </div>
              {getLoader === true ? (
                <Box sx={{ width: "100%" }}>
                  <LinearProgress />
                </Box>
              ) : (
                <Box sx={{ height: 400, width: "100%" }}>
                  {getDetail1.length > 0 ? (
                    <DataGrid
                      rows={getDetail1}
                      columns={columns}
                      pageSize={5}
                      rowsPerPageOptions={[5]}
                    />
                  ) : (
                    "No Course Found!!!"
                  )}
                </Box>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
