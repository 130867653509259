import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  getInviteLink,
  seaarch_detail,
  profile,
  get_all_push_notification,
} from "../services/web/webServices";
import Box from "@mui/material/Box";
import { Store } from "react-notifications-component";
import LinearProgress from "@mui/material/LinearProgress";
import { Modal } from "react-bootstrap";
import base_uri from "../api/base_url";
import { NavLink } from "react-router-dom";
const css = `

.modal-content {
    border: none;
}

.modal-body.grade-pop.coach-profile-need {
    padding: 0;
}
.login-container-new-grid.login3svg {grid-template-columns: repeat(3, 1fr);gap: 10px;}

.login-container-new-grid.login3svg img {
    height: 100px;
}
`;
export default function InsideHeader() {
  const state = localStorage.getItem("state");
  const [getSearch, setSearch] = useState(null);
  const [getLoader, setLoader] = useState(false);
  const [getValue, setValue] = useState(null);
  const navigate = useNavigate();
  const [getDetail, setDetail] = useState(null);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const [getData, setData] = useState([]);

  const handleClose = () => {
    setShow(false);
  };
  const handleClose1 = () => {
    setShow1(false);
  };

  useEffect(() => {
    profile()
      .then((res) => {
        setDetail(res.data.response);
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });
  }, []);

  const getApi = () => {
    if (localStorage.getItem("state")) {
      get_all_push_notification()
        .then((res) => {
          let data = res?.data?.response;
          for (let i of data) {
            // if (i?.student_saw == false) {
            setData((getData) => [...getData, i]);
            // }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <style>{css}</style>
      <div className="main-wrapper">
        <div id="header" className="header section inside-header">
          <div className="container">
            {/* <!-- Header Wrapper Start  --> */}
            <div className="header-wrapper">
              {/* <!-- Header Logo Start --> */}
              <div className="header-logo">
                <Link to="/">
                  <img src="images/logo.webp" alt="" />
                </Link>
              </div>
              {/* <!-- Header Logo End --> */}
              {/* <!-- Header Menu Start --> */}
              <div className="header-menu d-none d-lg-block">
                <ul className="main-menu">
                  <li>
                    {/* <Link className="active" to="/"> */}
                    <Link to="/">Home</Link>
                  </li>

                  <li>
                    <Link to="/about">Services</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>

                  {localStorage?.getItem("auth_token") ? (
                    ""
                  ) : (
                    <li>
                      <NavLink to="/becomecoach" activeClassName="active">
                        Become a Coach
                      </NavLink>
                    </li>
                  )}

                  {state ? (
                    <>
                      <li>
                        <NavLink to="/mycourse" activeClassName="active">
                          My Course
                        </NavLink>
                        {/* <Link to="/mycourse" className="active">My Course</Link> */}
                      </li>
                      <li>
                        <NavLink to="/multicoach" activeClassName="active">
                          Book a Coach
                        </NavLink>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                </ul>
              </div>
              {/* <!-- Header Menu End --> */}
              {/* <!-- Header Meta Start --> */}
              <div className="header-meta">
                <div className="header-search d-none d-lg-block">
                  <form>
                    <input
                      type="text"
                      placeholder="Search Courses"
                      value={
                        localStorage?.getItem("Click") == "1" ? "" : getValue
                      }
                      onChange={(e) => {
                        localStorage.setItem("Click", "0");
                        setLoader(true);
                        setValue(e.target.value);
                        seaarch_detail(e.target.value)
                          .then((res) => {
                            setSearch(res.data.response);
                            setLoader(false);
                          })
                          .catch((err) => {
                            console.log(err);
                          });
                      }}
                    />
                    <a>
                      <i className="flaticon-loupe"></i>
                    </a>
                  </form>
                </div>

                {!state ? (
                  <div className="header-login d-none d-lg-flex">
                    <a className="link" onClick={() => setShow(true)}>
                      Login
                    </a>
                    {/* <Link className="link" to="/login"><i className="fa fa-user-o"></i> Login</Link> */}
                    <a className="link" onClick={() => setShow1(true)}>
                      Register
                    </a>
                  </div>
                ) : (
                  <>
                    <a
                      onClick={() => {
                        getApi();
                        navigate("/notification");
                      }}
                    >
                      <i
                        className="fas fa-bell"
                        style={{ cursor: "pointer" }}
                      ></i>
                    </a>
                    <Link to="/profile">
                      <i
                        className="fa fa-user"
                        aria-hidden="true"
                        style={{ marginLeft: "40px" }}
                      ></i>
                    </Link>
                    <a
                      style={{ marginLeft: "5px" }}
                      onClick={() => navigate("/profile")}
                    >
                      {getDetail?.name}
                    </a>
                    {!localStorage.getItem("message") ? (
                      ""
                    ) : (
                      <div className="chat-noti-wrap">
                        <span>
                          {" "}
                          {localStorage.getItem("message") > 100
                            ? "99+"
                            : localStorage.getItem("message")}
                        </span>
                      </div>
                    )}

                    {/* <p style={{
                    color: "blue", marginLeft: '20px',
                    position: "absolute",
                    left: "110%", cursor: "pointer"
                  }} onClick={() => navigate('/checkout')}><i className="fas fa-shopping-cart"></i> Cart</p> */}
                  </>
                )}

                <div className="header-toggle d-lg-none">
                  <button
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasMenu"
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>
                </div>

                {getValue ? (
                  <>
                    {getSearch?.length > 0 &&
                    localStorage.getItem("Click") != 1 ? (
                      <ul className="search-Dropdown">
                        {getLoader == true ? (
                          <Box sx={{ width: "100%", height: "20px" }}>
                            <LinearProgress />
                          </Box>
                        ) : (
                          <>
                            {getSearch?.length > 0
                              ? getSearch.map((item, index) => {
                                  return (
                                    <>
                                      <li>
                                        <a
                                          onClick={() => {
                                            navigate("/search_filter", {
                                              state: { search_data: getSearch },
                                            });
                                            localStorage.setItem("Click", "1");
                                            setValue(null);
                                            setSearch(null);
                                          }}
                                        >
                                          Coach Name :{" "}
                                          {item?.coach[0]?.coach_name}
                                          <br></br>
                                          <span>
                                            Course Name : {item?.course_title}
                                          </span>
                                        </a>
                                      </li>
                                    </>
                                  );
                                })
                              : ""}
                          </>
                        )}
                      </ul>
                    ) : (
                      ""
                    )}

                    {localStorage.getItem("Click") == 0 &&
                    getValue &&
                    !getSearch?.length ? (
                      <>
                        <ul className="search-Dropdown">
                          <li>
                            <a>No result found.</a>
                          </li>
                        </ul>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
              {/* <!-- Header Meta End --> */}
            </div>
            {/* <!-- Header Wrapper End --> */}
          </div>
        </div>
        {/* <!-- Header End --> */}
      </div>

      {/* Login */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="modal-content">
            {/* <!-- Modal Header --> */}

            {/* <!-- Modal body --> */}
            <div class="modal-body grade-pop coach-profile-need">
              <div class="login-right  share-que">
                <div class="login-container-new">
                  <div class="login-container-new-grid login3svg">
                    <div class="login-new-content">
                      <a onClick={() => navigate("/login")}>
                        <figure>
                          <img src="images/learne-new.svg" />
                        </figure>
                        <h2>Login as Learner</h2>
                      </a>
                    </div>
                    <div class="login-new-content">
                      <a
                        onClick={() =>
                          (window.location.href = `${base_uri.coach_base_url}`)
                        }
                      >
                        <figure>
                          <img src="images/coach-new.svg" />
                        </figure>
                        <h2>Login as Coach</h2>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Ends */}

      {/* Signup */}
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header>
          <Modal.Title>Sigup</Modal.Title>
          <i
            className="fas fa-cut"
            style={{ cursor: "pointer" }}
            onClick={handleClose1}
          ></i>
        </Modal.Header>
        <Modal.Body>
          <>
            <div class="modal-content">
              {/* <!-- Modal Header --> */}

              {/* <!-- Modal body --> */}
              <div class="modal-body grade-pop coach-profile-need">
                <div class="login-right  share-que">
                  <div class="login-container-new">
                    <div class="login-container-new-grid">
                      <a onClick={() => navigate("/signup")}>
                        <div class="login-new-content">
                          <figure>
                            <img src="images/learne-new.svg" />
                          </figure>
                          <h2>Sign Up as Learner</h2>
                        </div>
                      </a>
                      <div class="login-new-content">
                        <a
                          onClick={() =>
                            (window.location.href = `${base_uri.coach_base_url}signup`)
                          }
                        >
                          <figure>
                            <img src="images/coach-new.svg" />
                          </figure>
                          <h2>Sign Up as Coach</h2>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
      {/* Ends */}
    </>
  );
}
