import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Store } from "react-notifications-component";
import {
  getSingleCoach,
  needHelp,
  profile,
  filter_for_coach,
  getCourses,
} from "../services/web/webServices";
import {
  MySelect,
  MyTextArea,
  MyTextInput,
} from "../services/web/inputServices";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Backdrop from "@mui/material/Backdrop";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Scroll from "../scroll";
import base_uri from "../api/base_url";

export default function CoachProfile() {
  const param = useParams();
  const [getLoader, setLoader] = useState(true);
  const [button, setButton] = useState(true);
  const [getEmail, setEmail] = useState("");
  const [getCoach1, setCoach1] = useState({});
  const [getGrade1, setGrade1] = useState([]);
  const [getCourse1, setCourse1] = useState([]);
  const [getCourse, setCourse] = useState(new Set([]));
  const [userDetails, setUserDetails] = useState([]);
  const [getGrade, setGrade] = useState([]);
  const [getSubject, setSubject] = useState([]);
  const [getService, setService] = useState([]);
  const [getSubject1, setSubject1] = useState([]);

  const [getCourseId, setCourseId] = useState(null);

  const [getAllPopularCourse, setAllPopularCourse] = useState([]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [getSearchDetail, setSearchDetail] = useState(null);

  const [getLevel, setLevel] = useState([
    "Foundation",
    "Intermediate",
    "Senior Phase",
    "Fet Phase",
    "University",
  ]);

  const [getLaguage, setLanguage] = useState(null);
  const [level, setlevel] = useState(null);

  const navigate = useNavigate();
  useEffect(() => {
    profile()
      .then((res) => {
        if (res.data.response.father_email == "") {
          setEmail(res.data.response?.email);
        }
        if (res.data.response.email == "") {
          setEmail(res?.data?.response?.father_email);
        }
        setUserDetails(res?.data?.response);
        if (res.data.response.status == 0) {
          Store.addNotification({
            title: "Error!",
            message: res?.data?.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
          localStorage.removeItem("auth_token");
          localStorage.removeItem("state");
          navigate("/login");
        }
      })
      .catch((err) => {
        if (err) {
          // console.log(err);
        }
      });

    getSingleCoach(param.id)
      .then((res) => {
        console.log("Coach prod=====>", res.data.response);
        setLoader(false);
        setCoach1(res.data.response);
        setCourse1(res.data.response?.myCourse);
        let lng = new Set([]);
        for (let l of res.data.response?.myCourse
          .map((v) => v?.language)
          .filter((v) => v != "")) {
          lng.add(l);
        }
        setCourse(lng);
        setSubject(res.data.response.subject_of_teacher);
        setGrade(res.data.response.grade);
        setService(res.data.response.service_offered);
      })
      .catch((err) => {
        setLoader(false);
        // console.log(err);
      });

    getCourses()
      .then((res) => {
        setAllPopularCourse(res.data.response);
        console.log("------getpopular course", res.data.response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // console.log(getCourse)

  return (
    <>
      <Header />
      <div
        className="section page-banner-section bg-color-1"
        style={{ marginTop: "1%" }}
      >
        <div className="container">
          {/* <!-- Page Banner Content Start --> */}
          <div className="page-banner-content">
            <h2 className="title">Coach Profile</h2>
            <ul className="breadcrumb justify-content-center">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active">
                <Link to="/search">Coach Profile</Link>
              </li>
            </ul>
          </div>
          {/* <!-- Page Banner Content End --> */}
        </div>
      </div>
      <div style={{ clear: "both" }}></div>
      {getLoader === true ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={getLoader}
        >
          <Spinner animation="grow" />
        </Backdrop>
      ) : (
        <>
          <div className="container">
            <div className="coach-profile-section">
              <div
                className="coach-profile-left wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
                style={{
                  visibility: "visible",
                  animationDuration: "1s",
                  animationDelay: "0.4s",
                }}
              >
                <img src={getCoach1.coach_image} />
                <div className="dots-bg1   "></div>
              </div>
              <div className="coach-profile-left">
                <div className="coach-name-inside">
                  <div>
                    <h2
                      className="wow fadeInUp"
                      data-wow-duration="1s"
                      data-wow-delay=".2s"
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.4s",
                      }}
                    >
                      {getCoach1.coach_name}
                    </h2>
                    <div className="social-icons">
                      <ul
                        className="ts-social wow fadeInUp"
                        data-wow-duration="1.3s"
                        data-wow-delay=".2s"
                        style={{
                          visibility: "visible",
                          animationDuration: "1s",
                          animationDelay: "0.4s",
                        }}
                      >
                        <li>
                          <a href="http://facebook.com">
                            {" "}
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="http://twitter.com" target="_blank">
                            {" "}
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="http://linkdin.com">
                            {" "}
                            <i className="fab fa-linkedin"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="book-need">
                    <a
                      onClick={() => {
                        navigate(`/bookcoach/${param.id}/bookingcoach`);
                      }}
                      className="global-button wow fadeInUp"
                      data-wow-duration="1s"
                      data-wow-delay=".3s"
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.3s",
                      }}
                    >
                      Book Coach
                    </a>

                    <a
                      href=""
                      className="global-button wow fadeInUp NeedHelp"
                      data-wow-duration="1s"
                      data-wow-delay=".3s"
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.3s",
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#myModal"
                    >
                      Need Help
                    </a>

                    {/* to={`/bookcoach/${param.id}`} */}
                  </div>
                </div>
                <div className="coach-grade2">
                  <div className="coach-grade-section">
                    <h3>Level</h3>
                    <select
                      onChange={(e) => {
                        setlevel(e.target.value);

                        if (getLaguage) {
                          filter_for_coach(
                            e.target.value,
                            getLaguage,
                            getCoach1?._id
                          ).then((res) => {
                            setSearchDetail(res.data.response);
                            if (res.data.response == "") {
                              alert("No course found :(");
                            }
                          });
                        }
                      }}
                    >
                      <option>Choose level</option>
                      {getLevel?.length > 0
                        ? getLevel.map((item, i) => {
                            return (
                              <option key={i} value={item?.language}>
                                {item}
                              </option>
                            );
                          })
                        : "Not Found"}
                    </select>
                  </div>
                  <div className="coach-grade-section">
                    <h3>Language</h3>
                    <select
                      onChange={(e) => {
                        setLanguage(e.target.value);
                        if (level) {
                          filter_for_coach(
                            level,
                            e.target.value,
                            getCoach1?._id
                          ).then((res) => {
                            setSearchDetail(res.data.response);
                            if (res.data.response == "") {
                              alert("No course found :(");
                            }
                          });
                        }
                      }}
                    >
                      <option>Choose Language</option>
                      {Array.from(getCourse)?.length > 0
                        ? Array.from(getCourse).map((item, i) => {
                            return (
                              <option key={i} value={item}>
                                {item}
                              </option>
                            );
                          })
                        : "Not Found"}
                    </select>
                  </div>
                  {/* <div className="coach-grade-section">
                                        <h3>Service Offered</h3>
                                        <select onChange={(e) => {
                                            console.log("service==>", e.target.value);
                                        }}>
                                            <option>Choose Service Offered</option>
                                            <option value="online">Online Service</option>
                                            <option value="one-to-one">One To One Service</option>
                                        </select>
                                    </div> */}
                </div>
                <div
                  className="coach-short-bio wow fadeInUp"
                  data-wow-duration="1.6s"
                  data-wow-delay=".2s"
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: "0.4s",
                    inlineSize: "910px",
                    overflowWrap: "break-word",
                  }}
                >
                  <h3>Short Bio</h3>
                  <p>{getCoach1.short_bio}</p>
                  {/* <!-- Counter Wrapper Start --> */}
                  <div className="counter-wrapper coach-profile-counter">
                    <div className="counter-grid">
                      <div
                        className="wow fadeInUp"
                        data-wow-duration="1s"
                        data-wow-delay=".4s"
                        style={{
                          visibility: "visible",
                          animationDuration: "1s",
                          animationDelay: "0.4s",
                        }}
                      >
                        {/* <!-- Single Counter Start --> */}
                        <div className="single-counter">
                          <div className="counter-box">
                            <div className="counter-content">
                              <p>Total Courses</p>
                              <span className="count value" data-count="100">
                                {getCoach1.noOfCourses}
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* <!-- Single Counter End --> */}
                      </div>
                      <div
                        className="wow fadeInUp"
                        data-wow-duration="1s"
                        data-wow-delay=".2s"
                        style={{
                          visibility: "visible",
                          animationDuration: "1s",
                          animationDelay: "0.2s",
                        }}
                      >
                        {/* <!-- Single Counter Start --> */}
                        <div className="single-counter">
                          <div className="counter-box">
                            <div className="counter-content">
                              <p>Total Students</p>
                              <span className="count value" data-count="2500">
                                {getCoach1.noOfStudent}
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* <!-- Single Counter End --> */}
                      </div>
                    </div>
                  </div>
                  {/* <!-- Counter Wrapper End --> */}
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            {!getSearchDetail?.length ? (
              <h2 className="my-courses">
                My Courses <span>{getCoach1.noOfCourses}</span>
              </h2>
            ) : (
              ""
            )}
            {getSearchDetail?.length ? (
              <h2 className="my-courses">
                My Courses <span>{getSearchDetail?.length}</span>
              </h2>
            ) : (
              ""
            )}
            <div className="four-grid">
              {getCourse1?.length > 0 && !getSearchDetail?.length
                ? getCourse1.map((item, k) => {
                    return (
                      <div
                        className="individual-course wow fadeInUp"
                        data-wow-duration="1s"
                        data-wow-delay=".2s"
                        style={{
                          visibility: "visible",
                          animationDuration: "1s",
                          animationDelay: "0.2s",
                        }}
                        key={k}
                      >
                        <figure>
                          <Link to={`/coursedetail/${item._id}`}>
                            <img src={item?.course_images} />
                          </Link>
                        </figure>
                        <div className="individualCourseContent">
                          <div className="coach-cont">
                            <div>
                              <img src={item.coach.coach_image} />
                              <h4>
                                <Link to={`/coachprofile/${param.id}`}>
                                  {item?.coach?.coach_name}
                                </Link>
                              </h4>
                            </div>

                            {/* <div className="save-abs save-job-active" >
                                            <i className="fas fa-bookmark"></i>
                                        </div>

                                          <div className="save-abs">
                                             <i className="far fa-bookmark"></i>
                                          </div> */}
                          </div>
                          <h5>
                            <Link to={`/coursedetail/${item._id}`}>
                              {item.course_title}
                            </Link>
                          </h5>
                          <div className="indiv-view-section">
                            <div>
                              <i className="fas fa-eye"></i>{" "}
                              {item.review?.length} +
                            </div>
                            <div>
                              <i className="fas fa-book"></i>{" "}
                              {item.videos.length}, Lesson
                            </div>
                            <div>
                              <i className="fas fa-comments"></i> {item.comment}
                              +
                            </div>
                            <div>
                              <i className="fas fa-star"></i>
                              {item?.rating > 0 ? item?.rating : "0"}/5
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ""}
              {getSearchDetail?.length > 0
                ? getSearchDetail.map((item, k) => {
                    return (
                      <div
                        className="individual-course wow fadeInUp"
                        data-wow-duration="1s"
                        data-wow-delay=".2s"
                        style={{
                          visibility: "visible",
                          animationDuration: "1s",
                          animationDelay: "0.2s",
                        }}
                        key={k}
                      >
                        <figure>
                          <Link to={`/coursedetail/${item._id}`}>
                            <img src={item?.course_images} />
                          </Link>
                        </figure>
                        <div className="individualCourseContent">
                          <div className="coach-cont">
                            <div>
                              <img src={item?.coach[0]?.coach_image} />
                              <h4>
                                <Link to={`/coachprofile/${param?.id}`}>
                                  {item?.coach[0]?.coach_name}
                                </Link>
                              </h4>
                            </div>

                            {/* <div className="save-abs save-job-active" >
                                            <i className="fas fa-bookmark"></i>
                                        </div>

                                          <div className="save-abs">
                                             <i className="far fa-bookmark"></i>
                                          </div> */}
                          </div>
                          <h5>
                            <Link to={`/coursedetail/${item?._id}`}>
                              {item?.course_title}
                            </Link>
                          </h5>
                          <div className="indiv-view-section">
                            <div>
                              <i className="fas fa-eye"></i>{" "}
                              {item?.review?.length} +
                            </div>
                            <div>
                              <i className="fas fa-book"></i>{" "}
                              {item?.videos.length}, Lesson
                            </div>
                            <div>
                              <i className="fas fa-comments"></i>{" "}
                              {item?.comment}+
                            </div>
                            <div>
                              <i className="fas fa-star"></i>
                              {item?.rating > 0 ? item?.rating : "0"}/5
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>
            {/* <a href="" className="load-more">Load More</a> */}
          </div>
          {/* <!-- Single Courses Start --> */}

          {/* <!-- The Modal --> */}
          <div className="modal fade" id="myModal">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Need Help ?</h4>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                  ></button>
                </div>

                <div className="modal-body grade-pop coach-profile-need">
                  <div className="login-right  share-que">
                    <div className="login-ins-sec">
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          name: userDetails.name,
                          email: getEmail,
                          select_grade: getGrade1.toString(),
                          select_subject: getSubject1.toString(),
                          message: "",
                          coach: getCoach1._id,
                        }}
                        validationSchema={Yup.object({
                          name: Yup.string()
                            .max(40)
                            .matches(/^[A-Za-z ]*$/, "Please enter valid name"),
                          select_grade: Yup.string().required(),
                          select_subject: Yup.string().required(),
                          email: Yup.string().email("Invalid email address"),
                          message: Yup.string().required(),
                        })}
                        onSubmit={(values, { resetForm }) => {
                          setButton(false);

                          needHelp(values)
                            .then((res) => {
                              setButton(true);
                              Store.addNotification({
                                title: "Success",
                                message: res?.data?.message,
                                type: "success",
                                insert: "top",
                                container: "top-right",
                                animationIn: [
                                  "animate__animated",
                                  "animate__fadeIn",
                                ],
                                animationOut: [
                                  "animate__animated",
                                  "animate__fadeOut",
                                ],
                                dismiss: {
                                  duration: 5000,
                                  onScreen: true,
                                },
                              });

                              resetForm({ values: "" });
                            })
                            .catch((err) => {
                              setButton(true);
                              // console.log(err);
                            });
                        }}
                      >
                        {(props) => (
                          <Form>
                            <div className="need-p-grid">
                              <div>
                                <div className="form-group">
                                  <label>Name</label>
                                  <MyTextInput
                                    type="text"
                                    name="name"
                                    readOnly
                                  />
                                </div>
                              </div>
                              <div>
                                <div className="form-group">
                                  <label>Email ID</label>
                                  <MyTextInput
                                    type="email"
                                    name="email"
                                    readOnly
                                  />
                                </div>
                              </div>
                              <div>
                                <div className="form-group">
                                  <label>Select Grade</label>
                                  <MySelect
                                    name="select_grade"
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.select_grade}
                                  >
                                    <option value="">Select Grade</option>
                                    {getGrade.length > 0
                                      ? getGrade.map((i, k) => {
                                          return (
                                            <option value={i} key={k}>
                                              {i}
                                            </option>
                                          );
                                        })
                                      : ""}
                                    {/* <option value='1'>1</option>
                                                                        <option value='2'>2</option>
                                                                        <option value='3'>3</option>
                                                                        <option value='4'>4</option>
                                                                        <option value='5'>5</option>
                                                                        <option value='6'>6</option>
                                                                        <option value='7'>7</option>
                                                                        <option value='8'>8</option>
                                                                        <option value='9'>9</option>
                                                                        <option value='10'>10</option>
                                                                        <option value='11'>11</option>
                                                                        <option value='12'>12</option> */}
                                  </MySelect>

                                  {/* {getGrade.length > 0 ? getGrade.map((item, i) => {
                                                                        return (
                                                                            <select name="select_grade" onChange={props.handleChange}
                                                                                onBlur={props.handleBlur}
                                                                                value={props.values.select_grade}>
                                                                                <option >Select Grade</option>
                                                                                <option key={i}>{item}</option>
                                                                            </select>


                                                                        )
                                                                    }) : <MySelect name="select_grade" onChange={props.handleChange} onBlur={props.handleBlur} value={props.values.select_grade}>
                                                                        <option value=''>Select Grade</option>
                                                                        <option value='1'>1</option>
                                                                        <option value='2'>2</option>
                                                                        <option value='3'>3</option>
                                                                        <option value='4'>4</option>
                                                                        <option value='5'>5</option>
                                                                        <option value='6'>6</option>
                                                                        <option value='7'>7</option>
                                                                        <option value='8'>8</option>
                                                                        <option value='9'>9</option>
                                                                        <option value='10'>10</option>
                                                                        <option value='11'>11</option>
                                                                        <option value='12'>12</option>

                                                                    </MySelect>

                                                                    } */}
                                </div>
                              </div>

                              <div>
                                <div className="form-group">
                                  <label>Select Subject</label>
                                  <MySelect
                                    name="select_subject"
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.select_subject}
                                  >
                                    <option value="">Select Subject</option>

                                    {getAllPopularCourse.length > 0
                                      ? getAllPopularCourse.map((item) => {
                                          return (
                                            <option value={item?.course_title}>
                                              {item?.course_title}
                                            </option>
                                          );
                                        })
                                      : ""}
                                  </MySelect>

                                  {/* {getSubject.length > 0 ? getSubject.map((item, i) => {
                                                                        return (
                                                                            <MySelect name="select_subject" onChange={props.handleChange}
                                                                                onBlur={props.handleBlur}
                                                                                value={props.values.select_subject}>
                                                                                <option value = "">Select Subject</option>
                                                                                <option key={i} >{item}</option>
                                                                            </MySelect>

                                                                        )
                                                                    }) :

                                                                        <MySelect name="select_subject" onChange={props.handleChange}
                                                                            onBlur={props.handleBlur}
                                                                            value={props.values.select_subject}>
                                                                            <option value= "">Select Subject</option>

                                                                            {

                                                                                getAllPopularCourse.map((item) => {
                                                                                    return (<option value={item?.course_title}>{item?.course_title}</option>)


                                                                                })
                                                                            }

                                                                        </MySelect>

                                                                    } */}

                                  {/* {props.errors.select_subject && props.touched.select_subject && <div style={{ color: "red" }}>{props.errors.select_subject}</div>} */}
                                </div>
                              </div>
                            </div>
                            <div className="form-group need-pt-20">
                              <label>Message</label>
                              <MyTextArea
                                name="message"
                                placeholder="message"
                              />
                            </div>
                            <button
                              className="btn btn-primary btn-hover-heading-color cmn-btn"
                              type="submit"
                            >
                              Submit
                            </button>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Offcanvas Start --> */}
          <div className="offcanvas offcanvas-start" id="offcanvasMenu">
            <div className="offcanvas-header">
              <div className="offcanvas-logo">
                <a href=" ">
                  <img src="images/logo.webp" alt="" />
                </a>
              </div>
              <button
                className="btn-close"
                data-bs-dismiss="offcanvas"
              ></button>
            </div>
          </div>
          {/* <!-- Offcanvas End --> */}
          <Footer />
        </>
      )}

      <Modal show={show} onHide={handleClose} className="select-course-pop">
        <Modal.Header closeButton>
          <Modal.Title>Select Course</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Scroll />
            <Formik
              initialValues={{
                course_id: "",
              }}
              validate={(values) => {
                const errors = {};
                if (!getCourseId) {
                  errors.course_id = "Course is required !";
                }

                return errors;
              }}
              onSubmit={(values) => {
                values.course_id = getCourseId;
                navigate(`/bookcoach/${param.id}/${values.course_id}`);
              }}
            >
              {(props) => (
                <Form>
                  <div className="row">
                    <div className="col-md-12">
                      <label>Select Course</label>
                      <select
                        style={{ width: "100%", height: "70%" }}
                        name="course_id"
                        onChange={(e) => {
                          setCourseId(e.target.value);
                        }}
                      >
                        <option>Choose Course</option>

                        {(getCourse1 || []).map((item, index) => {
                          return (
                            <>
                              <option value={item?._id}>
                                {item?.course_title}
                              </option>
                            </>
                          );
                        })}
                      </select>
                      {!getCourseId &&
                        props.errors.course_id &&
                        props.touched.course_id && (
                          <div style={{ color: "red" }}>
                            {props.errors.course_id}
                          </div>
                        )}
                    </div>
                  </div>
                  <br></br>
                  <div className="row">
                    <div className="col-md-12">
                      <Button type="submit">Submit</Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
}
