import React, { useEffect, useState } from "react";
import InsideHeaderForContact from "../components/InsideHeader/InsideHeaderForContact";
import Footer from "./Footer";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { MyTextArea, MyTextInput } from "../services/web/inputServices";
import { contactUs, profile } from "../services/web/webServices";
import { Store } from "react-notifications-component";
import Header from "./Header";
export default function Contact() {
  const [getButton, setButton] = useState(true);
  const [getUserDetails, setUserDetails] = useState([]);
  return (
    <>
      <Header />
      <div className="container pt-120">
        <div className="section section-padding-02">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                {/* <!-- Contact Info Wrapper Start --> */}
                <div className="contact-info-wrapper">
                  <div className="row gx-0">
                  <div className="col-md-3">
                      {/* <!-- Single Contact Info Start --> */}
                      <div className="single-contact-info">
                        <div className="info-icon">
                          <i className="flaticon-whatsapp">
                          <a href="https://web.whatsapp.com/send?phone=076 287 0323" target="_blank">
                          <img src="images/icons8-whatsapp-100.png" className="whatsapp-icon"/></a>
                          </i>
                        </div>
                        <div className="info-content">
                        <a href="https://web.whatsapp.com/send?phone=076 287 0323" target="_blank">
                          <h5 className="title">Whatsapp</h5> </a>
                          <p> <a href="https://web.whatsapp.com/send?phone=076 287 0323" target="_blank">076 287 0323</a></p>
                        </div>
                      </div>
                      {/* <!-- Single Contact Info End --> */}
                    </div>
                    <div className="col-md-3">
                      {/* <!-- Single Contact Info Start --> */}
                      <div className="single-contact-info">
                        <div className="info-icon">
                        <a href="tel:12345 67890">
                          <i className="flaticon-phone-call"></i>
                          </a>
                        </div>
                        <div className="info-content">
                        <a href="tel:12345 67890">
                          <h5 className="title">Telephone</h5>
                          </a>
                          <p>
                            <a href="tel:12345 67890">011 238 7037</a>
                          </p>
                        </div>
                      </div>
                      {/* <!-- Single Contact Info End --> */}
                    </div>
                    <div className="col-md-3">
                      {/* <!-- Single Contact Info Start --> */}
                      <div className="single-contact-info">
                        <div className="info-icon">
                        <a href="mailto:admin@ethuta.com">
                          <i className="flaticon-mail"></i>
                          </a>
                        </div>
                        <div className="info-content">
                        <a href="mailto:admin@ethuta.com">
                          <h5 className="title">Our Mail</h5>
                          </a>
                          <p>
                            <a href="mailto:admin@ethuta.com">admin@ethuta.com</a>
                          </p>
                        </div>
                      </div>
                      {/* <!-- Single Contact Info End --> */}
                    </div>
                    <div className="col-md-3">
                      {/* <!-- Single Contact Info Start --> */}
                      <div className="single-contact-info">
                        <div className="info-icon">
                        <a href="https://maps.google.com/maps?ll=-25.988707,28.094105&z=11&t=m&hl=en&gl=US&mapclient=embed&q=Midrand%20South%20Africa" target="_blank">
                          <i className="flaticon-placeholder"></i>
                          </a>
                        </div>
                        <div className="info-content">
                          <a href="https://maps.google.com/maps?ll=-25.988707,28.094105&z=11&t=m&hl=en&gl=US&mapclient=embed&q=Midrand%20South%20Africa"  target="_blank">
                          <h5 className="title">Location</h5>
                          </a>
                          <p>161 Allan Rd, Glen Austin AH, Midrand, 1685</p>
                        </div>
                      </div>
                      {/* <!-- Single Contact Info End --> */}
                    </div>
                    
                  </div>
                </div>
                {/* <!-- Contact Info Wrapper End --> */}
              </div>
            </div>
          </div>
        </div>
        <div
          className="section section-padding pt-0"
          style={{ paddingBottom: "40px" }}
        >
          <div className="container">
            <div className="row justify-content-center cnts">
              <div className=" ">
                {/* <!-- Section Title Start --> */}
                {/* <!-- Section Title End --> */}
                {/* <!-- Contact Form Start --> */}
                <div className="contact-form-wrapper  share-que ">
                  <div className="section-title  ">
                    <h2 className="title">Contact Us</h2>
                  </div>
                  <Formik
                    initialValues={{
                      name: "",
                      subject: "",
                      phone: "",
                      email: "",
                      message: "",
                    }}
                    validationSchema={Yup.object({
                      name: Yup.string()
                        .matches(/^[A-Za-z ]*$/, "Please enter valid name")
                        .required("Name required"),
                      subject: Yup.string().required("Subject required"),
                      phone: Yup.number()
                        .typeError("That doesn't look like a phone number")
                        .positive("A phone number can't start with a minus")
                        .integer("A phone number can't include a decimal point")
                        .min(8)
                        .required("A phone number is required"),
                      email: Yup.string()
                        .email("Invalid email address")
                        .required("Email is required!!!"),
                      message: Yup.string()
                        // .matches(/^[A-Za-z ]*$/, 'Please enter valid text')
                        .required("Message required!!!"),
                    })}
                    onSubmit={(values, { resetForm }) => {
                      setButton(false);
                      contactUs(values)
                        .then((res) => {
                          setButton(true);
                          Store.addNotification({
                            title: "Success",
                            message: res?.data?.message,
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: [
                              "animate__animated",
                              "animate__fadeIn",
                            ],
                            animationOut: [
                              "animate__animated",
                              "animate__fadeOut",
                            ],
                            dismiss: {
                              duration: 5000,
                              onScreen: true,
                            },
                          });
                          resetForm({ values: "" });
                        })
                        .catch((err) => {
                          setButton(true);
                          if (err) {
                            console.log(err);
                          }
                        });
                    }}
                  >
                    <Form>
                      <div>
                        <div className="form-group">
                          <label>Name</label>
                          <MyTextInput
                            type="text"
                            name="name"
                            placeholder="Name *"
                          />
                        </div>
                        <div className="form-group">
                          <label>Subject</label>
                          <MyTextInput
                            type="text"
                            name="subject"
                            placeholder="Subject *"
                          />
                        </div>
                        <div className="form-group">
                          <label>Phone Number</label>
                          <MyTextInput
                            type="text"
                            name="phone"
                            placeholder="Phone Number*"
                          />
                        </div>
                        <div className="form-group">
                          <label>Email</label>
                          <MyTextInput
                            className="form-group"
                            type="email"
                            name="email"
                            placeholder="Email *"
                          />
                        </div>
                      </div>
                      <div className="form-group no-grid">
                        <label>Message</label>
                        <MyTextArea
                          type="text"
                          name="message"
                          placeholder="Message*"
                        />
                      </div>

                      {getButton === true ? (
                        <button
                          className="btn btn-primary btn-hover-heading-color cmn-btn btn-submit"
                          type="submit" 
                          style={{borderRadius:"15px", marginLeft:"9em"}}
                        >
                          Submit
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary btn-hover-heading-color cmn-btn"
                          disabled
                        >
                          wait please
                        </button>
                      )}
                    </Form>
                  </Formik>
                </div>
                {/* <!-- Contact Form End --> */}
              </div>
              <div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d229528.81081864348!2d27.806999784491413!3d-25.988115314143172!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e9571fd4965198b%3A0x87b2105c1c8bfe22!2sMidrand%2C%20South%20Africa!5e0!3m2!1sen!2sus!4v1650199032950!5m2!1sen!2sus"
                  width="600"
                  height="450"
                  style={{ border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Offcanvas Start --> */}
      <div className="offcanvas offcanvas-start" id="offcanvasMenu">
        <div className="offcanvas-header">
          <div className="offcanvas-logo">
            <a href=" ">
              <img src="images/logo.webp" alt="" />
            </a>
          </div>
          <button className="btn-close" data-bs-dismiss="offcanvas"></button>
        </div>
      </div>
      {/* <!-- Offcanvas End --> */}

      <Footer />
    </>
  );
}
