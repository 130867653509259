import React ,{useState} from 'react';
import { Formik, Form } from 'formik';
import { useParams } from 'react-router-dom';
import { MyTextInput, MyTextArea } from '../../services/web/inputServices';
import *  as Yup from 'yup'
import { addQueryForCoach } from '../../services/web/webServices';
import { Store } from 'react-notifications-component';

export default function QueryForCoach(props) {
    const param = useParams();
    const [getButton,setButton]=useState(false);
    return (
        <>
            <div className="tab-pane fade" id="share">
                <h3>Questions & Answer</h3>
                <div className="share-que">

                    <Formik
                        initialValues={{
                            subject: "",
                            message: "",
                            upload_img: "",
                            course_id: param.id,
                            coach_id:props?.data?.coach?._id,
                        }}
                        validationSchema={Yup.object({
                            subject: Yup.string().required(),
                            message: Yup.string().required(),
                            upload_img: Yup.mixed().required(),
                        })}

                        onSubmit={(values,{ resetForm }) => {
                            setButton(true);
                            addQueryForCoach(values).then((res) => {                 
                                Store.addNotification({
                                    title: "Success",
                                    message: res?.data?.message,
                                    type: "success",
                                    insert: "top",
                                    container: "top-right",
                                    animationIn: ["animate__animated", "animate__fadeIn"],
                                    animationOut: [
                                        "animate__animated",
                                        "animate__fadeOut",
                                    ],
                                    dismiss: {
                                        duration: 5000,
                                        onScreen: true,
                                    },
                                });
                                setButton(false);
                                resetForm({ values: "" });
                                


                            }).catch((err) => {

                                if (err) {
                                    Store.addNotification({
                                        title: "Error!",
                                        message: err?.response?.data?.message,
                                        type: "danger",
                                        insert: "top",
                                        container: "top-right",
                                        animationIn: ["animate__animated", "animate__fadeIn"],
                                        animationOut: ["animate__animated", "animate__fadeOut"],
                                        dismiss: {
                                            duration: 5000,
                                            onScreen: true,
                                        },
                                    });
                                    setButton(false);

                                }

                            })
                        }}
                    >
                        {props => (
                            <Form>
                                <div className="form-group">
                                    <label>Subject</label>
                                    <MyTextInput type="text" name="subject" />
                                </div>
                                <div className="form-group">
                                    <label>Message</label>
                                    <MyTextArea name="message" />
                                </div>
                                <div className="form-group">
                                    <label>Upload Document</label>
                                    <input type="file" name="upload_img" onChange={(e) => {
                                        props.values.upload_img = e.target.files[0];
                                    }} />
                                    {props.errors.upload_img && props.touched.upload_img && <div style={{ color: "red" }}>{props.errors.upload_img}</div>}
                                </div>
                                {/* <div className="form-group upload-sec upload-sec-2 ">
                                    <label>Upload Document</label>
                                    <input type="file" name="upload_img" onChange={(e) => {
                                        props.values.upload_img = e.target.files;
                                    }} />
                                    <div className="file-upload-abs">
                                        <img src="/images/upload.png" />
                                        <h5>
                                            Upload your files{" "}
                                            <span>File should be PNG, JPG, JPEG, WEBP, PDF</span>
                                        </h5>
                                    </div>
                                </div> */}

                                {getButton===false?<button className="btn btn-primary btn-hover-heading-color cmn-btn" type="submit">
                                    Post Comment
                                </button>:<button className="btn btn-primary btn-hover-heading-color cmn-btn" disabled>
                                    wait please!!
                                </button>}
                                
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </>
    )
}
