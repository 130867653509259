import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import ProfileSideMenu from './ProfileSideMenu';
import { getAllFav } from '../services/web/webServices';
import { Store } from 'react-notifications-component';
// import { Link } from 'react-router-dom';
import { removeFav } from '../services/web/webServices';
import { useNavigate } from 'react-router';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { Loader } from '../helper/Loader';
import LinearProgress from '@mui/material/LinearProgress';

const css = `
.css-eglki6-MuiLinearProgress-root{
  width:23.5% !important;

  .profile-list li:nth-child(4) a {
    color: #0086db !important;
  }
}`
export default function MyFavCourse() {
    const navigate = useNavigate();
    const [getDetail1, setDetail1] = useState([]);
    const [getLoader, setLoader] = useState(true)
    let getUserId;
    const getApi = () => {
        getAllFav()
            .then((res) => {
                setDetail1(
                    res.data.response.map((el, index) => ({
                        ...el,
                        id: el._id,
                        i: index,
                    }))
                );
                setLoader(false);


            })
            .catch((err) => {
                setLoader(false);
            });
    }
    useEffect(() => {
        getApi();
    }, [])





    const columns = [
        {
            field: "coach_image",
            headerName: "Image",
            width: 80,
            renderCell: (params) => {
                return (
                    <div>
                        {params?.row?.course_id?.coach.coach_image == " " ? (
                            <img
                                className="circular_image"
                                style={{ width: "62px" }}
                                src="images/profile.png"
                                alt="Not Found "
                            />
                        ) : (
                            <img
                                className="circular_image"

                                style={{ width: "62px", borderRadius: "47%" }}
                                src={params?.row?.course_id?.coach.coach_image}
                                alt=""
                            />
                        )}
                    </div>
                );
            },
        },
        {
            field: "coach_name",
            headerName: "Coach Name",
            width: 160,
            valueGetter: (index) => index.row?.course_id?.coach?.coach_name


        },
        {
            field: "#2",
            headerName: "Course Name",
            width: 250,
            valueGetter: (index) => index?.row?.course_id?.course_title
        },
        {
            field: "#3",
            headerName: "Level",
            width: 150,
            valueGetter: (index) => index?.row?.course_id?.level

        },
        {
            field: "#4",
            headerName: "Language",
            width: 150,
            valueGetter: (index) => index?.row?.course_id.language || "Not Found"
        },
        {
            field: "view",
            headerName: "View course",
            width: 150,
            renderCell:(item)=>{
                return(
                    <>
                        <a style={{cursor:"pointer",color:'blue'}} onClick={()=>navigate(`/coursedetail/${item?.row?.course_id?._id}`)}>Click to see</a>
                    </>
                )
            }
        },
        {
            field: "#5",
            headerName: "Action",
            width: 150,

            renderCell: (index) => {
                return (
                    <>

                        <div className="start-course fav-del">
                            <a onClick={() => removeFavourite(index.row.i)}><i className="far fa-trash-alt"></i></a>
                        </div>
                    </>
                )


            }
        },

    ];


    // const CoachProfile = (k) => {
    //     navigate(`/coachprofile/${getDetail1[k].course_id.coach._id}`)

    // }
    // const CoachName = (k) => {
    //     navigate(`/coursedetail/${getDetail1[k].course_id._id}`)
    // }
    const removeFavourite = (k) => {

        getUserId = getDetail1[k].course_id._id;

        removeFav(getUserId).then((res) => {

            Store.addNotification({
                title: "Success",
                message: res?.data?.message,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true,
                },

            });
            getApi();

        }).catch((err) => {
            console.log(err);

        })
    }
    return (
        <>
            <Header />
            <style>{css}</style>
            <div className="section page-banner-section bg-color-1" style={{ marginTop: '29px' }}>
                <div className="container">
                    {/* <!-- Page Banner Content Start --> */}
                    <div className="page-banner-content">
                        <h2 className="title">My Favourite Course</h2>
                        <ul className="breadcrumb justify-content-center">
                            <li className="breadcrumb-item"><a href="">Home</a></li>
                            <li className="breadcrumb-item active">My Favourite Course</li>
                        </ul>
                    </div>
                    {/* <!-- Page Banner Content End --> */}
                </div>
            </div>
            <div style={{ clear: "both" }}></div>
            <div className="container  ">
                <div className="profile-container">
                    <div className="profile-grid">
                        {/* <?php include "profile-side-menu.php" ?> */}
                        <ProfileSideMenu />
                        <div className="profile-grid-right wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s" style={{ visibility: "visible", animationDuration: "1s", animationDelay: "0.4s" }}>
                            <div className="profile-heading-flex">
                                <h2>My Favourite Course</h2>

                            </div>

                            {getLoader === true ?
                                <Box sx={{ width: '100%' }}>
                              <LinearProgress />
                           </Box>
                                : <Box sx={{ height: 400, width: '100%' }}>

                                    {getDetail1.length > 0 ? <DataGrid

                                        rows={getDetail1}

                                        columns={columns}
                                        pageSize={5}
                                        rowsPerPageOptions={[5]}


                                    /> : "No Favourite Data Found"}

                                </Box>}



                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
