import React, { useEffect, useState } from 'react';
import Footer from '../Footer';
import Header from '../Header';
import { Link } from 'react-router-dom';
import ProfileSideMenu from '../ProfileSideMenu';
import { getEnrolledCourse, getReply } from '../../services/web/webServices';
import { useNavigate } from 'react-router';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { Loader } from '../../helper/Loader';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import ScrollToTop from '../../scroll';
import ShowDocument from './ShowDocument';
import LinearProgress from '@mui/material/LinearProgress';

const css = `
.css-eglki6-MuiLinearProgress-root{
  width:25% !important;
  .profile-list li:nth-child(5) a {
    color: #0086db !important;
  }
}`
export default function CoachReply() {
    const [lgShow, setLgShow] = useState(false);
    const [getLoader, setLoader] = useState(true);
    const [getData, setData] = useState(null);
    const navigate = useNavigate();
    const [getDetail, setDetail] = useState(null);
    useEffect(() => {
        getReply().then((res) => {
            console.log(res.data.response);
            setData(
                res.data.response.map((el, index) => ({
                    ...el,
                    id: el._id,
                    i: index,
                }))
            );
            setLoader(false);
        }).catch((err) => {
            console.log(err);
            setLoader(false);
        })
    }, [])

    const columns = [

        {
            field: "coach_name",
            headerName: "Coach Name",
            width: 160,
            valueGetter: (index) => index.row.coach_id?.coach_name
        },
        {
            field: "course_name",
            headerName: "Course Name",
            width: 200,
            valueGetter: (index) => index.row.course_id?.course_title
        },
        {
            field: "course_message",
            headerName: "Message",
            width: 170,
            renderCell: (i) => {
                return (
                    <>
                        <button className="btn btn-primary btn-hover-heading-color cmn-btn" onClick={() => {
                            setLgShow(true)
                            setDetail(i?.row);
                        }}>Show Message</button>
                    </>
                )
            }
        },
        {
            field: "show file",
            headerName: "File",
            width: 170,
            renderCell: (i) => {
                return (
                    <>
                        <button className="btn btn-primary btn-hover-heading-color cmn-btn" onClick={() => {
                            navigate('/show_coach_document', { state: { data: i.row } })
                        }}>Click to view</button>
                    </>
                )
            }
        },
    ];


    return (
        <>
            <style>{css}</style>
            <Header />
            <div className="section page-banner-section bg-color-1" style={{ marginTop: '29px' }}>
                <div className="container">
                    {/* <!-- Page Banner Content Start --> */}
                    <div className="page-banner-content">
                        <h2 className="title">Query Solved</h2>
                        <ul className="breadcrumb justify-content-center">
                            <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                            <li className="breadcrumb-item active">My Course</li>
                        </ul>
                    </div>
                    {/* <!-- Page Banner Content End --> */}
                </div>
            </div>

            <div style={{ clear: "both" }}></div>
            <div className="container">
                <div className="profile-container">
                    <div className="profile-grid">
                        {/* <?php include "profile-side-menu.php" ?> */}
                        <ProfileSideMenu />
                        <div className="profile-grid-right wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s" style={{ visibility: "visible", animationDuration: "1s", animationDelay: "0.4s" }}>
                            <div className="profile-heading-flex">
                                <h2>My Queries</h2>

                            </div>


                            {getLoader === true ?
                                <Box sx={{ width: '100%' }}>
                                    <LinearProgress />
                                </Box>
                                : <Box sx={{ height: 400, width: '100%' }}>

                                    {getData.length > 0 ? <DataGrid

                                        rows={getData}

                                        columns={columns}
                                        pageSize={5}
                                        rowsPerPageOptions={[5]}


                                    /> : "No Reply Found!!!"}

                                </Box>}
                        </div>



                    </div>
                </div>
            </div>
            <Footer />




            {/* Show Message */}

            <Modal
                size="lg"
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Message
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <ScrollToTop />
                        {getDetail?.query_message}
                    </>
                </Modal.Body>
            </Modal>
            {/* Ends */}

        </>
    )
}
