import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { reset_password } from '../services/web/webServices';
import { Formik, Form,Field } from 'formik';
import { MyTextInput } from '../services/web/inputServices';
import * as Yup from 'yup';
import { Store } from 'react-notifications-component';
import { useNavigate } from "react-router-dom";
// import { ReactNotifications } from 'react-notifications-component';
// import 'react-notifications-component/dist/theme.css';



export default function ResetPassword() {
   const token=localStorage.getItem("auth_token");
   const type="password";
   const [button, setButton] = useState(true);
   const navigate=useNavigate();
   return (
      <>
      {/* <ReactNotifications /> */}
         <div class="login-main-outer">
            {/* <!-- Preloader Start  --> */}

            <div class="login-container">
               <div class="log-s">
                  <div class="login-left">
                     <div class="comlog abs-login1">
                        <img src="images/q1.png" />
                     </div>
                     <div class="comlog  abs-login2">
                        <img src="images/q2.png" />
                     </div>
                     <div class="comlog  abs-login3">
                        <img src="images/q3.png" />
                     </div>
                     <div class="comlog  abs-login6">
                        <img src="images/q3.png" />
                     </div>
                     <div class="comlog  abs-login4">
                        <img src="images/q4.jpg" />
                     </div>
                     <div class="comlog  abs-login5">
                        <img src="images/q1.png" />
                     </div>
                     <h3>Engineering, Sales, ICT, and Self development / coaching expertise.</h3>
                  </div>
                  <div class="login-right  share-que">
                     <div class="login-logo">
                        <Link to="/"><img src="images/logo.webp" alt="" /></Link>
                     </div>
                     <div class="login-ins-sec">
                        <div class="section-title  ">
                           <h2 class="title">Reset Password !</h2>
                        </div>
                        <Formik initialValues={{
                           Password:"",
                           confirmPassword:""
                        }}
                        validationSchema={Yup.object({
                            
                              Password: Yup.string().min(8),
                              confirmPassword:Yup.string()
                           })}

                           onSubmit={(values, { resetForm }) => {
                              setButton(false);
                              reset_password(values,token,type)
                              
                                 .then((res) => {
                                    setButton(true);
                                    Store.addNotification({
                                       title: "Success",
                                       message: res?.data?.message,
                                       type: "success",
                                       insert: "top",
                                       container: "top-right",
                                       animationIn: ["animate__animated", "animate__fadeIn"],
                                       animationOut: ["animate__animated", "animate__fadeOut"],
                                       dismiss: {
                                          duration: 5000,
                                          onScreen: true,
                                       },
                                    });
                                    resetForm({ values: "" });
                                    localStorage.clear();
                                    navigate("/");
                                 })
                                 .catch((err) => {
                                    setButton(true);
                                    if (err) {
                                       Store.addNotification({
                                          title: "Error!",
                                          message: err?.response?.data?.message,
                                          type: "danger",
                                          insert: "top",
                                          container: "top-right",
                                          animationIn: ["animate__animated", "animate__fadeIn"],
                                          animationOut: ["animate__animated", "animate__fadeOut"],
                                          dismiss: {
                                             duration: 5000,
                                             onScreen: true,
                                          },
                                       });
                                    }
                                 });
                           }}                         >
                           <Form>
                              <div>
                                 <div class="form-group">
                                    <label>Password</label>
                                    <MyTextInput

                                       type="password"
                                       name="Password"
                                       placeholder="New Password *"
                                       required="true"
                                    />

                                 </div>
                              </div>
                              <div>
                                 <div class="form-group">
                                    <label>Confirm Password</label>
                                    <MyTextInput

                                       type="password"
                                       name="confirmPassword"
                                       placeholder="Confirm Password *"
                                       required="true"
                                    />

                                 </div>
                              </div>
                              {button==true?<button class="btn btn-primary btn-hover-heading-color cmn-btn" type="submit">Reset Password</button>:<button class="btn btn-primary btn-hover-heading-color cmn-btn" type="button">wait please :)</button>}
                      
                           </Form>
                        </Formik>
                     </div>
                  </div>
               </div>
            </div>
            {/* <!-- Back to Top End --> */}
         </div>

      </>
   )
}
