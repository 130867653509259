import { Field, Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import Footer from "./Footer";
import InsideHeader from "./InsideHeader";
import * as Yup from "yup";
import { bookCoachForMe } from "../services/web/webServices";
import { Store } from "react-notifications-component";
import { MySelect, MyTextInput } from "../services/web/inputServices";
import Autocomplete from "react-google-autocomplete";
import { useNavigate } from "react-router-dom";
import Header from "./Header";

export default function Selectcoachforme() {
  const navigate = useNavigate();
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [studyLocation, setStudyLocation] = useState("");
  // const [place, setPlace] = useState('');
  const [selectWhereToStudy, setSelectWhereToStudy] = useState("block");
  const [locationValue, setLocationValue] = useState(null);
  const [whereToStudy, setWhereToStudy] = useState("");
  const [sessionType, setSessionType] = useState("");
  const [whereToStudyUi, setWhereToStudyUi] = useState(true);

  function getLocationLatLng(location) {
    console.log(location, "kolaveri");
    setLocationValue(location.label);
    // getCoordinates(location.label);
  }

  function selectStudyHome() {
    console.log("clicked home");
    setSelectWhereToStudy("block");
  }

  function selectStudyOnline() {
    console.log("clicked online");

    setSelectWhereToStudy("none");
  }

  // function getLatLng(place) {
  //   setStudyLocation(place.formatted_address);
  //   setLat(place.geometry.location.lat());
  //   setLng(place.geometry.location.lng());
  // }

  const [getButton, setButton] = useState(true);
  const [whereToStudyOption, setWhereToStudyOption] = useState("");

  function changeUiOn() {
    setWhereToStudyUi(false);
    setWhereToStudyOption("Online");
  }

  function changeUiFa() {
    setWhereToStudyUi(true);

    setWhereToStudyOption("Face-to-Face (eThuta Center)");
  }

  return (
    <>
      {/* <InsideHeader /> */}
      <Header />
      <div
        className="section page-banner-section bg-color-1"
        style={{ marginTop: "29px" }}
      >
        <div className="container">
          <div className="page-banner-content">
            <h2 className="title">Match me with a Coach</h2>
            <ul className="breadcrumb justify-content-center">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active">Match me with a Coach</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="newSectionElearn">
          <div className="course-bottom" style={{ clear: "both" }}>
            <div className="course-bottom-left">
              <Formik
                initialValues={{
                  grade: "",
                  subject: "",
                  where_to_study: "",
                  session_type: "",
                  // where_to_study_option: '',
                  where_to_study_address: "",
                  where_to_study_latitude: "",
                  where_to_study_longitude: "",
                }}
                validationSchema={Yup.object({
                  grade: Yup.string().required("Grade required"),
                  subject: Yup.string().required("Subject required"),
                  // where_to_study: Yup.string().required(
                  //   'Select where do you want to study'
                  // ),
                  session_type: Yup.string().required("Select session Type"),
                  where_to_study_option: Yup.string(),
                  where_to_study_address: Yup.string(),
                  where_to_study_latitude: Yup.string(),
                  where_to_study_longitude: Yup.string(),
                })}
                onSubmit={(values, { resetForm }) => {
                  values.where_to_study = whereToStudyOption;
                  values.where_to_study_address = studyLocation.toString();
                  values.where_to_study_latitude = lat.toString();
                  values.where_to_study_longitude = lng.toString();

                  setButton(false);
                  bookCoachForMe(values)
                    .then((res) => {
                      setButton(true);
                      Store.addNotification({
                        title: "Success",
                        message: res?.data?.message,
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                          duration: 5000,
                          onScreen: true,
                        },
                      });
                      resetForm({ values: "" });
                      navigate("/");
                    })
                    .catch((err) => {
                      setButton(true);

                      console.log(err);
                    });
                }}
              >
                <Form>
                  <div className="course-c wow fadeInUp">
                    <h2 className="insiMainTitle">Book Your Multiple Slots</h2>
                    <div className="coach-grade2">
                      <div className="coach-grade-section">
                        <h3>Select Grade</h3>
                        <MyTextInput
                          type="text"
                          name="grade"
                          placeholder="Grade *"
                        />
                      </div>
                      <div className="coach-grade-section">
                        <h3>Subject</h3>

                        <MyTextInput
                          type="text"
                          name="subject"
                          placeholder="Subject *"
                        />
                      </div>

                      <div className="coach-grade-section">
                        <h3>Select where to study</h3>

                        <select
                          // type='text'
                          name="where_to_study"
                          onChange={(i) => {
                            i.target.value == "Online"
                              ? changeUiOn()
                              : changeUiFa();
                          }}
                          // as='select'
                          // onChange={(event) => {
                          //   console.log(event.target.value, 'haha');
                          //   setWhereToStudy(event.target.value);
                          // }}
                          // value={whereToStudyOption}
                        >
                          <option readOnly>Select</option>
                          <option value="Face-to-Face (eThuta Center)">
                            Face-to-Face (eThuta Center)
                          </option>
                          <option value="Online">Online</option>
                        </select>
                        {/* <MyTextInput
                          type='text'
                          name='where_to_study'
                          value={whereToStudy}
                          disabled
                        /> */}
                      </div>

                      <div
                        className="coach-grade-section"
                        style={{ diplay: `${selectWhereToStudy}` }}
                      >
                        <h3>Select Session type</h3>
                        <MySelect type="text" name="session_type">
                          <option readOnly>Select</option>
                          <option value="One on One">On on One</option>
                          <option value="Class">Class</option>
                        </MySelect>
                        {/* <MyTextInput
                          type='text'
                          name='session_type'
                          value={sessionType}
                          disabled
                        /> */}
                      </div>
                    </div>
                    {/* {whereToStudyUi && (
                      <div
                        className='coach-grade-section'
                        // style={{ display: `${selectWhereToStudy}` }}
                      >
                        <h3>Select where to study</h3>
                        <MySelect name='where_to_study_option' as='select'>
                          <option value='Face-to-Face (Home)'>
                            Face-to-Face (Home)
                          </option>
                          <option value='Face-to-Face (Center)'>
                            Face-to-Face (Center)
                          </option>
                        </MySelect>
                      </div>
                    )} */}

                    <div
                      className="coach-grade-section"
                      style={{ marginTop: "25px" }}
                    >
                      <h3>Enter Location - Select from the dropdown</h3>

                      <Autocomplete
                        className="coach-grade-section"
                        apiKey="AIzaSyAmmo5iqyWMEROq8eBQy_s7zjxF_qJ2mT0"
                        //   onPlaceSelected={(place) => {
                        //     setLat(place.geometry.location.lat());
                        //     setLng(place.geometry.location.lng());
                        //     setPlace(place.formatted_address);
                        //     console.log(place);

                        //   }
                        // }
                        // selectProps={{
                        //   locationValue,
                        //   onChange: getLocationLatLng,
                        // }}
                        onPlaceSelected={
                          (place) => {
                            setStudyLocation(place.formatted_address);
                            setLat(place.geometry.location.lat());
                            setLng(place.geometry.location.lng());
                          }

                          // console.log('===>Lat', place.formatted_address)
                        }
                      />
                      <MyTextInput
                        type="text"
                        name="where_to_study_address"
                        placeholder="Place *"
                        value={studyLocation}
                        hidden
                      />
                      <MyTextInput
                        type="text"
                        name="where_to_study_latitude"
                        placeholder="lat *"
                        value={lat}
                        hidden
                      />
                      <MyTextInput
                        type="text"
                        name="where_to_study_longitude"
                        placeholder="lng *"
                        value={lng}
                        hidden
                      />
                    </div>

                    {/* <MyTextInput
                      type='text'
                      name='where_to_study_address'
                      placeholder='Location *'
                      value={place}
                      disabled
                    />
                    <MyTextInput
                      type='text'
                      name='where_to_study_latitude'
                      placeholder='Lat *'
                      value={lat}
                      disabled
                    />
                    <MyTextInput
                      type='text'
                      name='where_to_study_longitude'
                      placeholder='Lng *'
                      value={lng}
                      disabled
                    /> */}
                  </div>
                  <button
                    style={{ marginTop: "30px", width: "100%" }}
                    className="btn btn-primary btn-hover-heading-color cmn-btn"
                    // className='global-button startbtn'
                    type="submit"
                  >
                    Match me with a Coach
                  </button>
                </Form>
              </Formik>

              {/* <div className='course-c wow fadeInUp'>
                <h2 className='insiMainTitle'>Book Your Multiple Slots</h2>
                <div className='coach-grade2'>
                  <div className='coach-grade-section'>
                    <h3>Select Grade</h3>
                    <select>
                      <option>Class I</option>
                      <option>Class VI</option>
                      <option>Class VI</option>
                      <option>Class IX</option>
                    </select>
                  </div>
                  <div className='coach-grade-section'>
                    <h3>Subject</h3>
                    <select>
                      <option>English</option>
                      <option>Hindi</option>
                      <option>Math</option>
                      <option>Science</option>
                    </select>
                  </div>

                  <div className='coach-grade-section'>
                    <h3>Select where to study</h3>
                    <select>
                      <option>Face-to-Face (Home or Center)</option>
                      <option>Online</option>
                    </select>
                  </div>
                  <div className='coach-grade-section'>
                    <h3>Select Session type</h3>
                    <select>
                      <option>1: 1</option>
                      <option>1: Many (Class)</option>
                    </select>
                  </div>
                </div>
                <div className='coach-grade-section'>
                  <h3>Select where to study</h3>
                  <select>
                    <option>Face-to-Face (Home)</option>
                    <option>Face-to-Face (Center)</option>
                  </select>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
