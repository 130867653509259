import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import {
  getAllCoach,
  getSingleCoach,
  profile,
  saw_all_push_notification,
  profileNeedHelp,
  getInviteLinkCheck,
  get_course_comment_saw,
  getCourses,
} from "../services/web/webServices";
import { Store } from "react-notifications-component";
import { Field, Form, Formik } from "formik";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import ScrollToTop from "../scroll";

export default function ProfileSideMenu() {
  const navigate = useNavigate();
  const [getCoachDetail, setCoachDetail] = useState([]);
  const [getStatus, setStatus] = useState("false");
  const [getSingleCoachGrade, setSingleCoachGrade] = useState([]);
  const [getSingleSubject, setSingleSubject] = useState([]);
  const [getCoachId, setCoachId] = useState("");
  const [getDetail, setDetail] = useState([]);
  const [show, setShow] = useState(false);
  const [getButton, setButton] = useState(true);
  const [getData, setData] = useState([]);

  const [getAllPopularCourse, setAllPopularCourse] = useState({});

  const getApi = () => {
    getInviteLinkCheck()
      .then((res) => {
        localStorage.removeItem("message");
      })
      .catch((err) => {});

    get_course_comment_saw()
      .then((res) => {
        localStorage.removeItem("message");
      })
      .catch((err) => {
        console.log(err);
      });
    saw_all_push_notification()
      .then((res) => {
        localStorage.removeItem("message");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleClose = () => {
    setShow(false);
    setStatus(false);
  };
  // const handleShow = () => setShow(true);

  const Logout = () => {
    localStorage.removeItem("auth_token");
    localStorage.removeItem("state");
    navigate("/");
  };

  const onHandle1 = (e) => {
    setStatus(true);
    setCoachId(e);
    // Api get SingleCoachBy Id
    setSingleCoachGrade([]);
    setSingleSubject([]);
    getSingleCoach(e)
      .then((res) => {
        setSingleCoachGrade(res?.data?.response?.grade);
        console.log("-----yuyu--", res?.data?.response);
        setSingleSubject(res?.data?.response?.subject_of_teacher);
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });
  };
  // ends of OnChange Handler

  useEffect(() => {
    //    start
    profile()
      .then((res) => {
        setDetail(res.data.response);
        if (res.data.response.status == 0) {
          Store.addNotification({
            title: "Error!",
            message: res?.data?.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
          localStorage.removeItem("auth_token");
          localStorage.removeItem("state");
          navigate("/login");
        }
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });
    getAllCoach()
      .then((res) => {
        setCoachDetail(res?.data?.response);
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });

    getCourses()
      .then((res) => {
        setAllPopularCourse(res.data.response);
        console.log("------getpopular course---------2222", res.data.response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      
      <div className="profile-grid-left">
        <div className="profile-pic">
          <div className="browse-file">
            {getDetail.profile_pic == " " ? (
              <img src="images/profile.png" style={{ objectFit: "contain" }} />
            ) : (
              <img src={getDetail.profile_pic} style={{ objectFit: "cover" }} />
            )}

            {/* <i className="fas fa-pencil-alt"></i> */}
          </div>
          <h2>{getDetail?.name}</h2>
          <a
            className="nh"
            onClick={() => {
              setShow(true);
            }}
          >
            <i className="fas fa-info"></i>
          </a>
        </div>
        <div className="profile-list">
          <ul>
            <li>
              <Link to="/profile">
                <i className="far fa-user" style={{ cursor: "pointer" }}></i>{" "}
                Edit Profile
              </Link>
            </li>

            <li>
              <Link to="/mycourse">
                <i
                  className="fas fa-book-open"
                  style={{ cursor: "pointer" }}
                ></i>{" "}
                My Course
              </Link>
            </li>

            <li>
              <Link to="/grade">
                <i
                  className="fas fa-graduation-cap"
                  style={{ cursor: "pointer" }}
                ></i>{" "}
                Grade
              </Link>
            </li>
            {/* <!-- <li><a href=""><i className="far fa-user"></i> Parent / Guardian</a></li> --> */}

            <li>
              <Link to="/myfavcourse">
                <i
                  className="fas fa-bookmark"
                  style={{ cursor: "pointer" }}
                ></i>
                My Favourite Course
              </Link>
            </li>

            <li>
              <Link to="/coach_reply">
                <i
                  className="fa fa-reply"
                  aria-hidden="true"
                  style={{ cursor: "pointer" }}
                ></i>
                Coach Reply
              </Link>
            </li>

            <li>
              <a
                onClick={() => {
                  getApi();
                  navigate("/notification");
                }}
              >
                <i
                  className="fas fa-bell"
                  style={{ cursor: "pointer", color: "black" }}
                ></i>
                Notification
              </a>
              {!localStorage.getItem("message") ? (
                ""
              ) : (
                <div className="chat-noti-wrap">
                  <span>
                    {" "}
                    {localStorage.getItem("message") > 100
                      ? "100+"
                      : localStorage.getItem("message")}
                  </span>
                </div>
              )}
            </li>

            {/* <!-- <li><a href=""><i className="far fa-user"></i> Payment</a></li> --> */}
            {/* <li><a href=""><i className="far fa-user"></i> Logout</a></li> */}
            <button
              className="btn btn-primary"
              type="submit"
              onClick={Logout}
              style={{ cursor: "pointer" }}
            >
              <i className="fas fa-sign-out-alt"> Logout</i>
            </button>
          </ul>
        </div>
      </div>
      {/* Modal start */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>I Need Help</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body grade-pop coach-profile-need prifle-gcntainer">
            <div className="login-right  share-que">
              <div className="login-ins-sec">
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    name: getDetail?.name,
                    email: getDetail?.email || getDetail?.father_email,
                    select_grade: "",
                    select_subject: "",
                    coach: getCoachId,
                  }}
                  validationSchema={Yup.object({
                    select_grade: Yup.string().required(),
                    select_subject: Yup.string().required(),
                  })}
                  onSubmit={(values) => {
                    setButton(false);
                    profileNeedHelp(values)
                      .then((res) => {
                        setButton(true);
                        Store.addNotification({
                          title: "Success",
                          message: res?.data?.message,
                          type: "success",
                          insert: "top",
                          container: "top-right",
                          animationIn: ["animate__animated", "animate__fadeIn"],
                          animationOut: [
                            "animate__animated",
                            "animate__fadeOut",
                          ],
                          dismiss: {
                            duration: 5000,
                            onScreen: true,
                          },
                        });
                        setShow(false);
                        setStatus(false);
                      })
                      .catch((err) => {
                        setButton(true);
                        console.log(err);
                      });
                  }}
                >
                  {(props) => (
                    <Form>
                      <ScrollToTop />
                      <div className="prifle-g">
                        <div>
                          <div className="form-group">
                            <label>Name</label>
                            <Field
                              type="text"
                              name="name"
                              readOnly="readonly"
                            />
                          </div>
                        </div>
                        <div>
                          <div className="form-group">
                            <label>Email ID</label>
                            <Field
                              type="email"
                              name="email"
                              readOnly="readonly"
                            />
                          </div>
                        </div>
                        <div>
                          <div className="form-group">
                            <label>Select Coach</label>
                            <select
                              name="coach"
                              onChange={(e) => {
                                onHandle1(e.target.value);
                              }}
                            >
                              <option>Select Coach</option>
                              {getCoachDetail.length > 0
                                ? getCoachDetail.map((item, index) => {
                                    return (
                                      <option key={index} value={item?._id}>
                                        {item?.coach_name}{" "}
                                      </option>
                                    );
                                  })
                                : "Not Found"}
                            </select>
                          </div>
                        </div>
                        {getStatus == true ? (
                          <>
                            <div>
                              <div className="form-group">
                                <label>Select Grade</label>
                                <select
                                  name="select_grade"
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={props.values.select_grade}
                                >
                                  <option>Select Grade</option>
                                  {getSingleCoachGrade?.length > 0
                                    ? getSingleCoachGrade?.map(
                                        (item, index) => {
                                          return (
                                            <>
                                              <option key={index}>
                                                {item}
                                              </option>
                                            </>
                                          );
                                        }
                                      )
                                    : ""}
                                </select>
                                {props.errors.select_grade &&
                                  props.touched.select_grade && (
                                    <div style={{ color: "red" }}>
                                      {props.errors.select_grade}
                                    </div>
                                  )}
                              </div>
                            </div>

                            <div>
                              <div className="form-group">
                                <label>Select Subject</label>
                                <select
                                  name="select_subject"
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={props.values.select_subject}
                                >
                                  <option>Select Subject</option>

                                  {getAllPopularCourse.length > 0
                                    ? getAllPopularCourse.map((item) => {
                                        return (
                                          <option value={item?.course_title}>
                                            {item?.course_title}
                                          </option>
                                        );
                                      })
                                    : ""}

                                  {/* {getSingleSubject.length > 0
                                    ? getSingleSubject.map((item, index) => {
                                        return (
                                          <option key={index}>{item}</option>
                                        );
                                      })
                                    : ''} */}
                                </select>
                                {props.errors.select_subject &&
                                  props.touched.select_subject && (
                                    <div style={{ color: "red" }}>
                                      {props.errors.select_subject}
                                    </div>
                                  )}
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {getStatus == true ? (
                        getButton === true ? (
                          <button
                            type="submit"
                            className="btn btn-primary btn-hover-heading-color cmn-btn"
                          >
                            Submit
                          </button>
                        ) : (
                          <button
                            disabled
                            className="btn btn-primary btn-hover-heading-color cmn-btn"
                          >
                            wait please!!
                          </button>
                        )
                      ) : (
                        ""
                      )}
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
