import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import InsideHeader from "./InsideHeader";
import ProfileSideMenu from "./ProfileSideMenu";
import { Formik, Form } from "formik";
import { MyTextInput,MySelect } from "../services/web/inputServices";
import { Store } from "react-notifications-component";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import { editprofile, profile } from "../services/web/webServices";

export default function EditProfile() {
  const [button, setButton] = useState(true);
  const navigate = useNavigate();
  let img1 = null;

  const [userDetails, setUserDetails] = useState({});
  const [image, setImage] = useState({});
  const [getImageUrl, setImageUrl] = useState('');
  const [getState, setState] = useState(true);
  const onHandle = (e) => {
    setImage({
      pictureAsFile: e.target.files[0],

    });
    setState(false);
    setImageUrl(URL.createObjectURL(e.target.files[0]));

  };

  useEffect(() => {
    //    start
    profile()
      .then((res) => {

        setUserDetails(res.data.response);
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });
  }, []);






  const css = `
  .profile-list li:nth-child(1) a {
    color: #0086db !important;
  }
  `
  return (
    <>
      <InsideHeader />
      <style>{css}</style>
      <div className="container pt-120">
        <div className="profile-container">
          <div className="profile-grid">

            <ProfileSideMenu />
            <div className="profile-grid-right">
              <h2>Edit Profile</h2>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  name: userDetails.name,
                  phone: userDetails.phone,
                  school:userDetails.school,
                  grade:userDetails.grade,
                  province:userDetails.province,
                  city:userDetails.city,
                  township:userDetails.township,
                  areacode:userDetails.areacode,
                  profile_pic: "",
                }}
                validationSchema={Yup.object({
                  name: Yup.string().matches(/^[A-Za-z ]*$/, 'Please enter valid name'),
                  phone: Yup.string().matches(
                    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                    "Phone number is not valid"
                  ).min(6).max(15),
                  profile_pic: Yup.mixed(),
                })}
                onSubmit={(values, { resetForm }) => {
                  let formData = new FormData();
                  if (image.pictureAsFile) {
                    formData.append("profile_pic", image.pictureAsFile);
                  }
                  formData.append("name", values.name);
                  formData.append("phone", values.phone);

                  formData.append("school", values.school);
                  formData.append("grade", values.grade);
                  formData.append("province", values.province);
                  formData.append("city", values.city);
                  formData.append("township", values.township);
                  formData.append("areacode", values.areacode);
                  


                  setButton(false);

                  editprofile(formData)
                    .then((res) => {
                      setButton(true);

                      Store.addNotification({
                        title: "Success",
                        message: res?.data?.message,
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                          duration: 5000,
                          onScreen: true,
                        },
                      });
                      resetForm({ values: "" });

                      // localStorage.setItem("name", res.data.response.name);

                      navigate("/profile");
                    })
                    .catch((err) => {
                      setButton(true);
                      if (err) {
                        console.log(err);


                      }
                    });
                }}
              >
                { props => (
                     <Form className="share-que">
                     <div>
                       <div className="form-group">
                         <label>Name</label>
                         <MyTextInput
                           className="form-group"
                           type="text"
                           name="name"
                         />
                       </div>
                     </div>
                     <div>
                       <div className="form-group">
                         <label>Upload Profile</label>
                         {getState ? <img src={userDetails.profile_pic} className=" w-50 p-3" style={{ marginLeft: "70px", objectFit: "cover" }} alt="" /> : <img src={getImageUrl} className=" w-50 p-3" style={{ marginLeft: "70px", objectFit: "cover" }} alt="" />}
   
                         <input
                           type="file"
                           accept="image/*"
                           name="profile_pic"
                           onChange={onHandle}
                         />
   
                       </div>
                     </div>
                     <div>
                       <div className="form-group">
                         <label>Phone Number</label>
                         <MyTextInput
                           className="form-group"
                           type="text"
                           name="phone"
                         />
                       </div>
                       <div className="form-group">
                         <label>School Name</label>
                         <MyTextInput
                           className="form-group"
                           type="text"
                           name="school"
                         />
                       </div>
                       <div className="form-group">
                         <label>School Grade</label>
                         <MySelect  className="form-control" name="grade" onChange={props.handleChange} onBlur={props.handleBlur} value={props.values.grade}>
                                <option value=''>Select Grade</option>
                                <option value='1'>1</option>
                                <option value='2'>2</option>
                                <option value='3'>3</option>
                                <option value='4'>4</option>
                                <option value='5'>5</option>
                                <option value='6'>6</option>
                                <option value='7'>7</option>
                                <option value='8'>8</option>
                                <option value='9'>9</option>
                                <option value='10'>10</option>
                                <option value='11'>11</option>
                                <option value='12'>12</option>
                        </MySelect>
    {props.errors.grade && props.touched.grade && <div style={{ color: "red" }}>{props.errors.grade}</div>}
                       </div>

                       <div className="form-group">
                         <label>Province</label>
                         <MySelect  className="form-control" name="province" onChange={props.handleChange} onBlur={props.handleBlur} value={props.values.province}>
                                <option value=''>Select Province</option>
                                <option value='Free State'>Free State</option>
                              <option value='Gauteng'>Gauteng</option>
                              <option value='KwaZulu-Natal'>KwaZulu-Natal</option>
                              <option value='Limpopo'>Limpopo</option>
                              <option value='Mpumalanga'>Mpumalanga</option>
                              <option value='Northern Cape'>Northern Cape</option>
                              <option value='North West'>North West</option>
                              <option value='Western Cape'>Western Cape</option>
                              <option value='Eastern Cape'>Eastern Cape</option>
                        </MySelect>
    {props.errors.province && props.touched.province && <div style={{ color: "red" }}>{props.errors.province}</div>}
                       </div>
                       
                       <div className="form-group">
                         <label>City</label>
                         <MyTextInput
                           className="form-group"
                           type="text"
                           name="city"
                         />
                       </div>
                       <div className="form-group">
                         <label>Township</label>
                         <MyTextInput
                           className="form-group"
                           type="text"
                           name="township"
                         />
                       </div>
                       <div className="form-group">
                         <label>Area Code</label>
                         <MyTextInput
                           className="form-group"
                           type="text"
                           name="areacode"
                         />
                       </div>

                     </div>
                     <button
                       className="btn btn-primary btn-hover-heading-color cmn-btn"
                       type="submit"
                     >
                       Update Details
                     </button>
                   </Form>
              )}
               
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
