const base_uri = {
  base_uri: `https://ethuta-backend-app.azurewebsites.net/api/v1`,
  backend_base_uri: `https://ethuta-backend-app.azurewebsites.net`,
  backend_base_ngrok_uri: `https://ethuta-backend-app.azurewebsites.net`,
  base_uri_coach: `https://ethuta-backend-app.azurewebsites.net/coach`,
  url: `https://ethuta.com`,

  coach_base_url: `https://ethuta-coachpanel-app.azurewebsites.net/`,
  admin_base_url: `https://ethuta-adminpanel-app.azurewebsites.net/`,

  // Payfast Live details update rev02 to main branch
  merchant_id: "21428356",
  merchant_key: "xipthkbixxkup",
  passphrase: "eThutaProject123",
  payfast_uri: "https://www.payfast.co.za/eng/process",
};
export default base_uri;
