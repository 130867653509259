import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import About from "../components/About";
import Blog from "../components/Blog";
import Contact from "../components/Contact";
import CourseDetail from "../components/CourseDetail";
import Home from "../components/Home";
import Login from "../components/Login";
import SearchFilterCategory from "../components/SearchFilterCategory";
import SignUp from "../components/SignUp";
import CoachProfile from "../components/CoachProfile";
import ForgotPassword from "../components/ForgotPassword";
import PrivacyPolicy from "../components/PrivacyPolicy";
import MyCourse from "../components/MyCourse";
import ProfileSideMenu from "../components/ProfileSideMenu";
import Profile from "../components/Profile";
import Grade from "../components/Grade";
import MyFavCourse from "../components/MyFavCourse";
import Notification from "../components/Notification";
import EditProfile from "../components/EditProfile";
import ChangePassword from "../components/ChangePassword";
import StartCourse from "../components/StartCourse/StartCourse";
import BookCoach from "../components/BookCoach";
import ResetPassword from "../components/ResetPassword";
import EmailVerification from "../components/EmailVerification";
import ForgotPasswordMove from "../components/ForgotPasswordMove";
import BecomeCoach from "../components/BecomeCoach";
import MultiCoaches from "../components/MultipleCoaches";
import {
  GuardedLogin,
  GuardedRoute,
  Guard_And_Navigate_To_Login,
} from "../services/authentication/authentication/GuardedRoute";
import Coach_Signup_Verification from "../components/Coach_Signup_Verification";
import CoachReply from "../components/coachReply/CoachReply";
import ShowDocument from "../components/coachReply/ShowDocument";
import Checkout from "../components/Payment/Checkout";
import Notify from "../components/Payment/Notify";
import CoachForgotPassword from "../components/CoachForgotPassword";
import Selectcoachforme from "../components/Selectcoachforme";
import TermsConditions from "../components/TermsConditions";
import Rescheduling from "../components/Rescheduling";
export default function AllRoute() {
  return (
    <>
      <Routes>
        {/* Not Routed */}
        <Route path="/" caseSensitive={false} element={<Home />} />
        <Route path="/about" caseSensitive={false} element={<About />} />
        <Route path="/blog" caseSensitive={false} element={<Blog />} />
        <Route path="/contact" caseSensitive={false} element={<Contact />} />
        <Route
          path="/search_filter"
          caseSensitive={false}
          element={<SearchFilterCategory />}
        />
        <Route
          path="/privacypolicy"
          caseSensitive={false}
          element={<PrivacyPolicy />}
        />
        <Route
          path="/terms-and-conditions"
          caseSensitive={false}
          element={<TermsConditions />}
        />

        <Route
          path="/becomecoach"
          caseSensitive={false}
          element={<BecomeCoach />}
        />
        <Route
          path="/multicoach"
          caseSensitive={false}
          element={<MultiCoaches />}
        />
        <Route
          path="/selectcoachforme"
          caseSensitive={false}
          element={<Selectcoachforme />}
        />
        <Route
          path="/forgotpassword"
          caseSensitive={false}
          element={<ForgotPassword />}
        />
        {/* Ends */}

        {/* GuardedLogin */}
        <Route exact path="/login" element={<GuardedLogin />}>
          <Route
            exact
            path="/login"
            caseSensitive={false}
            element={<Login />}
          />
        </Route>
        <Route exact path="/signup" element={<GuardedLogin />}>
          <Route
            exact
            path="/signup"
            caseSensitive={false}
            element={<SignUp />}
          />
        </Route>
        {/* Ends */}

        {/* Guarded And Navigate to login */}

        <Route
          exact
          path="/coursedetail/:id"
          element={<Guard_And_Navigate_To_Login />}
        >
          <Route
            path="/coursedetail/:id"
            caseSensitive={false}
            element={<CourseDetail />}
          />
        </Route>
        <Route
          exact
          path="/coachprofile/:id"
          element={<Guard_And_Navigate_To_Login />}
        >
          <Route
            path="/coachprofile/:id"
            caseSensitive={false}
            element={<CoachProfile />}
          />
        </Route>
        <Route
          exact
          path="/resetpassword"
          element={<Guard_And_Navigate_To_Login />}
        >
          <Route
            path="/resetpassword"
            caseSensitive={false}
            element={<ResetPassword />}
          />
        </Route>
        <Route
          exact
          path="/bookcoach/:id/:course_id"
          element={<Guard_And_Navigate_To_Login />}
        >
          <Route
            path="/bookcoach/:id/:course_id"
            caseSensitive={false}
            element={<BookCoach />}
          />
        </Route>
        <Route
          exact
          path="/rescheduling/:id/:course_id"
          element={<Guard_And_Navigate_To_Login />}
        >
          <Route
            path="/rescheduling/:id/:course_id"
            caseSensitive={false}
            element={<Rescheduling />}
          />
        </Route>
        {/* Ends */}

        {/* Guard Route */}
        <Route exact path="/profile" element={<GuardedRoute />}>
          <Route path="/profile" caseSensitive={false} element={<Profile />} />
        </Route>
        <Route exact path="/notify_url" element={<GuardedRoute />}>
          <Route
            path="/notify_url"
            caseSensitive={false}
            element={<Notify />}
          />
        </Route>

        <Route exact path="/mycourse" element={<GuardedRoute />}>
          <Route
            path="/mycourse"
            caseSensitive={false}
            element={<MyCourse />}
          />
        </Route>

        <Route exact path="/coach_reply" element={<GuardedRoute />}>
          <Route
            path="/coach_reply"
            caseSensitive={false}
            element={<CoachReply />}
          />
        </Route>
        <Route exact path="/show_coach_document" element={<GuardedRoute />}>
          <Route
            path="/show_coach_document"
            caseSensitive={false}
            element={<ShowDocument />}
          />
        </Route>

        <Route exact path="/profilesidemenu" element={<GuardedRoute />}>
          <Route
            path="/profilesidemenu"
            caseSensitive={false}
            element={<ProfileSideMenu />}
          />
        </Route>
        <Route exact path="/checkout" element={<GuardedRoute />}>
          <Route
            path="/checkout"
            caseSensitive={false}
            element={<Checkout />}
          />
        </Route>

        <Route exact path="/grade" element={<GuardedRoute />}>
          <Route path="/grade" caseSensitive={false} element={<Grade />} />
        </Route>
        <Route exact path="/myfavcourse" element={<GuardedRoute />}>
          <Route
            path="/myfavcourse"
            caseSensitive={false}
            element={<MyFavCourse />}
          />
        </Route>
        <Route exact path="/notification" element={<GuardedRoute />}>
          <Route
            path="/notification"
            caseSensitive={false}
            element={<Notification />}
          />
        </Route>
        <Route exact path="/editprofile" element={<GuardedRoute />}>
          <Route
            path="/editprofile"
            caseSensitive={false}
            element={<EditProfile />}
          />
        </Route>
        <Route exact path="/changepassword" element={<GuardedRoute />}>
          <Route
            path="/changepassword"
            caseSensitive={false}
            element={<ChangePassword />}
          />
        </Route>
        <Route exact path="/startcourse/:id" element={<GuardedRoute />}>
          <Route
            path="/startcourse/:id"
            caseSensitive={false}
            element={<StartCourse />}
          />
        </Route>

        <Route
          path="/emailverification"
          caseSensitive={false}
          element={<EmailVerification />}
        />
        <Route
          path="/coach_email_verification/:token"
          caseSensitive={false}
          element={<Coach_Signup_Verification />}
        />
        <Route
          path="/coach_forgot_password_verification/:token"
          caseSensitive={false}
          element={<CoachForgotPassword />}
        />
        <Route
          path="/forgotpasswordmove"
          caseSensitive={false}
          element={<ForgotPasswordMove />}
        />
        {/* Ends */}
        {/* If Something Wrong Entred  */}
        <Route exact path="*" caseSensitive={false} element={<Home />} />
        {/* Ends */}
      </Routes>
    </>
  );
}
