import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { emailVerification } from "../services/web/webServices";
import { useNavigate } from "react-router-dom";
import { Store } from "react-notifications-component";
export default function EmailVerification() {
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();
  const type = "email";

  useEffect(() => {
    emailVerification(type, queryParams.get("token"))
      .then((res) => {
        Store.addNotification({
          title: "Success",
          message: res?.data?.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
        navigate("/login");
      })
      .catch((err) => {
        Store.addNotification({
          title:
            "Account already verified or token is invalid. Contact admin@ethuta.com",

          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
        navigate("/");
      });
  }, []);
}
