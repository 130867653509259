import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  getSingleCourse,
  getMcq,
  getMcqInfo,
  get_resource,
} from "../../services/web/webServices";
import Footer from "../Footer";
import InsideHeader from "../InsideHeader";
import { Store } from "react-notifications-component";
import ReactPlayer from "react-player";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import QueryForCoach from "./QueryForCoach";
import Document from "./Document";
import Qna from "./Qna";
import Modal from "react-bootstrap/Modal";

export default function StartCourse() {
  const [getDocument, setDocument] = useState(null);
  const [lgShow, setLgShow] = useState(false);
  const [getIndex, setIndex] = useState(null);
  const param = useParams();
  const navigate = useNavigate();
  const [getDetail3, setDetail3] = useState([]);
  const [getVideo, setVideo] = useState(null);
  const [getLoader, setLoader] = useState(true);
  const [getCourseMcq, setCourseMcq] = useState(null);
  const [getState, setState] = useState(true);
  const [getCoursetMcqInfo, setCourseMcqInfo] = useState(null);
  const [getCondition, setCondition] = useState(true);
  const [getSelected, setSeleted] = useState([]);

  const startVideo = (val) => {
    getMcq(param?.id, getDetail3.videos[val]?._id)
      .then((res) => {
        setCourseMcq(res.data.response);
        setState(false);
        if (res.data.response.status == 0) {
          Store.addNotification({
            title: "Error!",
            message: res?.data?.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
          localStorage.removeItem("auth_token");
          localStorage.removeItem("state");
          navigate("/login");
        }
      })
      .catch((err) => {
        console.log(err);
      });

    setVideo(getDetail3.videos[val]);
  };
  useEffect(() => {
    getMcqInfo(param.id)
      .then((res) => {
        setCourseMcqInfo(res.data.response[0]);
      })
      .catch((err) => {
        console.log(err);
      });
    getSingleCourse(param.id)
      .then((res) => {
        setDetail3(res.data.response);
        setLoader(false);
        if (res.data.response.status == 0) {
          Store.addNotification({
            title: "Error!",
            message: res?.data?.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
          localStorage.removeItem("auth_token");
          localStorage.removeItem("state");
          navigate("/login");
        }
      })
      .catch((err) => {
        if (err) {
          setLoader(false);
          console.log(err);
        }
      });
  }, []);

  const getApi = (courseId, videoId) => {
    get_resource(courseId, videoId).then((res) => {
      setDocument(res.data.response[0]?.resource);
    });
  };
  return (
    <>
      <InsideHeader />

      {getLoader === true ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={getLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <div className="container pt-120">
            <div className="start-course-container">
              <div
                className="start-course-container-left wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
                style={{
                  visibility: "visible",
                  animationDuration: "1s",
                  animationDelay: "0.4s",
                }}
              >
                {/* main video shown  */}
                {!getVideo ? (
                  <>
                    <div className="video-elar startCourse1">
                      <div>
                        <div
                          className="video-image  wow fadeInUp"
                          data-wow-duration="1s"
                          data-wow-delay=".2s"
                          style={{
                            visibility: "visible",
                            animationDuration: "1s",
                            animationDelay: "0.4s",
                          }}
                        >
                          <figure>
                            <img
                              src={getDetail3?.videos?.[0]?.video_thumbnail}
                            />
                            <div className="video-gallery-wrapper edu-section-gapTop video-section-overlayto-another">
                              <div className="video-gallery-1">
                                <div className="thumbnail video-popup-wrapper">
                                  <button
                                    className="video-play-btn with-animation position-to-top video-popup-activation btn-secondary-color size-80"
                                    onClick={() => startVideo(0)}
                                  >
                                    <span>
                                      <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        strokeWidth="0"
                                        viewBox="0 0 448 512"
                                        className="play-icon"
                                        height="1em"
                                        width="1em"
                                      >
                                        <path d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"></path>
                                      </svg>
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <ReactPlayer
                      controls
                      config={{
                        file: { attributes: { controlsList: "nodownload" } },
                      }}
                      url={getVideo.video_url}
                      height={500}
                      width="400"
                      playing={true}
                      playIcon={<button>Play</button>}
                      light={getDetail3?.videos?.video_thumbnail}
                    />
                  </>
                )}

                <h2>{getDetail3.course_title}</h2>
                <div className="start-view">
                  <span>{getDetail3?.review?.length}+ views </span>{" "}
                  <span>{getDetail3?.coach?.created_at?.slice(0, 10)}</span>
                </div>
                <div className="coach-name-start-course">
                  <img src={getDetail3?.coach?.coach_image} />
                  <h3>{getDetail3?.coach?.coach_name}</h3>
                </div>
              </div>
              <div
                className="start-course-container-right wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
                style={{
                  visibility: "visible",
                  animationDuration: "1s",
                  animationDelay: "0.4s",
                }}
              >
                {getDetail3.videos?.length > 0
                  ? getDetail3?.videos.map((item, index) => {
                      return (
                        <>
                          <div className="sub-vedio-content" key={index}>
                            <img src={item?.video_thumbnail} key={index} />
                            <h2 key={index}>
                              <a onClick={() => startVideo(index)}>
                                {item.video_name}
                              </a>
                              <span>{getDetail3?.coach?.coach_name}</span>
                              {item?.isResource == true ? (
                                <button
                                  style={{
                                    marginLeft: "61px",
                                    fontSize: "18px",
                                    backgroundColor: "white",
                                    padding: "7px",
                                  }}
                                  onClick={() => {
                                    getApi(param?.id, item?._id);
                                    setLgShow(true);
                                    console.log("item=======>", item);
                                  }}
                                >
                                  Resource
                                </button>
                              ) : (
                                ""
                              )}
                            </h2>
                          </div>
                        </>
                      );
                    })
                  : "not found"}
              </div>
            </div>
            <div
              className="pricing-wrapper start-course-desc wow fadeInUp"
              data-wow-duration="1.5s"
              data-wow-delay=".2s"
              style={{
                visibility: "visible",
                animationDuration: "1s",
                animationDelay: "0.4s",
              }}
            >
              <ul className="nav   pricing-btn">
                <li>
                  <button
                    className="active"
                    data-bs-toggle="tab"
                    data-bs-target="#annual"
                  >
                    Course Overview
                  </button>
                </li>
                <li>
                  <button data-bs-toggle="tab" data-bs-target="#monthly">
                    Q & A
                  </button>
                </li>
                <li>
                  <button data-bs-toggle="tab" data-bs-target="#document">
                    Documents
                  </button>
                </li>
                <li>
                  <button data-bs-toggle="tab" data-bs-target="#mcq">
                    MCQ
                  </button>
                </li>
                <li>
                  <button data-bs-toggle="tab" data-bs-target="#share">
                    Share Queries
                  </button>
                </li>
              </ul>
              <div className="tab-content">
                <div className="tab-pane fade show active" id="annual">
                  <div className="coach-short-bio">
                    <h3>What you will learn</h3>
                    <ul className="lessons-list">
                      {getDetail3?.what_you_will_learn?.length > 0
                        ? getDetail3?.what_you_will_learn?.map((i, k) => {
                            return <li key={k}>{i}</li>;
                          })
                        : "Not Found"}
                    </ul>
                  </div>
                  <div className="course-c">
                    <div className="lessons-top1">
                      <h2 className="insiMainTitle">Course Content</h2>
                      <ul className="lessons-list">
                        {getDetail3?.course_content?.length > 0
                          ? getDetail3?.course_content?.map((i, k) => {
                              return <li key={k}>{i}</li>;
                            })
                          : "Not Found"}
                      </ul>
                    </div>
                  </div>
                </div>
                <Qna />
                <Document data={getDetail3} />
                <div className="tab-pane fade" id="mcq">
                  <div className=" ">
                    <h3>MCQ Question</h3>
                    {/* <!-- Course Accordion Start --> */}
                    <div className="mcq-container">
                      <form>
                        <div className="mcq-section">
                          {getState === true ? (
                            getCoursetMcqInfo?.questions?.length > 0 ? (
                              getCoursetMcqInfo.questions?.map(
                                (item, index) => {
                                  return (
                                    <>
                                      <div className="mcq-content">
                                        <h2>{`Question ${index + 1}.)`}</h2>
                                        <h2>{item?.question}</h2>
                                        <div className="mcq-option">
                                          <div className="mcq-question-container">
                                            {item?.options?.map(
                                              (item1, index1) => {
                                                return (
                                                  <>
                                                    <div
                                                      className="form-group"
                                                      key={index1}
                                                    >
                                                      <input
                                                        type="radio"
                                                        id={index1}
                                                        name={`${index}options`}
                                                        onChange={() => {
                                                          if (
                                                            item1 ===
                                                            item?.answer
                                                          ) {
                                                            setCondition(false);
                                                            setSeleted([
                                                              ...getSelected,
                                                              item?._id,
                                                            ]);
                                                          } else {
                                                            setCondition(false);
                                                            setSeleted([
                                                              ...getSelected,
                                                              item?._id,
                                                            ]);
                                                          }
                                                        }}
                                                      />
                                                      {getCondition === true ? (
                                                        <div>{item1}</div>
                                                      ) : getSelected.find(
                                                          (v) => v == item?._id
                                                        ) ? (
                                                        item1 ==
                                                        item?.answer ? (
                                                          <div
                                                            style={{
                                                              color: "green",
                                                            }}
                                                          >
                                                            <i
                                                              className="fa fa-check"
                                                              aria-hidden="true"
                                                            >
                                                              {item1}
                                                            </i>
                                                          </div>
                                                        ) : (
                                                          <div
                                                            style={{
                                                              color: "red",
                                                            }}
                                                          >
                                                            {item1}
                                                          </div>
                                                        )
                                                      ) : (
                                                        <div>{item1}</div>
                                                      )}
                                                    </div>
                                                  </>
                                                );
                                              }
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                }
                              )
                            ) : (
                              <h3
                                style={{
                                  width: "200%",
                                  textAlign: "center",
                                }}
                              >
                                No Mcq Available
                              </h3>
                            )
                          ) : getCourseMcq[0] ? (
                            getCourseMcq[0].questions?.map((item3, index3) => {
                              return (
                                <>
                                  <div className="mcq-content">
                                    <h2>{`Question ${index3 + 1}.)`}</h2>
                                    <h2>{item3?.question}</h2>
                                    <div className="mcq-option">
                                      <div className="mcq-question-container">
                                        {item3?.options?.map((q, i) => {
                                          return (
                                            <>
                                              <div
                                                className="form-group"
                                                key={i}
                                              >
                                                <input
                                                  type="radio"
                                                  id={i}
                                                  name={`${index3}options`}
                                                  onChange={() => {
                                                    if (q === item3?.answer) {
                                                      setCondition(false);
                                                      setSeleted([
                                                        ...getSelected,
                                                        item3?._id,
                                                      ]);
                                                    } else {
                                                      setCondition(false);
                                                      setSeleted([
                                                        ...getSelected,
                                                        item3?._id,
                                                      ]);
                                                    }
                                                  }}
                                                />
                                                {getCondition === true ? (
                                                  <div>{q}</div>
                                                ) : getSelected.find(
                                                    (v) => v == item3?._id
                                                  ) ? (
                                                  q == item3?.answer ? (
                                                    <div
                                                      style={{ color: "green" }}
                                                    >
                                                      <i
                                                        className="fa fa-check"
                                                        aria-hidden="true"
                                                      >
                                                        {q}
                                                      </i>
                                                    </div>
                                                  ) : (
                                                    <div
                                                      style={{ color: "red" }}
                                                    >
                                                      {q}
                                                    </div>
                                                  )
                                                ) : (
                                                  <div>{q}</div>
                                                )}
                                              </div>
                                            </>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })
                          ) : (
                            <h3
                              style={{
                                width: "200%",
                                textAlign: "center",
                              }}
                            >
                              No Mcq Available
                            </h3>
                          )}
                        </div>
                      </form>
                    </div>
                    {/* <!-- Course Accordion End --> */}
                  </div>
                </div>
                <QueryForCoach data={getDetail3} />
              </div>
            </div>
          </div>

          <Footer />
        </>
      )}

      {/* Modal of Document */}
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => {
          setLgShow(false);
          setIndex(null);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="row">
              {getDocument?.resource_name?.length > 0
                ? getDocument?.resource_name.map((item, index) => {
                    return (
                      <>
                        <div className="col-md-6">
                          <h4
                            style={{ cursor: "pointer" }}
                            onClick={() => setIndex(index)}
                            key={index}
                          >
                            {item}
                          </h4>
                        </div>
                      </>
                    );
                  })
                : ""}
            </div>
            <br></br>
            <br></br>
            <h4>{getDocument?.resource_name[getIndex]}</h4>
            <embed
              src={getDocument?.resource_url[getIndex]}
              width="100%"
              height="600px"
            />
          </>
        </Modal.Body>
      </Modal>
      {/* Ends */}
    </>
  );
}
