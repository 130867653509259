import React, { useEffect, useState } from "react";
import {
  getInviteLink,
  getAcceptedRequest,
  get_all_student_saw_comment,
  get_all_saw_push_notification,
  checkRescheduleSession,
} from "../services/web/webServices";
import Footer from "./Footer";
import Header from "./Header";
import ProfileSideMenu from "./ProfileSideMenu";
import moment from "moment";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function Notification() {
  const [getLink, setLink] = useState(null);
  const [get_acceptedRequest, set_acceptedRequest] = useState(null);
  const [getLoader, setLoader] = useState(true);
  const [courseComment, setCourseComment] = useState(null);
  const [getPushNotification, setPushNotification] = useState(null);
  const [getData, setData] = useState(null);
  const [modalShow, setModalShow] = React.useState(false);
  const [getReschModal, setReschModal] = useState(false);
  const [getAttemptModal, setAttemptModal] = useState(false);
  const [getReasonModal, setReasonModal] = useState(false);
  const [getContinue, setContinue] = useState(false);
  const [getSelectedItem, setSelectedItem] = useState(null);
  const [getSelectedReason, setSelectedReason] = useState(null);
  const [getErrorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();

  const handelContinue = () => {
    setReasonModal(true);
  };
  const handelReason = () => {
    if (!getSelectedReason) {
      setErrorMessage("Please select reason");
    } else {
      setContinue(true);
      handleRescheduling(getSelectedItem);
    }
  };
  const handleRescheduling = async (data) => {
    const currentTime = new Date();
    // console.log(currentTime);
    const sessionTime = moment(data.slot_time[0].time_slot, "HH:mm").format(
      "HH:mm"
    );
    const sessionDate = moment(data.select_date).format("YYYY-MM-DD");
    const bookedSlot = `${sessionDate}T${sessionTime}`;
    const slot = new Date(bookedSlot);
    const hoursDiff = Math.abs(
      (slot.getTime() - currentTime.getTime()) / (1000 * 60 * 60)
    );
    const canReschedule = hoursDiff >= 24;
    if (canReschedule) {
      const response = await checkRescheduleSession(data.select_date);
      const resMessage = response.data.message;
      if (resMessage == "negative") {
        setAttemptModal(true);
        if (getContinue) {
          localStorage.setItem("itemData", JSON.stringify(data));
          localStorage.setItem(
            "rescheduleReason",
            JSON.stringify(getSelectedReason)
          );
          navigate(
            `/rescheduling/${data.slot_id.coach_id}/${data.slot_id.course_id}`
          );
        }
      } else {
        if (resMessage == "positive" || getContinue) {
          localStorage.setItem("itemData", JSON.stringify(data));
          setSelectedReason("");
          localStorage.setItem("rescheduleReason", getSelectedReason);
          navigate(
            `/rescheduling/${data.slot_id.coach_id}/${data.slot_id.course_id}`
          );
        }
      }
    } else {
      setReschModal(true);
    }
  };
  useEffect(() => {
    getInviteLink()
      .then((res) => {
        console.log("==>> ", res);
        setLink(res.data.response);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
    getAcceptedRequest()
      .then((res) => {
        //console.log("DARA======",res);
        set_acceptedRequest(res.data.response);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
    get_all_student_saw_comment()
      .then((res) => {
        setCourseComment(res.data.response);
      })
      .catch((err) => {
        console.log(err);
      });
    get_all_saw_push_notification()
      .then((res) => {
        setPushNotification(res.data.response);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <embed src={getData?.push_image} width="100%" height="600px" />
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  function reschedulingModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>File</Modal.Title>
        </Modal.Header>
        <Modal.Body></Modal.Body>
        <Modal.Footer>
          <Button>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <>
      <Header />
      <div
        className="section page-banner-section bg-color-1"
        style={{ marginTop: "29px" }}
      >
        <div className="container">
          {/* <!-- Page Banner Content Start --> */}
          <div className="page-banner-content">
            <h2 className="title">Notification</h2>
            <ul className="breadcrumb justify-content-center">
              <li className="breadcrumb-item">
                <a href="">Home</a>
              </li>
              <li className="breadcrumb-item active">Notification</li>
            </ul>
          </div>
          {/* <!-- Page Banner Content End --> */}
        </div>
      </div>
      <div style={{ clear: "both" }}></div>
      <div className="container  ">
        <div className="profile-container">
          <div className="profile-grid">
            {/* <?php include "profile-side-menu.php" ?> */}
            <ProfileSideMenu />
            <div
              className="profile-grid-right wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay=".2s"
              style={{
                visibility: "visible",
                animationDuration: "1s",
                animationDelay: "0.4s",
              }}
            >
              <h2>Notification</h2>
              <div className="notification-section">
                {getLoader === true ? (
                  <Box sx={{ width: "100%" }}>
                    <LinearProgress />
                  </Box>
                ) : (
                  <>
                    {!getLink?.length > 0 &&
                    !get_acceptedRequest?.length > 0 &&
                    !courseComment?.length > 0 &&
                    getPushNotification?.length > 0
                      ? "No notification found"
                      : ""}
                    {getLink?.length > 0 ? (
                      <h3>
                        ---------------------------Coach accepted Online
                        booking--------------------------
                      </h3>
                    ) : (
                      ""
                    )}
                    {getLink?.length > 0
                      ? getLink.map((item, index) => {
                          const now = moment();

                          const slotDate = item?.select_date
                            ? moment(item.select_date).format("YYYY-MM-DD")
                            : null;
                          const slotTime = item?.slot_time?.[0]?.time_slot;
                          const slotDateTime =
                            slotDate && slotTime
                              ? `${slotDate} ${slotTime}`
                              : null;

                          const slotMoment = slotDateTime
                            ? moment(slotDateTime, "YYYY-MM-DD HH:mm")
                            : null;

                          if (
                            slotMoment &&
                            slotMoment.isValid() &&
                            slotMoment.isBefore(now.clone().add(1, "hour"))
                          ) {
                            return null;
                          } else {
                            return (
                              <>
                                <div className="noti-list" key={index}>
                                  <figure key={index}>
                                    <img src="images/logo1.png" />
                                  </figure>
                                  <div>
                                    <h3>
                                      Invite Link From{" "}
                                      {item?.coach_id?.coach_name}
                                    </h3>
                                    <div>
                                      <span
                                        style={{
                                          fontSize: "20px",
                                          color: "black",
                                        }}
                                      >
                                        Selected Class :{" "}
                                      </span>
                                      <span
                                        style={{
                                          fontSize: "20px",
                                          color: "blue",
                                        }}
                                      >
                                        {(item?.slot_time || []).find(
                                          (q) =>
                                            q.center_booking ||
                                            q.class ||
                                            q.subject
                                        ) ? (
                                          <>{item?.slot_time[0].class}</>
                                        ) : (
                                          ""
                                        )}
                                      </span>
                                    </div>
                                    <div>
                                      <span
                                        style={{
                                          fontSize: "20px",
                                          color: "black",
                                        }}
                                      >
                                        Selected Subject :{" "}
                                      </span>
                                      <span
                                        style={{
                                          fontSize: "20px",
                                          color: "blue",
                                        }}
                                      >
                                        {(item?.slot_time || []).find(
                                          (q) =>
                                            q.center_booking ||
                                            q.class ||
                                            q.subject
                                        ) ? (
                                          <>{item?.slot_time[0].subject}</>
                                        ) : (
                                          ""
                                        )}
                                      </span>
                                    </div>
                                    <div>
                                      <span
                                        style={{
                                          fontSize: "20px",
                                          color: "black",
                                        }}
                                      >
                                        Class Type :{" "}
                                      </span>
                                      <span
                                        style={{
                                          fontSize: "20px",
                                          color: "blue",
                                        }}
                                      >
                                        {item?.slot_id?.class_type ==
                                        "one-to-one"
                                          ? ""
                                          : "Online"}
                                      </span>
                                    </div>

                                    <div className="row bookslot-btn">
                                      <strong>Booked Slots :</strong>
                                      {(item?.slot_time || []).map((q, i) => {
                                        return (
                                          <>
                                            <button
                                              className="col-md-2"
                                              style={{ marginLeft: "10px" }}
                                              key={i}
                                            >
                                              {q?.time_slot} - {q?.end_time}
                                            </button>
                                          </>
                                        );
                                      })}
                                    </div>
                                    <div>
                                      <span
                                        style={{
                                          fontSize: "20px",
                                          color: "black",
                                        }}
                                      >
                                        {(item?.slot_time || []).find(
                                          (q) =>
                                            q?.center_booking == "home_tution"
                                        )
                                          ? "Student Address :"
                                          : ""}
                                      </span>
                                      <span
                                        style={{
                                          fontSize: "20px",
                                          color: "blue",
                                        }}
                                      >
                                        {(item?.slot_time || []).find(
                                          (q) =>
                                            q.center_booking == "home_tution"
                                        ) ? (
                                          <>
                                            {
                                              item?.slot_time[0]
                                                ?.student_location
                                            }
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </span>
                                    </div>

                                    <div className="row">
                                      <p>
                                        Date Of Booking :{" "}
                                        {moment
                                          .utc(item?.select_date)
                                          .format("DD/MM/YYYY")}{" "}
                                      </p>
                                    </div>
                                    <div className="row">
                                      <p>
                                        Invite Link :
                                        <a
                                          href={item?.link_url}
                                          style={{ color: "blue" }}
                                        >
                                          {item?.link_url}
                                        </a>
                                      </p>
                                    </div>
                                    {item.is_rescheduled === true ? (
                                      <p
                                        style={{
                                          fontWeight: "bold",
                                          color: "red",
                                        }}
                                      >
                                        Reschedule Attempt: 0
                                      </p>
                                    ) : (
                                      <>
                                        <p
                                          style={{
                                            fontWeight: "bold",
                                            color: "green",
                                          }}
                                        >
                                          Reschedule Attempt: 1
                                        </p>
                                        <Button
                                          onClick={() => {
                                            handleRescheduling(item);
                                            setSelectedItem(item);
                                          }}
                                          disabled={item.is_rescheduled}
                                        >
                                          Reschedule
                                        </Button>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </>
                            );
                          }
                        })
                      : ""}{" "}
                    {console.log(getLink)}
                    {get_acceptedRequest?.length > 0 ? (
                      <h3>
                        -----------------------Coach accepted Face-to-Face
                        booking------------------------
                      </h3>
                    ) : (
                      ""
                    )}
                    {get_acceptedRequest?.length > 0
                      ? get_acceptedRequest.map((item, index) => {
                          return (
                            <>
                              <div className="noti-list" key={index}>
                                <figure key={index}>
                                  <img src="images/logo1.png" />
                                </figure>
                                <div>
                                  <h3>
                                    Invite Link From{" "}
                                    {item?.coach_id?.coach_name}
                                  </h3>
                                  <div>
                                    <span
                                      style={{
                                        fontSize: "20px",
                                        color: "black",
                                      }}
                                    >
                                      Selected Class :{" "}
                                    </span>
                                    <span
                                      style={{
                                        fontSize: "20px",
                                        color: "blue",
                                      }}
                                    >
                                      {(item?.slot_time || []).find(
                                        (q) =>
                                          q.center_booking ||
                                          q.class ||
                                          q.subject
                                      ) ? (
                                        <>{item?.slot_time[0]?.class}</>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </div>
                                  <div>
                                    <span
                                      style={{
                                        fontSize: "20px",
                                        color: "black",
                                      }}
                                    >
                                      Selected Subject :{" "}
                                    </span>
                                    <span
                                      style={{
                                        fontSize: "20px",
                                        color: "blue",
                                      }}
                                    >
                                      {(item?.slot_time || []).find(
                                        (q) =>
                                          q.center_booking ||
                                          q.class ||
                                          q.subject
                                      ) ? (
                                        <>{item?.slot_time[0]?.subject}</>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </div>
                                  <div>
                                    <span
                                      style={{
                                        fontSize: "20px",
                                        color: "black",
                                      }}
                                    >
                                      Class Type :{" "}
                                    </span>
                                    <span
                                      style={{
                                        fontSize: "20px",
                                        color: "blue",
                                      }}
                                    >
                                      {item?.slot_id?.class_type == "one-to-one"
                                        ? "One To One"
                                        : ""}
                                    </span>
                                  </div>

                                  <div>
                                    <span
                                      style={{
                                        fontSize: "20px",
                                        color: "black",
                                      }}
                                    >
                                      Booking For :{" "}
                                    </span>
                                    <span
                                      style={{
                                        fontSize: "20px",
                                        color: "blue",
                                      }}
                                    >
                                      {item?.slot_time[0]?.center_booking ==
                                      "ethuta_center"
                                        ? "Ethuta Center"
                                        : "Home Tution"}
                                    </span>
                                  </div>
                                  <div className="row bookslot-btn">
                                    <strong>Booked Slots :</strong>
                                    {(item?.slot_time || []).map((q, i) => {
                                      return (
                                        <>
                                          <button
                                            className="col-md-2"
                                            style={{ marginLeft: "10px" }}
                                            key={i}
                                          >
                                            {q?.time_slot} - {q?.end_time}
                                            {console.log("qqqqqqq------", item)}
                                          </button>
                                        </>
                                      );
                                    })}
                                  </div>
                                  <div>
                                    <span
                                      style={{
                                        fontSize: "20px",
                                        color: "black",
                                      }}
                                    >
                                      {(item?.slot_time || []).find(
                                        (q) =>
                                          q?.center_booking == "ethuta_center"
                                      )
                                        ? "Center Address :"
                                        : ""}
                                    </span>
                                    <span
                                      style={{
                                        fontSize: "20px",
                                        color: "blue",
                                      }}
                                    >
                                      {(item?.slot_time || []).find(
                                        (q) =>
                                          q.center_booking == "ethuta_center"
                                      ) ? (
                                        <>
                                          {
                                            item?.slot_time[0].center_id
                                              ?.center_address
                                          }
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </div>
                                  <div className="row">
                                    <p>
                                      Date Of Booking :{" "}
                                      {moment
                                        .utc(item?.select_date)
                                        .format("DD/MM/YYYY")}{" "}
                                      <span style={{ color: "blue" }}>
                                        (dd/mm/yyyy)
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })
                      : ""}
                    {courseComment?.length > 0 ? (
                      <h3>
                        -----------------------------Coach reply on your
                        comment---------------------------
                      </h3>
                    ) : (
                      ""
                    )}
                    {courseComment?.length > 0
                      ? courseComment.map((item, index) => {
                          return (
                            <>
                              <div className="noti-list" key={index}>
                                <figure key={index}>
                                  <img src="images/logo1.png" />
                                </figure>
                                <div>
                                  <h3>Comment From Coach </h3>
                                  {item?.course_id?.coach?.coach_name}
                                  <div>
                                    <span
                                      style={{
                                        fontSize: "20px",
                                        color: "black",
                                      }}
                                    >
                                      Coach Reply :{" "}
                                    </span>
                                    <span
                                      style={{
                                        fontSize: "20px",
                                        color: "blue",
                                      }}
                                    >
                                      {item?.reply}
                                    </span>
                                  </div>
                                  <div>
                                    <span
                                      style={{
                                        fontSize: "20px",
                                        color: "black",
                                      }}
                                    >
                                      You Comment :{" "}
                                    </span>
                                    <span
                                      style={{
                                        fontSize: "20px",
                                        color: "blue",
                                      }}
                                    >
                                      {item?.comment}
                                    </span>
                                  </div>
                                  <div>
                                    <span
                                      style={{
                                        fontSize: "20px",
                                        color: "black",
                                      }}
                                    >
                                      Coach reply date :{" "}
                                    </span>
                                    <span
                                      style={{
                                        fontSize: "20px",
                                        color: "blue",
                                      }}
                                    >
                                      {moment(item?.created_at).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </span>
                                  </div>

                                  <div className="row">
                                    {/* <p>Date Of Booking : {moment.utc(item?.select_date).format('DD/MM/YYYY')} <span style={{ color: "blue" }}>(dd/mm/yyyy)</span></p> */}
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })
                      : ""}
                    {getPushNotification?.length > 0 ? (
                      <h3>
                        -----------------------------Coach notification for
                        you-------------------------------
                      </h3>
                    ) : (
                      ""
                    )}
                    {getPushNotification?.length > 0
                      ? getPushNotification.map((item, index) => {
                          return (
                            <>
                              <div className="noti-list" key={index}>
                                <figure key={index}>
                                  <img src="images/logo1.png" />
                                </figure>
                                <div>
                                  <h3>Coach Notification For All Students</h3>
                                  {item?.coach_id?.coach_name}
                                  <div>
                                    <span
                                      style={{
                                        fontSize: "20px",
                                        color: "black",
                                      }}
                                    >
                                      Coach Title :{" "}
                                    </span>
                                    <span
                                      style={{
                                        fontSize: "20px",
                                        color: "blue",
                                      }}
                                    >
                                      {item?.title}
                                    </span>
                                  </div>
                                  <div>
                                    <span
                                      style={{
                                        fontSize: "20px",
                                        color: "black",
                                      }}
                                    >
                                      Coach Message :{" "}
                                    </span>
                                    <span
                                      style={{
                                        fontSize: "20px",
                                        color: "blue",
                                      }}
                                    >
                                      {item?.message}
                                    </span>
                                  </div>
                                  <div>
                                    <span
                                      style={{
                                        fontSize: "20px",
                                        color: "black",
                                      }}
                                    >
                                      Coach notification send date :{" "}
                                    </span>
                                    <span
                                      style={{
                                        fontSize: "20px",
                                        color: "blue",
                                      }}
                                    >
                                      {moment(item?.created_at).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </span>
                                  </div>
                                  <div>
                                    <span
                                      style={{
                                        fontSize: "20px",
                                        color: "black",
                                      }}
                                    >
                                      Image :{" "}
                                    </span>
                                    <a
                                      style={{
                                        color: "blue",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        console.log("==click===>", item);
                                        setData(item);
                                        setModalShow(true);
                                      }}
                                    >
                                      {" "}
                                      Click to see
                                    </a>
                                    {/* <img style={{
                                                   border: "1px solid #ddd",
                                                   borderRadius: "4px",
                                                   padding: "5px",
                                                   width: "305px",
                                                   height:"233px",
                                                }} src={item?.push_image} /> */}
                                  </div>

                                  <div className="row">
                                    {/* <p>Date Of Booking : {moment.utc(item?.select_date).format('DD/MM/YYYY')} <span style={{ color: "blue" }}>(dd/mm/yyyy)</span></p> */}
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })
                      : ""}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <Modal
        show={getReschModal}
        onHide={() => setReschModal(false)}
        size="lg"
        top
      >
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ fontWeight: "bold", fontSize: "20px" }}>
            You cannot reschedule this appointment as it is less than 24 hours
            away.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setReschModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={getAttemptModal}
        onHide={() => setAttemptModal(false)}
        size="lg"
        top
      >
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ fontWeight: "bold", fontSize: "20px" }}>
            You have already rescheduled the date of your session once this
            month{" "}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setAttemptModal(false)}>Close</Button>
          <Button onClick={() => handelContinue()}>Next</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={getReasonModal}
        onHide={() => setReasonModal(false)}
        size="lg"
        top
      >
        <Modal.Header closeButton>
          <Modal.Title>Action</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ fontWeight: "bold", fontSize: "20px" }}>
            Please select a reason to reschedule
          </p>
          <select
            value={getSelectedReason}
            onChange={(e) => setSelectedReason(e.target.value)}
          >
            <option value="">Select reason</option>

            <option value={"sickness"}>Sickness</option>
            <option value={"family responsibility"}>
              Family responsibility
            </option>
            <option value={"power cut"}>Power cut</option>
          </select>
          {getErrorMessage && <p style={{ color: "red" }}>{getErrorMessage}</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setReasonModal(false)}>Close</Button>
          <Button onClick={() => handelReason()}>Continue</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
