import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAllCoach, getCourses } from "../services/web/webServices";
import Footer from "./Footer";
import InsideHeader from "./InsideHeader";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Header from "./Header";

export default function MultipleCoaches() {
  const [getCoachDetail, setCoachDetail] = useState([]);
  const [getLoader, setLoader] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCoaches, setFilteredCoaches] = useState([]);

  const [subjects, setSubjects] = useState([]);
  const [language, setLanguages] = useState([]);
  const [levels, setLevels] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("");
  const [allPopularCourse, setAllPopularCourse] = useState([]);

  useEffect(() => {
    // Fetch coaches
    getAllCoach()
      .then((res) => {
        const coaches = res.data.response;
        setCoachDetail(coaches);
        setFilteredCoaches(coaches);

        // Logging the data to ensure it’s correct
        console.log("Coaches:", coaches);

        const uniqueSubjects = [
          ...new Set(coaches.flatMap((coach) => coach.subject_of_teacher)),
        ];
        const uniqueLanguages = [
          ...new Set(coaches.map((coach) => coach.language)),
        ];
        const uniqueLevels = [
          ...new Set(coaches.map((coach) => coach.level_of_coaching)),
        ];

        setSubjects(uniqueSubjects);
        setLanguages(uniqueLanguages);
        setLevels(uniqueLevels);

        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  }, []);

  useEffect(() => {
    // Fetch popular courses
    getCourses()
      .then((res) => {
        setAllPopularCourse(res.data.response);
        console.log(res);
        console.log("------get popular courses", res.data.response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    let filtered = getCoachDetail.filter((coach) =>
      coach.coach_name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    if (selectedSubject) {
      filtered = filtered.filter((coach) =>
        coach.subject_of_teacher.includes(selectedSubject)
      );
    }

    if (selectedLanguage) {
      filtered = filtered.filter(
        (coach) => coach.language.trim() === selectedLanguage.trim()
      );
    }

    if (selectedLevel) {
      filtered = filtered.filter(
        (coach) => coach.level_of_coaching.trim() === selectedLevel.trim()
      );
    }

    setFilteredCoaches(filtered);
  }, [
    searchQuery,
    selectedSubject,
    selectedLanguage,
    selectedLevel,
    getCoachDetail,
  ]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleFilterChange = (setter) => (e) => {
    setter(e.target.value);
  };

  return (
    <>
      <Header />

      <div
        className="section page-banner-section bg-color-1"
        style={{ marginTop: "29px" }}
      >
        <div className="container">
          <div className="page-banner-content">
            <h2 className="title">Academic Coaches</h2>
            <ul className="breadcrumb justify-content-center">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active">Academic Coaches</li>
            </ul>
          </div>
        </div>
      </div>

      {getLoader ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={getLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <div className="container-fluid">
            <div className="categoryInsidePage1">
              <div className="search-result">
                <div
                  className="search-bar"
                  style={{ textAlign: "center", padding: "10px" }}
                >
                  <h1 style={{ fontSize: "24px", marginLeft: "4px" }}>
                    Search Coaches
                  </h1>
                  <div
                    className="search-input"
                    style={{ display: "inline-block", position: "relative" }}
                  >
                    <input
                      type="text"
                      placeholder="Search by name or surname"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      style={{
                        width: "220px",
                        padding: "5px",
                        borderRadius: "5px",
                        paddingRight: "30px",
                      }}
                    />
                    <i
                      className="fas fa-search"
                      style={{
                        position: "absolute",
                        right: "8px",
                        top: "53%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                    ></i>
                  </div>
                </div>
                <div
                  className="filters"
                  style={{ textAlign: "center", padding: "10px" }}
                >
                  <select
                    value={selectedSubject}
                    onChange={handleFilterChange(setSelectedSubject)}
                    style={{
                      width: "220px",
                      padding: "5px",
                      borderRadius: "5px",
                      margin: "5px 0",
                    }}
                  >
                    <option value="">Select Subject</option>
                    {subjects.map((subject, index) => (
                      <option
                        key={index}
                        value={subject}
                        style={{ margin: "0", padding: "0" }}
                      >
                        {subject}
                      </option>
                    ))}
                  </select>

                  {/* Uncomment and style the other select elements similarly */}
                  {/* 
                  <select
                    value={selectedLanguage}
                    onChange={handleFilterChange(setSelectedLanguage)}
                    style={{ width: '200px', padding: '5px', borderRadius: '5px', margin: '5px 0' }}
                  >
                    <option value="">Select Language</option>
                    {language.map((language, index) => (
                      <option key={index} value={language}>{language}</option>
                    ))}
                  </select>

                  <select
                    value={selectedLevel}
                    onChange={handleFilterChange(setSelectedLevel)}
                    style={{ width: '200px', padding: '5px', borderRadius: '5px', margin: '5px 0' }}
                  >
                    <option value="">Select Level</option>
                    {levels.map((level, index) => (
                      <option key={index} value={level}>{level}</option>
                    ))}
                  </select>
                  */}
                </div>
                <div
                  className="Academic-coaches"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <a style={{ fontSize: "1.5rem" }}>Academic Coaches: </a>
                  <span
                    style={{
                      marginLeft: "15px",
                      color: "blue",
                      fontSize: "1.5rem",
                    }}
                  >
                    {filteredCoaches.length}
                  </span>
                  {/* Filter Controls */}
                </div>
              </div>
              <div className="allcoach">
                {filteredCoaches.length > 0
                  ? filteredCoaches.map((item, index) => (
                      <div className="qwerty" key={index}>
                        <div
                          className="individual-course wow fadeInUp"
                          data-wow-duration="1s"
                          data-wow-delay=".2s"
                          style={{
                            visibility: "visible",
                            animationDuration: "1s",
                            animationDelay: "0.2s",
                          }}
                        >
                          <figure
                            style={{
                              maxHeight: "100%",
                              width: "100%",

                              overflow: "hidden",
                            }}
                          >
                            <Link to={`/coachprofile/${item._id}`}>
                              {item?.coach_image === "" ? (
                                <img
                                  src="images/profile.png"
                                  style={{ objectFit: "contain" }}
                                />
                              ) : (
                                <img src={item?.coach_image} />
                              )}
                            </Link>
                          </figure>
                          <div className="individualCourseContent">
                            <div className="coach-cont">
                              <div>
                                <h4 className="coach-name">
                                  <Link
                                    to={`/coachprofile/${item._id}`}
                                    style={{
                                      color: "#121617",
                                      fontSize: "25px",
                                    }}
                                    title={item?.coach_name}
                                  >
                                    {item?.coach_name}
                                  </Link>
                                </h4>
                              </div>
                            </div>
                            <h5>
                              <Link
                                to={`/coachprofile/${item._id}`}
                                style={{
                                  color: "#909ca5",
                                  inlineSize: "300px",
                                  overflowWrap: "break-word",
                                }}
                              >
                                {item?.short_bio?.slice(0, 30)}..
                              </Link>
                            </h5>
                            <div className="indiv-view-section">
                              <div>
                                <i
                                  className="fas fa-book"
                                  style={{ color: "blue" }}
                                ></i>{" "}
                                {item?.subject_of_teacher?.slice(0, 2)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  : "Not Found"}
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
}
