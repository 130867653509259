import React, { useEffect, useState, useRef  } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  getCourses,
  filtered_language,
  get_filter_data,
} from '../services/web/webServices';
import Footer from './Footer';
import InsideHeader from './InsideHeader';
import { Loader } from '../helper/Loader';
import { Formik, Form } from 'formik';
import { Button } from 'react-bootstrap';
import Header from './Header';


export default function SearchFilterCategory() {
  const searchResultRef = useRef(null);
  const handleApplyFilter = () => {
    if (searchResultRef.current) {
      searchResultRef.current.scrollIntoView({
        behavior: 'smooth', // You can change this to 'auto' for an instant scroll
      });
    }
    // Additional logic for applying the filter if needed
  };
  
  const [studentCourse, setStudentCourse] = useState([]);
  const [getLevel, setLevel] = useState([
    'Foundation',
    'Intermediate',
    'Senior Phase',
    'Fet Phase',
    'University',
  ]);
  const [getPrice, setPrice] = useState([
    'Under R999',
    'Under R1500',
    'Under R2500',
    'Above R3500',
  ]);
  const [getLoader, setLoader] = useState(true);
  const [getLanguage, setLanguage] = useState(null);
  const { state } = useLocation();
  const { search_data } = state || [];
  const { popular_topic } = state || [];

  // console.log("What found ====>",state);

  let [getData, setData] = useState(null);
  const [getStateLevel, setStateLevel] = useState([]);
  const [getStatePrice, setStatePrice] = useState([]);
  const [getStateLanguage, setStateLanguage] = useState([]);
  const [getFilteredData, setFilteredData] = useState(null);
  const [getButton, setButton] = useState(false);
  const [level, setlevel] = useState(true);
  const [language, setlanguage] = useState(true);
  const [price, setprice] = useState(true);

  const getApi = () => {
    //    start
    // localStorage.setItem('Click', "0");
    getCourses()
      .then((res) => {
        setLoader(false);
        setStudentCourse(res.data.response);
        // console.log('res====>',res.data.response);
      })
      .catch((err) => {
        if (err) {
          // console.log(err);
        }
      });
    filtered_language()
      .then((res) => {
        setLanguage(res?.data.response);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  useEffect(() => {
    getApi();
  }, []);
  return (
    <>
      <Header />
      <div
        className='section page-banner-section bg-color-1'
        style={{ marginTop: '29px' }}
      >
        <div className='container'>
          {/* <!-- Page Banner Content Start --> */}
          <div className='page-banner-content'>
            <h2 className='title'>All Courses</h2>
            <ul className='breadcrumb justify-content-center'>
              <li className='breadcrumb-item'>
                <Link to='/'>Home</Link>
              </li>
              <li className='breadcrumb-item active'>All Courses</li>
            </ul>
          </div>
          {/* <!-- Page Banner Content End --> */}
        </div>
      </div>
      <div className='container-fluid filter-container'>
        <div className='filter-section'>
          <Formik
            initialValues={{
              level: [],
              language: [],
              price: [],
            }}
            onSubmit={(values) => {
              setButton(true);
              values.level = getStateLevel || [];
              values.language = getStateLanguage || [];
              values.price = getStatePrice || [];

              if (
                getPrice?.length > 0 ||
                getStateLanguage?.length > 0 ||
                getStateLevel?.length > 0
              ) {
                get_filter_data(values)
                  .then((res) => {
                    localStorage.setItem('Click', '0');
                    setButton(false);
                    setFilteredData(res.data.response);
                    if (res.data.response?.length == 0) {
                      alert('No course found :(');
                    }
                  })
                  .catch((err) => {
                    setButton(false);
                    // console.log(err);
                  });
              } else {
                alert('please select atleast one filter :)');
              }
            }}
          >
            <Form>
              {/* <div className="cate-search">
                                <input type="search" placeholder="Type here to search" className="" />
                            </div> */}
              <div className='sidebar-widget-02 pad-20'>
                <h3 className='widget-title'>Level</h3>
                <div className='widget-checkbox'>
                  <ul className='checkbox-list'>
                    {(getLevel || []).map((item, index) => {
                      return (
                        <>
                          <li className='form-check'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              style={{ cursor: 'pointer' }}
                              onChange={(e) => {
                                if (e.target.checked == true) {
                                  setStateLevel((getStateLevel) => [
                                    ...getStateLevel,
                                    item,
                                  ]);
                                  setlevel(true);
                                }
                                if (e.target.checked == false) {
                                  let Index = getStateLevel.findIndex(
                                    (q) => q == item
                                  );
                                  if (getStateLevel?.length == 1) {
                                    setlevel(false);
                                  }
                                  getStateLevel.splice(Index, 1);
                                  // setStateLevel(data);
                                }
                              }}
                            />
                            <label className='form-check-label'>{item}</label>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </div>
              </div>

              <div className='sidebar-widget-02 pad-20'>
                <h3 className='widget-title'>Language</h3>
                <div className='widget-checkbox'>
                  <ul className='checkbox-list'>
                    {(getLanguage?.[0]?.filter_language || []).map((q) => {
                      return (
                        <>
                          <li className='form-check'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              style={{ cursor: 'pointer' }}
                              onChange={(e) => {
                                if (e.target.checked == true) {
                                  setStateLanguage((getStateLanguage) => [
                                    ...getStateLanguage,
                                    q,
                                  ]);
                                  setlanguage(true);
                                }
                                if (e.target.checked == false) {
                                  let Index = getStateLanguage.findIndex(
                                    (l) => l == q
                                  );
                                  if (getStateLanguage?.length == 1) {
                                    setlanguage(false);
                                  }
                                  getStateLanguage.splice(Index, 1);
                                }
                              }}
                            />
                            <label className='form-check-label'>{q}</label>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </div>
              </div>

              <div className='sidebar-widget-02 pad-20'>
                <h3 className='widget-title'>Price</h3>
                <div className='widget-checkbox'>
                  <ul className='checkbox-list'>
                    {(getPrice || []).map((item, index) => {
                      return (
                        <>
                          <li className='form-check'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              style={{ cursor: 'pointer' }}
                              onChange={(e) => {
                                if (e.target.checked == true) {
                                  setprice(true);
                                  setStatePrice((getStatePrice) => [
                                    ...getStatePrice,
                                    item,
                                  ]);
                                }
                                if (e.target.checked == false) {
                                  let Index = getStatePrice.findIndex(
                                    (q) => q == item
                                  );

                                  if (getStatePrice?.length == 1) {
                                    setprice(false);
                                  }
                                  getStatePrice.splice(Index, 1);
                                }
                              }}
                            />
                            <label className='form-check-label'>{item}</label>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </div>
              </div>
              {getStatePrice?.length > 0 ||
              getStateLanguage?.length > 0 ||
              getStateLevel?.length > 0 ? (
                getButton == false ? (
                  <Button
                    type='submit'
                    style={{ marginLeft: '20px', marginTop: '20px' }}
                    onClick={handleApplyFilter}
                  >
                    Apply Filter
                  </Button>
                ) : (
                  <Button
                    type='button'
                    style={{ marginLeft: '20px', marginTop: '20px' }}
                  >
                    wait please!! :)
                  </Button>
                )
              ) : (
                ''
              )}
            </Form>
          </Formik>
        </div>
        <div className=' categoryInsidePage1'>
          <div ref={searchResultRef} className='search-result'>
            {search_data?.length > 0 &&
            localStorage?.getItem('Click') == '1' ? (
              <span>Search Result:{search_data?.length}</span>
            ) : (
              ''
            )}
            {getFilteredData?.length > 0 &&
            localStorage?.getItem('Click') == '0' ? (
              <span>Search Result:{getFilteredData?.length}</span>
            ) : (
              ''
            )}
            {popular_topic?.course?.length > 0 &&
            !getFilteredData?.length > 0 &&
            localStorage?.getItem('Click') == '0' ? (
              <span>Search Result:{popular_topic?.course?.length}</span>
            ) : (
              ''
            )}
            {studentCourse?.length > 0 &&
            !popular_topic?.course?.length > 0 &&
            !getFilteredData?.length > 0 &&
            localStorage?.getItem('Click') == '0' ? (
              <span>Search Result:{studentCourse?.length}</span>
            ) : (
              ''
            )}
          </div>
          <div className='four-grid categoryInsidePage'>
            {/* <!-- Single Courses Start --> */}
            {getLoader === true ? (
              <Loader />
            ) : (
              <>
                {search_data?.length > 0 &&
                localStorage?.getItem('Click') == '1'
                  ? search_data.map((i, index) => {
                      return (
                        <>
                          <div
                            className='individual-course  wow fadeInUp'
                            data-wow-duration='1s'
                            data-wow-delay='.2s'
                            style={{
                              visibility: 'visible',
                              animationDuration: '1s',
                              animationDelay: '0.2s',
                            }}
                            key={index}
                          >
                            <figure>
                              <Link to={`/coursedetail/${i._id}`}>
                                <img src={i?.course_images} />
                              </Link>
                            </figure>
                            <div className='individualCourseContent'>
                              <div className='coach-cont'>
                                <div>
                                  <img src={i.coach[0]?.coach_image} />
                                  <h4>
                                    <Link
                                      to={`/coachprofile/${i.coach[0]?._id}`}
                                    >
                                      {i?.coach[0]?.coach_name}
                                    </Link>
                                  </h4>
                                </div>
                              </div>
                              <h5>
                                <Link to={`/coursedetail/${i._id}`}>
                                  {i?.course_title}
                                </Link>
                              </h5>
                              <div className='indiv-view-section'>
                                <div>
                                  <i className='fas fa-eye'></i>{' '}
                                  {i?.review?.length}+
                                </div>
                                <div>
                                  <i className='fas fa-book'></i>{' '}
                                  {i?.videos?.length}, Lesson
                                </div>
                                <div>
                                  <i className='fas fa-comments'></i>{' '}
                                  {i?.comment}+
                                </div>
                                <div>
                                  <i className='fas fa-star'></i>
                                  {i?.rating > 0 ? i?.rating : '0'}/5
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })
                  : ''}
                {getFilteredData?.length > 0 &&
                localStorage?.getItem('Click') == '0'
                  ? getFilteredData.map((i, index) => {
                      return (
                        <>
                          <div
                            className='individual-course  wow fadeInUp'
                            data-wow-duration='1s'
                            data-wow-delay='.2s'
                            style={{
                              visibility: 'visible',
                              animationDuration: '1s',
                              animationDelay: '0.2s',
                            }}
                            key={index}
                          >
                            <figure>
                              <Link to={`/coursedetail/${i._id}`}>
                                <img src={i?.course_images} />
                              </Link>
                            </figure>
                            <div className='individualCourseContent'>
                              <div className='coach-cont'>
                                <div>
                                  <img src={i.coach[0]?.coach_image} />
                                  <h4>
                                    <Link
                                      to={`/coachprofile/${i.coach[0]?._id}`}
                                    >
                                      {i?.coach[0]?.coach_name}
                                    </Link>
                                  </h4>
                                </div>
                              </div>
                              <h5>
                                <Link to={`/coursedetail/${i._id}`}>
                                  {i?.course_title}
                                </Link>
                              </h5>
                              <div className='indiv-view-section'>
                                <div>
                                  <i className='fas fa-eye'></i>{' '}
                                  {i?.review?.length}+
                                </div>
                                <div>
                                  <i className='fas fa-book'></i>{' '}
                                  {i?.videos?.length}, Lesson
                                </div>
                                <div>
                                  <i className='fas fa-comments'></i>{' '}
                                  {i?.comment}+
                                </div>
                                <div>
                                  <i className='fas fa-star'></i>
                                  {i?.rating > 0 ? i?.rating : '0'}/5
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })
                  : ''}

                {/* Popular Topic  */}
                {popular_topic?.course?.length > 0 &&
                !getFilteredData?.length > 0 &&
                localStorage?.getItem('Click') == '0'
                  ? popular_topic?.course?.map((i, index) => {
                      return (
                        <>
                          <div
                            className='individual-course  wow fadeInUp'
                            data-wow-duration='1s'
                            data-wow-delay='.2s'
                            style={{
                              visibility: 'visible',
                              animationDuration: '1s',
                              animationDelay: '0.2s',
                            }}
                            key={index}
                          >
                            <figure>
                              <Link to={`/coursedetail/${i._id}`}>
                                <img src={i?.course_images} />
                              </Link>
                            </figure>
                            <div className='individualCourseContent'>
                              <div className='coach-cont'>
                                <div>
                                  <img src={i.coach?.coach_image} />
                                  <h4>
                                    <Link to={`/coachprofile/${i.coach?._id}`}>
                                      {i?.coach?.coach_name}
                                    </Link>
                                  </h4>
                                </div>
                              </div>
                              <h5>
                                <Link to={`/coursedetail/${i._id}`}>
                                  {i?.course_title}
                                </Link>
                              </h5>
                              <div className='indiv-view-section'>
                                <div>
                                  <i className='fas fa-eye'></i>{' '}
                                  {i?.review?.length}+
                                </div>
                                <div>
                                  <i className='fas fa-book'></i>{' '}
                                  {i?.videos?.length}, Lesson
                                </div>
                                <div>
                                  <i className='fas fa-comments'></i>{' '}
                                  {i?.comment}+
                                </div>
                                <div>
                                  <i className='fas fa-star'></i>
                                  {i?.rating > 0 ? i?.rating : '0'}/5
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })
                  : ''}
                {/* Ends */}

                {studentCourse?.length > 0 &&
                !popular_topic?.course?.length &&
                !getFilteredData?.length > 0 &&
                localStorage?.getItem('Click') == '0'
                  ? studentCourse.map((i, index) => {
                      return (
                        <div
                          className='individual-course  wow fadeInUp'
                          data-wow-duration='1s'
                          data-wow-delay='.2s'
                          style={{
                            visibility: 'visible',
                            animationDuration: '1s',
                            animationDelay: '0.2s',
                          }}
                          key={index}
                        >
                          <figure>
                            <Link to={`/coursedetail/${i._id}`}>
                              <img src={i?.course_images} />
                            </Link>
                          </figure>
                          <div className='individualCourseContent'>
                            <div className='coach-cont'>
                              <div>
                                <img src={i.coach?.coach_image} />
                                <h4>
                                  <Link to={`/coachprofile/${i.coach?._id}`}>
                                    {i?.coach?.coach_name}
                                  </Link>
                                </h4>
                              </div>
                            </div>
                            <h5>
                              <Link to={`/coursedetail/${i._id}`}>
                                {i.course_title}
                              </Link>
                            </h5>
                            <div className='indiv-view-section'>
                              <div>
                                <i className='fas fa-eye'></i>{' '}
                                {i?.review?.length}+
                              </div>
                              <div>
                                <i className='fas fa-book'></i>{' '}
                                {i?.videos?.length}, Lesson
                              </div>
                              <div>
                                <i className='fas fa-comments'></i> {i?.comment}
                                +
                              </div>
                              <div>
                                <i className='fas fa-star'></i>
                                {i?.rating > 0 ? i?.rating : '0'}/5
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : ''}
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
