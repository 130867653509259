import React, { useEffect, useState } from "react";
import Header from "./Header";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Store } from "react-notifications-component";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import moment from "moment";
import Calendar from "react-calendar/dist/umd/Calendar";
import Footer from "./Footer";
import Modal from "react-bootstrap/Modal";
import base_uri from "../api/base_url";
import {
  getCoachTimeSlot,
  profile,
  rescheduleBookedSession,
  getCoachTimeSlotv2,
} from "../services/web/webServices";

export default function Rescheduling() {
  const [getExceededHours, setExceededHours] = useState(false);
  const [timeSlotId, setTimeSlotId] = useState(null);
  const [getTimeSlot, setTimeSlot] = useState([]);
  const [getClass, setClass] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [getPrice, setPrice] = useState(0);
  const [getValue, setValue] = useState([]);
  const [classBookingType, setClassBookingType] = useState("");
  const [getLoader, setLoader] = useState(true);
  const [getLoader1, setLoader1] = useState(true);
  const [getStudyDetail, setStudyDetail] = useState(null);
  const [getOnlineDetail, setOnlineDetail] = useState(null);
  const navigate = useNavigate();
  const [subjectList, setSubjectList] = useState(null);
  let [getDetail, setDetail] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [getSelectedSlot, setSelectedSlot] = useState(null);
  const [getNewSelectedDate, setNewSelectedDate] = useState();
  const [getTimeValue, setTimeValue] = useState([]);
  const [getModal, setModal] = useState();
  let newDuration = 0;
  const bookedInfo = JSON.parse(localStorage.getItem("itemData"));
  const rescheduleReason = JSON.parse(localStorage.getItem("rescheduleReason"));

  const bookedData = bookedInfo.slot_time;
  const param = useParams();
  //  localStorage.removeItem('itemData');
  const minDate = moment(bookedInfo.select_date).startOf("month").toDate();
  const maxDate = moment(bookedInfo.select_date).endOf("month").toDate();
  const convertTimeSlotToDate = (timeSlot) => {
    const [hours, minutes] = timeSlot.split(":");
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  };

  const checkConsecutiveSlots = (newSelectedSlots) => {
    let sessionCount = 0;
    if (
      newSelectedSlots.length > 0 &&
      newSelectedSlots.length == bookedData.length
    ) {
      if (newSelectedSlots.length > 1) {
        for (
          let slotIndex = 0;
          slotIndex < newSelectedSlots.length;
          slotIndex++
        ) {
          let startTime1;
          let startTime2;
          if (newSelectedSlots[slotIndex + 1] != null) {
            startTime1 = convertTimeSlotToDate(
              newSelectedSlots[slotIndex].time_slot
            );
            startTime2 = convertTimeSlotToDate(
              newSelectedSlots[slotIndex + 1].time_slot
            );
          }

          const differenceInMilliseconds = Math.abs(startTime2 - startTime1);
          const differenceInMinutes = differenceInMilliseconds / (1000 * 60);

          if (differenceInMinutes == 60) {
            sessionCount++;
          }
          if (sessionCount == newSelectedSlots.length - 1) {
            return true;
          }
        }
      } else {
        return true;
      }
    } else {
      setModal(true);
      return false;
    }
  };

  const rescheduleTimeSlot = async (e) => {
    let isChecked = checkConsecutiveSlots(getTimeValue);
    if (isChecked) {
      rescheduleBookedSession(e);
      navigate("/");
    }

    return;
  };
  const getApi = (class_type) => {
    setLoader1(true);
    getCoachTimeSlotv2(param.id).then((res) => {
      if (res.data.response.status == 0) {
        Store.addNotification({
          title: "Error!",
          message: res?.data?.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
        localStorage.removeItem("auth_token");
        localStorage.removeItem("state");
        navigate("/login");
      }
      setLoader(false);
      setLoader1(false);
      setTimeSlot(res.data.response);
      const subjects = getTimeSlot
        .flatMap((entry) => entry.slot.map((slot) => slot.subject))
        .filter((subject) => subject);
      const uniqueSubjects = [
        ...new Set(
          getTimeSlot
            .flatMap((entry) => entry.slot.map((slot) => slot.subject))
            .filter((subject) => subject)
        ),
      ];

      setSubjectList([
        ...new Set(
          res?.data?.response
            .flatMap((entry) => entry.slot.map((slot) => slot.subject))
            .filter((subject) => subject)
        ),
      ]);
    });
  };

  const getDateFunc = (date) => {
    let exists = false;
    if (date) {
      for (let i of getTimeSlot) {
        if (
          moment(i.select_date)?.format("YYYY-MM-DD") ==
          moment(date)?.format("YYYY-MM-DD")
        ) {
          setSelectedSlot(i);
          // console.log("----------i", i);
          exists = true;
        }
      }
      if (!exists) {
        setSelectedSlot(null);
      }
    }
  };

  // getApi("online");
  useEffect(() => {
    getCoachTimeSlotv2(param.id).then((res) => {
      // console.log("respond ", res);
      if (res.data.response.status == 0) {
        Store.addNotification({
          title: "Error!",
          message: res?.data?.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
        localStorage.removeItem("auth_token");
        localStorage.removeItem("state");
        navigate("/login");
      }
      setLoader1(false);
      setTimeSlot(res.data.response);
      // console.log(getTimeSlot);
      checkConsecutiveSlots("");
      const subjects = getTimeSlot
        .flatMap((index) => index.slot.map((slot) => slot.subject))
        .filter((subject) => subject);

      setSubjectList([
        ...new Set(
          res?.data?.response
            .flatMap((entry) => entry.slot.map((slot) => slot.subject))
            .filter((subject) => subject)
        ),
      ]);

      const uniqueSubjects = [...new Set(subjects)];

      profile().then((res) => {
        setUserDetails(res.data.response);
      });
    });
  }, [getCoachTimeSlotv2]);

  const css = `
    .content {
        font-size: 20px;
        color: blue;
      }

    `;

  return (
    <>
      <style>{css}</style>
      <Header />
      <div className="container pt-120">
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <i className="fas fa-chevron-right"></i>
          <li className="breadcrumb-item active">Course</li>
          <i className="fas fa-chevron-right"></i>
          <li className="breadcrumb-item active">Coach-Detail</li>
        </ul>
        <div className="book-caoch-top">
          <div className="book-coach-top-left">
            <div className="book-coach-top-left-title">
              <div
                className="coach-photo wow fadeInUp"
                data-wow-duration=".5s"
                data-wow-delay=".2s"
                style={{
                  visibility: "visible",
                  animationDuration: "1s",
                  animationDelay: " 0.4s",
                }}
              >
                <img src={bookedInfo.coach_id.coach_image} />
                <h3>
                  <Link to={`/coachprofile/${bookedInfo.coach_id}`}>
                    {bookedInfo.coach_id.coach_name}
                  </Link>
                </h3>
              </div>
              <div
                className=" wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
                style={{
                  visibility: "visible",
                  animationDuration: "1s",
                  animationDelay: " 0.4s",
                }}
              >
                <h2 className="course-title">
                  {bookedInfo.coach_id.coach_name}
                </h2>
                <p className="course-top-para">Enrolled Student</p>
                <h5>
                  <span>Book your multiple slots</span>
                </h5>
                <div
                  className="pricing-wrapper start-course-desc wow fadeInUp"
                  data-wow-duration="1.5s"
                  data-wow-delay=".2s"
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: " 0.4s",
                  }}
                >
                  <div className="tab-content book-coacnew-top">
                    <div className="tab-pane fade show active" id="monthly">
                      <div
                        className="select-date-container wow fadeInUp"
                        data-wow-duration="1s"
                        data-wow-delay=".2s"
                        style={{
                          visibility: "visible",
                          animationDuration: "1s",
                          animationDelay: " 0.4s",
                        }}
                      >
                        {/* {(props) => ( */}
                        <Formik
                          initialValues={{
                            coach_id: "",
                            user_id: "",
                            current_slot_time: "",
                            new_slot_time: "",
                            new_selected_date: "",
                            reason: "",
                          }}
                          validationSchema={Yup.object({})}
                          onSubmit={(values, { resetForm }) => {
                            values.new_slot_time = getValue;
                            values.coach_id = param.id;
                            values.new_selected_date =
                              moment(getNewSelectedDate)?.format("YYYY-MM-DD");
                            values.current_slot_time = bookedData.map(
                              (slot) => slot._id
                            );
                            values.user_id = userDetails._id;
                            values.reason = rescheduleReason;
                            rescheduleTimeSlot(values);
                          }}
                        >
                          <Form>
                            <div className="select-className-book-coach">
                              <div className="form-group">
                                <label>Selected Grade</label>
                                <select name="grade">
                                  <option value="0">
                                    {bookedData[0].class}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="select-className-book-coach">
                              <div className="form-group">
                                <label>Selected Subject</label>
                                <select name="subject">
                                  <option value="0">
                                    {bookedData[0].subject}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="select-className-book-coach">
                              <div className="form-group">
                                <label>Type Of Class</label>
                                <select name="booked_for">
                                  <option value="0">
                                    {bookedData[0].booked_for}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="form-group">
                              <p>
                                Current Date:{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {"  "}
                                  {moment(bookedInfo.select_date).format(
                                    "DD MMMM YYYY"
                                  )}
                                </span>
                              </p>
                              <br></br>
                              <label>Select new Date:</label>

                              <Calendar
                                name="select_date"
                                tileClassName={({ date, view }) => {
                                  if (
                                    getTimeSlot.find(
                                      (x) =>
                                        moment(x?.select_date)?.format(
                                          "YYYY-MM-DD"
                                        ) ==
                                          moment(date).format("YYYY-MM-DD") &&
                                        x.slot.find((q) => !q.booked)
                                    )
                                  ) {
                                    return "content";
                                  }
                                }}
                                minDate={minDate}
                                maxDate={maxDate}
                                onChange={(e) => {
                                  getDateFunc(e);
                                  setNewSelectedDate(e);
                                }}
                              />
                            </div>

                            <div className="form-group">
                              <label>Current time slot:</label>

                              <div className="time-container">
                                {bookedData.map((slot, index) => (
                                  <div className="seperate-time" key={index}>
                                    <input type="checkbox" />

                                    <span
                                      style={{
                                        backgroundColor: "red",
                                        color: "white",
                                      }}
                                    >
                                      {slot.booked
                                        ? slot.time_slot
                                        : slot.time_slot}{" "}
                                      - {slot.end_time}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            </div>
                            {getSelectedSlot ? (
                              <>
                                {getSelectedSlot ? (
                                  <div className="form-group">
                                    {getSelectedSlot?.slot?.find(
                                      (q) => q.booked == false
                                    ) ? (
                                      <label>Select time slot</label>
                                    ) : (
                                      ""
                                    )}
                                    <div className="time-container">
                                      {getSelectedSlot?.slot?.length > 0
                                        ? getSelectedSlot?.slot
                                            ?.filter((q) => q.booked == false)
                                            .map((item, index) => {
                                              return (
                                                <>
                                                  <div
                                                    className="seperate-time"
                                                    key={index}
                                                  >
                                                    <input
                                                      type="checkbox"
                                                      checked={getValue?.find(
                                                        (q) => q == item?._id
                                                      )}
                                                      onChange={(e) => {
                                                        let existsIndex =
                                                          getValue.findIndex(
                                                            (v) =>
                                                              v == item?._id
                                                          );
                                                        let existsIndex1 =
                                                          getValue.findIndex(
                                                            (v) =>
                                                              v == item?._id
                                                          );

                                                        if (existsIndex < 0) {
                                                          let dataIndex =
                                                            getSelectedSlot.slot.findIndex(
                                                              (q) =>
                                                                q._id ==
                                                                item?._id
                                                            );
                                                          let dataIndex1 =
                                                            getSelectedSlot.slot.findIndex(
                                                              (q) =>
                                                                q._id ==
                                                                item?._id
                                                            );
                                                          if (
                                                            getValue.length <=
                                                            bookedInfo.slot_time
                                                              .length
                                                          ) {
                                                            setValue([
                                                              ...getValue,
                                                              getSelectedSlot
                                                                ?.slot[
                                                                dataIndex
                                                              ]?._id,
                                                            ]);
                                                            setTimeValue([
                                                              ...getTimeValue,
                                                              getSelectedSlot
                                                                ?.slot[
                                                                dataIndex1
                                                              ],
                                                            ]);
                                                          } else {
                                                          }
                                                        } else {
                                                          getValue.splice(
                                                            existsIndex,
                                                            1
                                                          );
                                                          getTimeValue.splice(
                                                            existsIndex1,
                                                            1
                                                          );
                                                          setValue([
                                                            ...getValue,
                                                          ]);
                                                          setTimeValue([
                                                            ...getTimeValue,
                                                          ]);
                                                        }
                                                      }}
                                                    />
                                                    <span>
                                                      {item?.booked == true ? (
                                                        <span
                                                          style={{
                                                            backgroundColor:
                                                              "red",
                                                            color: "white",
                                                          }}
                                                        >
                                                          {item.time_slot}
                                                        </span>
                                                      ) : (
                                                        item.time_slot +
                                                        " - " +
                                                        item.end_time
                                                      )}
                                                    </span>
                                                  </div>
                                                </>
                                              );
                                            })
                                        : ""}
                                    </div>
                                  </div>
                                ) : (
                                  <h4>No Slot Found :(</h4>
                                  // ''
                                )}
                              </>
                            ) : (
                              ""
                            )}

                            <button
                              className="btn btn-primary btn-hover-heading-color cmn-btn"
                              type="submit"
                            >
                              Submit
                            </button>
                          </Form>
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Modal show={getModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Alert</Modal.Title>
                </Modal.Header>
                {bookedData.length > 1 ? (
                  <>
                    <Modal.Body>
                      <p>
                        Since you have booked {bookedData.length} hours
                        consecutive slots. Please note that you will only be
                        allowed to reschedule your slots in those consecutive
                        hours.
                      </p>
                    </Modal.Body>
                  </>
                ) : (
                  <>
                    <Modal.Body>
                      <p>
                        Since you have booked {bookedData.length} hour slots.
                        Please note that you will only be allowed to reschedule
                        an hour slot.
                      </p>
                    </Modal.Body>
                  </>
                )}

                <Modal.Footer>
                  <button
                    onClick={() => setModal(false)}
                    className="btn btn-primary btn-hover-heading-color cmn-btn"
                  >
                    OK
                  </button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
