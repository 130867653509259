import { React, useEffect, useState } from "react";
// import { Carousel, CarouselItem } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import {
  addFav,
  getAdminSideCourseAndCategory,
  get_all_about_user,
  getPopularCourse,
  getDashboard,
  getMostViewed,
  getSingleCourse,
  removeFav,
} from "../services/web/webServices";
import Backdrop from "@mui/material/Backdrop";
import Spinner from "react-bootstrap/Spinner";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
export default function Home() {
  const [getDetail, setDetail] = useState(null);
  const [getMostViewedCourse, setMostViewed] = useState(null);
  const [getAllPopularCourse, setAllPopularCourse] = useState(null);
  const [getTopic, setTopic] = useState(null);
  const [getLoader, setLoader] = useState(true);
  const [getAboutUser, setAboutUser] = useState(null);

  const [userImage1, setUserImage1] = useState("");
  const [userImage2, setUserImage2] = useState("");
  const [userImage3, setUserImage3] = useState("");
  const [userImage4, setUserImage4] = useState("");
  const [userImage5, setUserImage5] = useState("");
  const [userImage6, setUserImage6] = useState("");

  const navigate = useNavigate();

  const getApi = () => {
    getPopularCourse()
      .then((res) => {
        console.log("res--=---=->", res.data.response);
        setAllPopularCourse(
          res.data.response.filter((v) => v.popular_course.coach)
        );
      })
      .catch((err) => {
        console.log(err);
      });

    getDashboard()
      .then((res) => {
        setDetail(res.data.response);
      })
      .catch((err) => {
        console.log(err);
      });
    getAdminSideCourseAndCategory()
      .then((res) => {
        setTopic(res.data.response);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });

    getMostViewed()
      .then((res) => {
        setMostViewed(res.data.response);
      })
      .catch((err) => {
        // setLoader(false);
        console.log(err);
      });

    get_all_about_user()
      .then((res) => {
        setAboutUser(res.data.response);
        setUserImage1(res.data.response[0].profile_pic);
        setUserImage2(res.data.response[1].profile_pic);
        setUserImage3(res.data.response[2].profile_pic);
        setUserImage4(res.data.response[3].profile_pic);
        setUserImage5(res.data.response[4].profile_pic);
        setUserImage6(res.data.response[5].profile_pic);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getApi();
  }, []);

  return (
    <>
      <Header />
      {getLoader === true ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={getLoader}
        >
          <Spinner animation="grow" />
        </Backdrop>
      ) : (
        <>
          <div className="slider-section section bg-color-1">
            <div className="dots-bg dots-bg-event "></div>
            <div className="dots-bg1   "></div>
            <div className="container">
              <div className="row justify-content-center align-items-center">
                <div className="col-md-6">
                  {/* <!-- Slider Start --> */}
                  <div className="banner-content">
                    <h2
                      className=" wow fadeInUp"
                      data-wow-duration="1s"
                      data-wow-delay=".2s"
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.4s",
                      }}
                    >
                      We help students <span>Achieve</span> their{" "}
                      <span>Academic Goals</span> with quality content &{" "}
                      <span>Academic Coaches</span>{" "}
                    </h2>
                    <p
                      className="wow fadeInUp"
                      data-wow-duration="1s"
                      data-wow-delay=".3s"
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.4s",
                      }}
                    >
                      {" "}
                      Globally access video content and Academic coaches for all
                      subjects. Primary, Highschool and University Tutoring
                      available online, at your home and at our Midrand Centre.
                    </p>
                    <Link
                      to="/about"
                      className="global-button wow fadeInUp"
                      data-wow-duration="1s"
                      data-wow-delay=".3s"
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.4s",
                        borderRadius: "15px",
                      }}
                    >
                      Learn More
                    </Link>
                  </div>
                  <div className="section counter-section">
                    <div className=" ">
                      {/* <!-- Counter Wrapper Start --> */}
                      <div className="counter-wrapper">
                        <div className="counter-grid">
                          <div
                            className=" wow fadeInUp"
                            data-wow-duration="1s"
                            data-wow-delay=".2s"
                            style={{
                              visibility: "visible",
                              animationDuration: "1s",
                              animationDelay: "0.4s",
                            }}
                          >
                            {/* <!-- Single Counter Start --> */}
                            <div className="single-counter">
                              <div className="counter-box">
                                <div className="counter-content">
                                  <p>Total Students</p>
                                  <span className="count value">
                                    700+
                                    {/* {!getDetail?.user ? '0' : getDetail?.user} */}
                                  </span>
                                </div>
                              </div>
                            </div>
                            {/* <!-- Single Counter End --> */}
                          </div>
                          <div
                            className=" wow fadeInUp"
                            data-wow-duration="1s"
                            data-wow-delay=".3s"
                            style={{
                              visibility: "visible",
                              animationDuration: "1s",
                              animationDelay: "0.4s",
                            }}
                          >
                            {/* <!-- Single Counter Start --> */}
                            <div className="single-counter">
                              <div className="counter-box">
                                <div className="counter-content">
                                  <p>Total Coaches</p>
                                  <span className="count value">
                                    50+
                                    {/* {!getDetail?.coach ? '0' : getDetail?.coach} */}
                                  </span>
                                </div>
                              </div>
                            </div>
                            {/* <!-- Single Counter End --> */}
                          </div>
                          <div
                            className=" wow fadeInUp"
                            data-wow-duration="1s"
                            data-wow-delay=".4s"
                            style={{
                              visibility: "visible",
                              animationDuration: "1s",
                              animationDelay: "0.4s",
                            }}
                          >
                            {/* <!-- Single Counter Start --> */}
                            <div className="single-counter">
                              <div className="counter-box">
                                <div className="counter-content">
                                  <p>Total Courses</p>
                                  <span className="count value">
                                    100+
                                    {/* {!getDetail?.course
                                      ? '0'
                                      : getDetail?.course} */}
                                  </span>
                                </div>
                              </div>
                            </div>
                            {/* <!-- Single Counter End --> */}
                          </div>
                        </div>
                      </div>
                      {/* <!-- Counter Wrapper End --> */}
                    </div>
                  </div>
                  {/* <!-- Slider End --> */}
                </div>
                <div className="col-md-6 col-sm-8">
                  {/* <!-- Slider Images Start --> */}
                  <div className="slider-images">
                    <div className="image" style={{ marginTop: "-30%" }}>
                      {!localStorage.getItem("auth_token") ? (
                        <img
                          src="images/LandingImageOnHomePage.png"
                          alt="Hero"
                        />
                      ) : (
                        <img
                          src="images/LandingImageAfterLogin.png"
                          alt="Hero"
                        />
                      )}
                      <div className="abs-content">
                        <div className="abs-content1  floating animated">
                          <i className="far fa-calendar-alt"></i>
                          <div className="abs1-con">
                            <h3>15-25%</h3>
                            <p>Pass Rate Increase</p>
                          </div>
                        </div>
                        <div className=" abs-content2 floating">
                          <i className="fas fa-envelope"></i>
                          <div className="abs1-con">
                            <h3>Subjects</h3>
                            <p>23+</p>
                          </div>
                        </div>
                        <div className="  abs-content3 floating1  ">
                          <i className="fas fa-envelope"></i>
                          <div className="abs1-con">
                            <h3>4000+</h3>
                            <p>Tutoring hours</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- Slider Images End --> */}
                </div>
              </div>
            </div>
            {/* <!-- Shape Start --> */}
            <div className="shape-1">
              <svg
                version="1.1"
                xmlns=""
                xmlnsXlink=""
                viewBox="0, 0, 400,25.9375"
              >
                <g>
                  <path
                    className="shape-path"
                    d="M395.978 7.401 C 387.213 8.942,374.841 10.759,364.167 12.075 C 312.112 18.490,258.708 19.603,209.167 15.306 C 201.972 14.682,197.734 14.248,186.458 12.978 C 136.314 7.332,98.882 6.502,68.750 10.367 C 56.076 11.993,47.455 13.662,27.552 18.345 C 16.230 21.009,13.054 21.719,4.323 23.539 C 3.177 23.778,1.736 24.091,1.120 24.235 L 0.000 24.497 0.000 25.217 L 0.000 25.938 200.000 25.938 L 400.000 25.938 400.000 16.354 L 400.000 6.771 399.714 6.783 C 399.556 6.790,397.875 7.068,395.978 7.401 "
                    stroke="none"
                    fillRule="evenodd"
                  ></path>
                </g>
              </svg>
            </div>
            {/* <!-- Shape End --> */}
          </div>
          {/* <!-- Slider End --> */}

          <div className="section section-padding bk">
            {/* <!--   Wrapper Start --> */}
            <div className="call-to-action-wrapper">
              <div className="container d-flex quic">
                {/* <!--   Content Start --> */}
                <div
                  className="call-to-action-content home wow fadeInLeft"
                  data-wow-duration="1s"
                  data-wow-delay=".2s"
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: "0.4s",
                  }}
                >
                  <div
                    className="section-heading wow fadeInUp"
                    data-wow-duration="1s"
                    data-wow-delay=".2s"
                    style={{
                      visibility: "visible",
                      animationDuration: "1s",
                      animationDelay: "0.2s",
                    }}
                  >
                    <h2 className="bookcoach">
                      {" "}
                      <span>Book Your </span>Academic coach
                    </h2>
                    <p>
                      {" "}
                      Elevate Your Education with Tailored Tutoring and Online
                      Learning, and request to be matched with a tutor or
                      academic coach.{" "}
                    </p>
                  </div>

                  <div className="combinebutton">
                    <Link
                      to="/multicoach"
                      className="global-button startbtn"
                      style={{ borderRadius: "15px" }}
                    >
                      Select a Tutor{" "}
                    </Link>
                    <Link
                      to="/selectcoachforme"
                      className="global-button startbtn"
                      style={{ borderRadius: "15px" }}
                    >
                      Match me with a Tutor{" "}
                    </Link>
                  </div>
                </div>
                {/* <!--   Content End --> */}
                {/* <!--   Image Start --> */}
                <div
                  className="call-to-action-image d-none d-lg-block wow fadeInRight"
                  data-wow-duration="1s"
                  data-wow-delay=".2s"
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: "0.4s",
                  }}
                >
                  <div className="image">
                    <img src="images/BookYourCoachImg.jpg" alt=" " />

                    <div className="dots-bg dots-bg-event  startdot "></div>
                  </div>
                </div>
                {/* <!--   Image End --> */}
              </div>
              {/* <!--   Wrapper End --> */}
            </div>
          </div>

          <div className="section  mb-80">
            <div className="container">
              {/* <!-- Section Title Start --> */}
              {getAllPopularCourse?.length > 0 ? (
                <div className="section-heading">
                  <h2 className="">
                    {" "}
                    <span>Our Popular</span> Courses
                  </h2>
                </div>
              ) : (
                ""
              )}

              {/* <!-- Section Title End --> */}
              {/* <!-- Courses Wrapper Start --> */}
              <div className="courses-wrapper">
                {/* <!-- Courses Tab Start --> */}
                <div className="courses-tab">
                  {/* <!-- Courses Tab Content Start --> */}
                  <div className="tab-content courses-tab-content">
                    <div className="tab-pane active" id="tab1">
                      <div className="row">
                        <div className="four-grid">
                          {/* <!-- Single Courses Start --> */}

                          {getAllPopularCourse?.length > 0
                            ? getAllPopularCourse.map((item, index) => {
                                return (
                                  <>
                                    <div
                                      className="individual-course wow fadeInUp"
                                      data-wow-duration="1s"
                                      data-wow-delay=".2s"
                                      style={{
                                        visibility: "visible",
                                        animationDuration: "1s",
                                        animationDelay: "0.2s",
                                      }}
                                      key={index}
                                    >
                                      <figure>
                                        <Link
                                          to={`/coursedetail/${item?.popular_course?._id}`}
                                        >
                                          <img
                                            src={
                                              item?.popular_course
                                                ?.course_images
                                            }
                                          />
                                        </Link>
                                      </figure>
                                      <div className="individualCourseContent">
                                        <div className="coach-cont">
                                          <div>
                                            <img
                                              src={
                                                item?.popular_course?.coach
                                                  ?.coach_image
                                              }
                                            />
                                            <h4>
                                              <Link
                                                to={`/coachprofile/${item?.popular_course?.coach?._id}`}
                                              >
                                                {
                                                  item?.popular_course?.coach
                                                    ?.coach_name
                                                }{" "}
                                              </Link>
                                            </h4>
                                          </div>
                                          {/* <div className="save-abs save-job-active">
                                                          <i className="fas fa-bookmark"></i>
                                                         </div> */}
                                          {/* <div className="save-abs">
                                                          <i className="far fa-bookmark"></i>
                                                         </div> */}
                                        </div>
                                        <h5>
                                          <Link
                                            to={`/coursedetail/${item?.popular_course?._id}`}
                                          >
                                            {item?.popular_course?.course_title}
                                          </Link>
                                        </h5>
                                        <div className="indiv-view-section">
                                          <div>
                                            <i className="fas fa-eye"></i>{" "}
                                            {
                                              item?.popular_course?.review
                                                ?.length
                                            }
                                            +
                                          </div>
                                          <div>
                                            <i className="fas fa-book"></i>{" "}
                                            {
                                              item?.popular_course?.videos
                                                ?.length
                                            }
                                            , Lesson
                                          </div>
                                          <div>
                                            <i className="fas fa-comments"></i>{" "}
                                            {item?.comment}+
                                          </div>
                                          <div>
                                            <i className="fas fa-star"></i>
                                            {item?.popular_course?.rating > 0
                                              ? item?.popular_course?.rating
                                              : "0"}
                                            /5
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })
                            : ""}
                        </div>
                      </div>
                    </div>
                    {/* <!-- Courses Tab Content End --> */}
                  </div>
                  {/* <!-- Courses Tab End --> */}
                  <div className="courses-more text-center">
                    <p>
                      <strong>
                        {!getDetail?.course ? "0" : getDetail?.course}+
                      </strong>{" "}
                      More skillful free video your can explore and learn
                    </p>
                    <Link
                      to="/search_filter"
                      className="global-button"
                      style={{ borderRadius: "15px" }}
                    >
                      Free Videos
                    </Link>
                  </div>
                </div>
                {/* <!-- Courses Wrapper End --> */}
              </div>
            </div>
          </div>
          {/* <!-- Courses End --> */}
          <div style={{ clear: "both" }}></div>
          <div className="vedio-sec">
            <div className="dots-bg1    "></div>
            <div className="dots-bg1  dots-bg2    "></div>
            <div className="container">
              <div className="video-elar">
                <div className="">
                  <div
                    className="video-image  wow fadeInUp"
                    data-wow-duration="1s"
                    data-wow-delay=".2s"
                    style={{
                      visibility: "visible",
                      animationDuration: "1s",
                      animationDelay: "0.4s",
                    }}
                  >
                    <figure className="thumpnail-home">
                      {!localStorage?.getItem("auth_token") ? (
                        <img src="images/becomeACoachImage.jpg" />
                      ) : (
                        <img src="images/studentImageAfterLogin.jpg" />
                      )}
                      <div className="video-gallery-wrapper edu-section-gapTop video-section-overlayto-another ">
                        <div className="video-gallery-1">
                          <div className="thumbnail video-popup-wrapper">
                            <button className="video-play-btn with-animation position-to-top video-popup-activation btn-secondary-color size-80">
                              <span>
                                <svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  strokeWidth="0"
                                  viewBox="0 0 448 512"
                                  className="play-icon"
                                  height="1em"
                                  width="1em"
                                >
                                  <path d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"></path>
                                </svg>
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </figure>
                  </div>
                  <div
                    className="section-heading  wow fadeInUp"
                    data-wow-duration="1s"
                    data-wow-delay=".3s"
                    style={{
                      visibility: "visible",
                      animationDuration: "1s",
                      animationDelay: "0.4s",
                    }}
                  >
                    <h2 className="">
                      {" "}
                      <Link to="/search_filter">
                        <span>Popular</span> Course
                      </Link>
                    </h2>
                  </div>
                  <div className="four-grid">
                    {/* <!-- Single Courses Start --> */}

                    {getMostViewedCourse?.length > 0
                      ? getMostViewedCourse.map((item, index) => {
                          return (
                            <>
                              <div
                                className="individual-course  wow fadeInUp"
                                data-wow-duration="1s"
                                data-wow-delay=".2s"
                                style={{
                                  visibility: "visible",
                                  animationDuration: "1s",
                                  animationDelay: "0.4s",
                                }}
                              >
                                <figure>
                                  <Link to={`/coursedetail/${item?._id}`}>
                                    <img src={item?.course_images} />
                                  </Link>
                                </figure>
                                <div className="individualCourseContent">
                                  <div className="coach-cont">
                                    <div>
                                      {item?.coach?.coach_image == "" ? (
                                        <img
                                          src="images/profile.png"
                                          style={{ objectFit: "contain" }}
                                        />
                                      ) : (
                                        <img src={item?.coach?.coach_image} />
                                      )}

                                      <h4>
                                        <Link
                                          to={`/coachprofile/${item?.coach?._id}`}
                                        >
                                          {item?.coach?.coach_name}
                                        </Link>
                                      </h4>
                                    </div>
                                    <div className="save-abs save-job-active">
                                      {/* {!getstatus?<i className="fas fa-bookmark"></i>:<i className="fas fa-bookmark"></i>} */}
                                    </div>
                                  </div>
                                  <h5>
                                    <Link to={`/coursedetail/${item?._id}`}>
                                      {item?.course_title}
                                    </Link>
                                  </h5>
                                  <div className="indiv-view-section">
                                    <div>
                                      <i className="fas fa-eye"></i>
                                      {item?.review?.length}+
                                    </div>
                                    <div>
                                      <i className="fas fa-book"></i>{" "}
                                      {item?.videos?.length}, Lesson
                                    </div>
                                    <div>
                                      <i className="fas fa-comments"></i>
                                      {item?.comment}+
                                    </div>
                                    <div>
                                      <i className="fas fa-star"></i>
                                      {item?.rating > 0 ? item?.rating : "0"}/5
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })
                      : ""}

                    {/* <!-- Single Courses Start --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {getTopic?.length > 0 ? (
            <div className="section pt-80  pos-rel">
              <div className="dots-bg1 dots-bg2   "></div>
              <div className="container">
                {/* <!-- Section Title Start --> */}
                <div
                  className="section-heading popular wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay=".3s"
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: "0.4s",
                  }}
                >
                  <h2 className="">
                    {" "}
                    <span>Popular Topic</span>To Learn
                  </h2>
                </div>
                {/* <!-- Section Title End --> */}
                {/* <!-- Courses Wrapper Start --> */}
                <div className="courses-wrapper">
                  {/* <!-- Courses Tab Start --> */}
                  <div className="courses-tab">
                    {/* <!-- Courses Tab Content Start --> */}
                    <div className="tab-content courses-tab-content">
                      <div className="tab-pane active" id="tab1">
                        <div className="row">
                          <div className="four-grid">
                            {getTopic?.length > 0
                              ? getTopic?.map((item, index) => {
                                  return (
                                    <>
                                      <div
                                        className="popular-container wow fadeInUp"
                                        data-wow-duration="1s"
                                        data-wow-delay=".2s"
                                        style={{
                                          visibility: "visible",
                                          animationDuration: "1s",
                                          animationDelay: "0.4s",
                                        }}
                                        key={index}
                                      >
                                        <div className="without-hover">
                                          <img src="images/web-dev.svg" />
                                          <h2>{item?.category?.category}</h2>
                                          <p>
                                            {item?.category_text?.slice(0, 30)}
                                            ...
                                          </p>
                                        </div>
                                        <a
                                          onClick={() => {
                                            navigate("/search_filter", {
                                              state: { popular_topic: item },
                                            });
                                          }}
                                        >
                                          <div className="hover-content">
                                            <div className="popular-container-bg"></div>
                                            <h3>
                                              {item?.course?.length} Course
                                            </h3>
                                            <ul>
                                              {item?.course?.length > 0
                                                ? item?.course
                                                    ?.slice(0, 2)
                                                    ?.map((item, index) => {
                                                      return (
                                                        <>
                                                          <li>
                                                            {item?.course_title}
                                                          </li>
                                                        </>
                                                      );
                                                    })
                                                : ""}
                                            </ul>
                                            <a href="">More Details</a>
                                          </div>
                                        </a>
                                      </div>
                                    </>
                                  );
                                })
                              : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- Courses Tab Content End --> */}
                  </div>
                  {/* <!-- Courses Tab End --> */}
                </div>
                {/* <!-- Courses Wrapper End --> */}
              </div>
            </div>
          ) : (
            ""
          )}

          {/* <div style={{ clear: "both" }}></div>
               <div className="section   mb-80 pos-rel ">
                  <div className="container">

                     <div className="section-heading wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s" style={{ visibility: "visible", animationDuration: "1s", animationDelay: "0.4s" }}>
                        <h2 className=""> <span>Most Joined   </span> Online classNamees</h2>
                     </div>

                     <div className="courses-wrapper">

                        <div className="courses-tab">

                           <div className="tab-content courses-tab-content">
                              <div className="tab-pane active" id="tab1">
                                 <div className="row">
                                    <div className="four-grid">

                                       <div className="individual-course newindi wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s" style={{ visibility: "visible", animationDuration: "1s", animationDelay: "0.4s" }}>
                                          <figure>
                                             <a href=""><img src="images/aa.jpg" /></a>
                                          </figure>
                                          <div className="individualCourseContent abs-con">
                                             <div className="coach-cont">
                                                <div>
                                                   <h4>Web Development</h4>
                                                </div>
                                                <div className="save-abs save-job-active">
                                                   <i className="fas fa-bookmark"></i>
                                                </div>
                                             </div>
                                             <h5><Link to="/coursedetail">Python for Data Science and Machine Learning Bootcamp</Link></h5>
                                             <div className="indiv-view-section">
                                                <div>
                                                   <i className="fas fa-eye"></i> 2540+
                                                </div>
                                                <div>
                                                   <i className="fas fa-book"></i> 15, Lesson
                                                </div>
                                                <div>
                                                   <i className="fas fa-comments"></i> 1045+
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="individual-course newindi wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s" style={{ visibility: "visible", animationDuration: "1s", animationDelay: "0.4s" }}>
                                          <figure>
                                             <a href=""><img src="images/b.jpg" /></a>
                                          </figure>
                                          <div className="individualCourseContent abs-con">
                                             <div className="coach-cont">
                                                <div>
                                                   <h4>Business Development</h4>
                                                </div>
                                                <div className="save-abs  ">
                                                   <i className="fas fa-bookmark"></i>
                                                </div>
                                             </div>
                                             <h5><Link to="/coursedetail">Python for Data Science and Machine Learning Bootcamp</Link></h5>
                                             <div className="indiv-view-section">
                                                <div>
                                                   <i className="fas fa-eye"></i> 2540+
                                                </div>
                                                <div>
                                                   <i className="fas fa-book"></i> 15, Lesson
                                                </div>
                                                <div>
                                                   <i className="fas fa-comments"></i> 1045+
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="individual-course newindi wow fadeInUp" data-wow-duration="1s" data-wow-delay=".4s" style={{ visibility: "visible", animationDuration: "1s", animationDelay: "0.4s" }}>
                                          <figure>
                                             <a href=""><img src="images/c.jpg" /></a>
                                          </figure>
                                          <div className="individualCourseContent abs-con">
                                             <div className="coach-cont">
                                                <div>
                                                   <h4>Web Design</h4>
                                                </div>
                                                <div className="save-abs save-job-active">
                                                   <i className="far fa-bookmark"></i>
                                                </div>
                                             </div>
                                             <h5><Link to="/coursedetail">Python for Data Science and Machine Learning Bootcamp</Link></h5>
                                             <div className="indiv-view-section">
                                                <div>
                                                   <i className="fas fa-eye"></i> 2540+
                                                </div>
                                                <div>
                                                   <i className="fas fa-book"></i> 15, Lesson
                                                </div>
                                                <div>
                                                   <i className="fas fa-comments"></i> 1045+
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="individual-course newindi wow fadeInUp" data-wow-duration="1s" data-wow-delay=".5s" style={{ visibility: "visible", animationDuration: "1s", animationDelay: "0.4s" }}>
                                          <figure>
                                             <a href=""><img src="images/d.jpg" /></a>
                                          </figure>
                                          <div className="individualCourseContent abs-con">
                                             <div className="coach-cont">
                                                <div>
                                                   <h4>Web Development</h4>
                                                </div>
                                                <div className="save-abs ">
                                                   <i className="fas fa-bookmark"></i>
                                                </div>
                                             </div>
                                             <h5><Link to="/coursedetail">Python for Data Science and Machine Learning Bootcamp</Link></h5>
                                             <div className="indiv-view-section">
                                                <div>
                                                   <i className="fas fa-eye"></i> 2540+
                                                </div>
                                                <div>
                                                   <i className="fas fa-book"></i> 15, Lesson
                                                </div>
                                                <div>
                                                   <i className="fas fa-comments"></i> 1045+
                                                </div>
                                             </div>
                                          </div>
                                       </div>

                                    </div>
                                 </div>
                              </div>

                           </div>

                        </div>

                     </div>




                  </div>
               </div> */}

          <div style={{ clear: "both" }}></div>
          <div className="home-prog mos">
            <div className="dots-bg1    "></div>
            <div className="dots-bg1 dots-bg2   "></div>
            <div className="container">
              <div className="section-heading how home">
                <h2 className="">
                  {" "}
                  <span>How It Works</span>
                </h2>
              </div>
              <div className="prog-rel">
                <div className="two-grid">
                  <div className="howitwork-grid">
                    <div className="howitwork-container">
                      <div
                        className="how it-work-section home wow fadeInUp"
                        data-wow-duration=".5s"
                        dataWowDelay=".2s"
                        style={{
                          visibility: "visible",
                          animationDuration: "1s",
                          animationDelay: "0.4s",
                        }}
                      >
                        <h3
                          style={{
                            fontWeight: "bold",
                            fontSize: "30px",
                            marginLeft: "",
                            color: "white",
                          }}
                        >
                          One on One Online Tutoring
                        </h3>

                        <p>
                          We use Google tools to have sessions. The tools are
                          for interactive videos, Whiteboarding and content
                          sharing. You require a Gmail account to have access to
                          the all google tools.{" "}
                        </p>
                      </div>
                      <div
                        className="how it-work-section home wow fadeInUp"
                        data-wow-duration="1s"
                        dataWowDelay=".2s"
                        style={{
                          visibility: "visible",
                          animationDuration: "1s",
                          animationDelay: "0.4s",
                        }}
                      >
                        <h3
                          style={{
                            fontWeight: "bold",
                            fontSize: "30px",
                            color: "white",
                          }}
                        >
                          Online Classes
                        </h3>
                        <p>
                          We offer Saturday classes from grades 4-9 using Google
                          tools. To access all Google tools, you must have a
                          Gmail account.{" "}
                        </p>
                      </div>
                      <div
                        className="how it-work-section home wow fadeInUp"
                        data-wow-duration="1.5s"
                        dataWowDelay=".2s"
                        style={{
                          visibility: "visible",
                          animationDuration: "1s",
                          animationDelay: "0.4s",
                        }}
                      >
                        <h3
                          style={{
                            fontWeight: "bold",
                            fontSize: "30px",
                            color: "white",
                          }}
                        >
                          One on One face-to-face tutoring
                        </h3>
                        <p>
                          We match learners with a tutor that suits their needs
                          to meet at our Midrand Glen Austin, AH center or a
                          tutor meets a learner at home.
                        </p>
                      </div>
                      <div
                        className="how it-work-section home wow fadeInUp"
                        data-wow-duration="2s"
                        dataWowDelay=".2s"
                        style={{
                          visibility: "visible",
                          animationDuration: "1s",
                          animationDelay: "0.4s",
                        }}
                      >
                        <h3
                          style={{
                            fontWeight: "bold",
                            fontSize: "30px",
                            color: "white",
                          }}
                        >
                          {" "}
                          Homeschooler Tutoring Program
                        </h3>
                        <p>
                          Grade 1-9, we support parents who choose to home
                          school their children.Tutoring is from Monday to
                          Thursday; parents must bring materials from their
                          chosen curriculum provider.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="abt-home	">
            <div className="dots-bg1   "></div>
            <div className="dots-bg1 dots-bg2   "></div>
            <div className="container">
              <div className="abt">
                <div className="two-grid">
                  <div
                    className="two-left-grid wow fadeInLeft"
                    data-wow-duration="1s"
                    data-wow-delay=".2s"
                    style={{
                      visibility: "visible",
                      animationDuration: "1s",
                      animationDelay: "0.4s",
                    }}
                  >
                    <h2>
                      {" "}
                      <span>
                        <span style={{ textTransform: "lowercase" }}>e</span>
                        Thuta Services
                      </span>
                    </h2>
                    <p>
                      <b>Languages:</b> English, Afrikaans, French, IsiZulu,
                      Sepedi.
                      <br />
                      <b>Human and Social Studies:</b> Geography, History, Life
                      Orientation.
                      <br />
                      <b>Science:</b> Physics, Chemistry, Biology/Life Science.
                      <br />
                      <strip>
                        <b>Commerce:</b> Accounting, Business management
                        studies, and Economics.
                      </strip>
                      <br />
                      <b>Mathematics:</b> Mathematics and Mathematical Literacy.{" "}
                      <br />
                      <b>Engineering and Technical: </b> Technology, Engineering
                      Graphics, and Designs.
                      <br />
                      <b>Information Technology:</b> CAT, IT.
                      <br />
                      <b>Hospitality:</b> Tourism and Consumer studies.
                    </p>
                  </div>
                  <div className="two-right-grid">
                    <div className="topact-grid" style={{ color: "#111174" }}>
                      <div
                        className="service-container"
                        data-wow-duration="1s"
                        data-wow-delay=".4s"
                        style={{
                          visibility: " visible",
                          animationDuration: "1s",
                          animationDelay: "0.4s",
                          // transform: "rotateY(30deg)",
                          // transform: "rotateX(30deg)",
                        }}
                      >
                        <div
                          className="topact home wow fadeInRight"
                          data-wow-duration="1s"
                          data-wow-delay=".4s"
                          style={{
                            visibility: " visible",
                            animationDuration: "1s",
                            animationDelay: "0.4s",
                          }}
                        >
                          <img
                            style={{
                              backgroundColor: "#E0E0E0",
                              padding: "3px",
                            }}
                            src="/images/icons8-online-learning-100.png"
                          />
                          <h2 class="ethuta_h2">
                            <b>Online One on One Tutoring</b>
                          </h2>
                          <p
                            style={{
                              fontWeight: "bold",
                              color: "rgb(7,47,96)",
                              fontSize: "17px",
                            }}
                          >
                            We offer one on one online sessions for Grades 4-12.
                            We cover most mainstream subjects.
                          </p>
                        </div>
                      </div>
                      <div className="service-container">
                        <div
                          className="topact home wow fadeInRight"
                          data-wow-duration="1s"
                          data-wow-delay=".5s"
                          style={{
                            visibility: "visible",
                            animationDuration: "1s",
                            animationDelay: "0.4s",
                          }}
                        >
                          <img
                            style={{
                              backgroundColor: "#E0E0E0",
                              height: "50px",
                              padding: "0px",
                            }}
                            src="images/face2face (2).png"
                          />
                          <h2>
                            <b>Face to Face one on one</b>
                          </h2>
                          <p
                            style={{
                              fontWeight: "bold",
                              color: "rgb(7,47,96)",
                              fontSize: "17px",
                            }}
                          >
                            We offer all grades 1-12 subjects at our Midrand
                            centre or the learner's home.
                          </p>
                        </div>
                      </div>
                      <div className="service-container">
                        <div
                          className="topact home wow fadeInRight"
                          data-wow-duration="1s"
                          data-wow-delay=".3s"
                          style={{
                            visibility: "visible",
                            animationDuration: "1s",
                            animationDelay: "0.4s",
                          }}
                        >
                          <img
                            style={{
                              backgroundColor: "#E0E0E0",
                              padding: "5px",
                            }}
                            src="images/icons88-class-g4.png"
                          />
                          <h2>
                            <b>Classes Grade 4-9</b>
                          </h2>
                          <p
                            style={{
                              fontWeight: "bold",
                              color: "rgb(7,47,96)",
                              fontSize: "17px",
                            }}
                          >
                            We offer Saturday classes online and face-to-face at
                            our Seshego centre. Mathematics and English.
                          </p>
                        </div>
                      </div>
                      <div className="service-container">
                        <div
                          className="topact home four wow fadeInRight"
                          data-wow-duration="1s"
                          data-wow-delay=".6s"
                          style={{
                            visibility: "visible",
                            animationDuration: "1s",
                            animationDelay: "0.4s",
                          }}
                        >
                          <img
                            style={{
                              backgroundColor: "#E0E0E0",
                              padding: "4px",
                            }}
                            src="images/icons8-video-conference-100.png"
                          />
                          <h2>
                            <b>Video Courses</b>
                          </h2>
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "17px",
                              color: "rgb(7,47,96)",
                            }}
                          >
                            We offer video content from the foundation phase to
                            university students.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {getAboutUser?.length > 0 ? (
            <>
              <div style={{ clear: "both" }}></div>

              <div className="container testi-sec">
                {/* <!-- start explore slider -->		 */}
                <div className="abs-testi-img">
                  <div
                    className="testi-1  wow fadeInLeft"
                    data-wow-duration="1s"
                    data-wow-delay=".2s"
                    style={{
                      visibility: "visible",
                      animationDuration: "1s",
                      animationDelay: "0.4s",
                    }}
                  >
                    <img src={userImage1} alt="userImage" />
                  </div>
                  <div
                    className="testi-2 wow fadeInLeft"
                    data-wow-duration="1s"
                    data-wow-delay=".3s"
                    style={{
                      visibility: "visible",
                      animationDuration: "1s",
                      animationDelay: "0.4s",
                    }}
                  >
                    <img src={userImage2} alt="userImage" />
                  </div>
                  <div
                    className="testi-3 wow fadeInLeft"
                    data-wow-duration="1s"
                    data-wow-delay=".4s"
                    style={{
                      visibility: "visible",
                      animationDuration: "1s",
                      animationDelay: "0.4s",
                    }}
                  >
                    <img src={userImage3} alt="userImage" />
                  </div>
                  <div
                    className="testi-4 wow fadeInRight"
                    data-wow-duration="1s"
                    data-wow-delay=".6s"
                    style={{
                      visibility: "visible",
                      animationDuration: "1s",
                      animationDelay: "0.4s",
                    }}
                  >
                    <img src={userImage4} alt="userImage" />
                  </div>
                  <div
                    className="testi-5 wow fadeInLeft"
                    data-wow-duration="1s"
                    data-wow-delay=".5s"
                    style={{
                      visibility: "visible",
                      animationDuration: "1s",
                      animationDelay: "0.4s",
                    }}
                  >
                    <img src={userImage5} alt="userImage" />
                  </div>
                  <div
                    className="testi-6 wow fadeInRight"
                    data-wow-duration="1s"
                    data-wow-delay=".3s"
                    style={{
                      visibility: "visible",
                      animationDuration: "1s",
                      animationDelay: "0.4s",
                    }}
                  >
                    <img src={userImage6} alt="userImage" />
                  </div>
                </div>
                <div
                  id=" "
                  className="  explore_slider testimonial-section live_auction_page"
                >
                  {/* <!-- start explore slider -->	 */}
                  <div className="row">
                    {/* <!-- start row -->			 */}
                    <div className="my-width">
                      {/* <!-- start explore slider -->		 */}
                      <div className="single_slide  ">
                        {/* <!-- start single slide -->	 */}
                        <div className="slider_img_area">
                          <div className="testimonial-inner testimonial-slider">
                            <div className="testimonial-item text-center">
                              <div className="section-heading mil">
                                <h2 className="">
                                  {" "}
                                  <span>TESTIMONIALS</span>{" "}
                                </h2>
                              </div>
                              <Carousel
                                nextLabel={""}
                                prevLabel={""}
                                variant="light"
                                className="owl-carousel owl-theme"
                                infiniteLoop
                                autoPlay
                              >
                                {(getAboutUser || []).map((item, index) => {
                                  return (
                                    <>
                                      <strong className="items">
                                        <figure className="testimonial-img">
                                          <img src={item?.profile_pic} alt="" />
                                        </figure>
                                        <div className="testimonial-content">
                                          <p
                                            style={{
                                              inlineSize: "700px",
                                              overflowWrap: "break-word",
                                            }}
                                          >
                                            {item?.about_user}
                                          </p>
                                          <cite>
                                            {item?.user_name}
                                            <span className="company">
                                              {item?.user_profession}
                                            </span>
                                          </cite>
                                        </div>
                                      </strong>
                                    </>
                                  );
                                })}
                              </Carousel>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <!-- end row -->	 */}
                    </div>
                    {/* <!-- end single slide -->		 */}
                  </div>
                  {/* <!-- end explore slider --> */}
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          <Footer />
        </>
      )}
    </>
  );
}
