
import React, { useEffect, useState } from 'react';
import { user_payment } from '../../services/web/webServices';
export default function Checkout() {
  const [getData, setData] = useState(null);
  useEffect(() => {
    user_payment().then((res) => {
      // console.log("checkout==>", res);
      setData(res.data.fields);
    }).catch((err) => {
      console.log(err);
    })
  }, [])
  return (
    <>
      <form
        action="https://www.payfast.co.za/eng/process"
        method="post">

        {(getData|| []).map((item, index) => {
          return (
            <>
              <label >
                <label>{item?.key}</label>
                <input type="text" name={item?.key} value={item?.val} />
              </label>
            </>
          )
        })}
        {/* <br /> */}
        <input type="submit" />
      </form>
    </>

  )
}

