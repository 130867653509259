import React from 'react'
import Footer from './Footer'
import InsideHeader from './InsideHeader'

export default function PrivacyPolicy() {
    return (
        <>
        <InsideHeader/>
            <div style={{clear:"both"}}></div>
            <div className="section page-banner-section bg-color-1">
                <div className="container">
                    {/* <!-- Page Banner Content Start --> */}
                    <div className="page-banner-content">
                        <h2 className="title">Privacy Policy</h2>
                        <ul className="breadcrumb justify-content-center">
                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                            <li className="breadcrumb-item active">Privacy Policy</li>
                        </ul>
                    </div>
                    {/* <!-- Page Banner Content End --> */}
                </div>
            </div>
            <div style={{clear:"both"}}></div>
            <div className="container">
                <div className="privacy-policy">
                    <h2>Where does it come from</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                    <h2>Where can I get some?</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                </div></div>
            <Footer/>

    </>
  )
}
