import { React, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { forgotpasswordmove } from "../services/web/webServices";
import { useNavigate } from "react-router-dom";
import { Store } from "react-notifications-component";

export default function ForgotPasswordMove() {
  const [queryParams] = useSearchParams();

  const navigate = useNavigate();
  const type = "password";

  localStorage.setItem("auth_token", queryParams.get("token"));
  useEffect(() => {
    forgotpasswordmove(type, queryParams.get("token"))
      .then((res) => {
        //   Store.addNotification({
        //     title: "Success",
        //     message: res?.data?.message,
        //     type: "success",
        //     insert: "top",
        //     container: "top-right",
        //     animationIn: ["animate__animated", "animate__fadeIn"],
        //     animationOut: ["animate__animated", "animate__fadeOut"],
        //     dismiss: {
        //         duration: 5000,
        //         onScreen: true,
        //     },
        // });
        navigate("/resetpassword");
      })
      .catch((err) => {
        Store.addNotification({
          title: "Error!",
          message: err?.response?.data?.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
        // console.log(err);
        // navigate('/login')
      });
  });

  return <></>;
}
