import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import InsideHeader from "./InsideHeader";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  addFav,
  bookCourse,
  comment,
  getCommentById,
  getSingleCourse,
  profile,
  removeFav,
  updateSingleCourse,
  addRating,
  getRating,
} from "../services/web/webServices";
import { Store } from "react-notifications-component";
import { Form, Formik } from "formik";
import { MyTextArea, MyTextInput } from "../services/web/inputServices";
import * as Yup from "yup";
import ReactPlayer from "react-player";
import md5 from "md5";
import Backdrop from "@mui/material/Backdrop";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import Scroll from "../scroll";
import base_uri from "../api/base_url";

const css = `
.enrolled-student:after {
    content: "";
    display: block;
    width: 2.5px;
    height: 20px;
    background: #d5d5d5;
    position: absolute;
    right: -15px;
    top: 1px;
}

.enrolled-student {
    position: relative;
}
`;
export default function CourseDetail() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [getLoader, setLoader] = useState(true);
  const [userDetails, setUserDetails] = useState([]);
  const [getDetail, setDetail] = useState([]);
  const [vedioUrl, setVedioUrl] = useState([]);
  const [getstatus, setGetStatus] = useState("");
  const [getCommentDetail, setCommentDetail] = useState([]);
  const param = useParams();
  const [Button, setButton] = useState(true);
  const [bookCourseButton, setBookCourseButton] = useState(true);
  const [addFavButton, setAddFavButton] = useState(true);
  const [removeFavButton, setRemoveFavButton] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [addRatingButton, setRatingButton] = useState(false);
  const navigate = useNavigate();

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Buy Course
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="row">
              <div className="col-md-12">
                <h1 style={{ marginLeft: "41%", marginBottom: "5%" }}>
                  R {getDetail?.price}
                </h1>
              </div>
            </div>
            <form action={`${base_uri.payfast_uri}`} method="post">
              {/* <form action={`${base_uri.url}/mycourse`}> */}
              <input
                type="hidden"
                name="merchant_id"
                value={`${base_uri.merchant_id}`}
              />
              <input
                type="hidden"
                name="merchant_key"
                value={`${base_uri.merchant_key}`}
              />
              <input
                type="hidden"
                name="return_url"
                value={`${base_uri.url}/mycourse`}
              />
              <input
                type="hidden"
                name="cancel_url"
                value={`${base_uri.url}/`}
              />
              <input
                type="hidden"
                name="notify_url"
                value={`${base_uri.backend_base_uri}/notify_url`}
              />
              <input
                type="hidden"
                name="name_first"
                value={userDetails?.name}
              />
              <input
                type="hidden"
                name="email_address"
                value={userDetails?.email}
              />
              <input
                type="hidden"
                name="m_payment_id"
                value={userDetails?._id}
              />
              <input type="hidden" name="amount" value={getDetail?.price} />
              <input
                type="hidden"
                name="item_name"
                value={getDetail.course_title}
              />
              <input type="hidden" name="custom_str1" value={param.id} />
              <input
                type="hidden"
                name="custom_str2"
                value={getDetail?.coach?._id}
              />
              <input
                type="hidden"
                name="passphrase"
                value={`${base_uri.passphrase}`}
              />
              <div className="row">
                <div className="col-md-3">
                  <button
                    type="submit"
                    style={{
                      width: "200px",
                      height: "49px",
                      marginLeft: "260px",
                      color: "white",
                      backgroundColor: "blue",
                      border: "none",
                    }}
                  >
                    {" "}
                    Pay
                  </button>
                </div>
              </div>
            </form>
          </>
        </Modal.Body>
      </Modal>
    );
  }

  const book_course = () => {
    const course_id = param.id;
    setBookCourseButton(false);
    bookCourse({ course_id })
      .then((res) => {
        setBookCourseButton(true);
        <Scroll />;
        if (res.data.response.status == 2) {
          Store.addNotification({
            title: "Success",
            message: res?.data?.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        }
        if (res.data.response.status == 1) {
          Store.addNotification({
            title: "Error!",
            message: res?.data?.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
          localStorage.removeItem("auth_token");
          localStorage.removeItem("state");
          navigate("/login");
        }
        if (res.data.response.status == 0) {
          // setShow(true);
          navigate("/mycourse");
        }
      })
      .catch((err) => {
        setBookCourseButton(true);
        if (err) {
          console.log(err);
        }
      });
  };
  const addFavourite = () => {
    const course_id = param.id;
    if (getstatus == false) {
      setAddFavButton(false);
      addFav({ course_id })
        .then((res) => {
          Store.addNotification({
            title: "Success",
            message: res?.data?.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
          navigate("/myfavcourse");
          if (res.data.response.status == 0) {
            Store.addNotification({
              title: "Error!",
              message: res?.data?.message,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
            localStorage.removeItem("auth_token");
            localStorage.removeItem("state");
            navigate("/login");
          }
        })
        .catch((err) => {
          if (err) {
            setAddFavButton(true);
            console.log(err);
          }
        });
    }
  };
  const removeFavourite = () => {
    setRemoveFavButton(false);
    removeFav(param.id)
      .then((res) => {
        Store.addNotification({
          title: "Success",
          message: res?.data?.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
        getSingleCourse(param.id).then((res) => {
          setRemoveFavButton(true);
          setGetStatus(res.data.response.isFavourite);
        });

        if (res.data.response.status == 0) {
          Store.addNotification({
            title: "Error!",
            message: res?.data?.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
          localStorage.removeItem("auth_token");
          localStorage.removeItem("state");
          navigate("/login");
        }
      })
      .catch((err) => {
        if (err) {
          // setRemoveFavButton(true);
          console.log(err);
        }
      });
  };

  useEffect(() => {
    //    start
    let data = "";
    let timer = "";
    updateSingleCourse(param.id);
    getCommentById(param.id).then((res) => {
      setCommentDetail(res.data.response);
    });
    profile().then((res) => {
      setUserDetails(res?.data?.response);
      console.log("profile===>", res.data.response);
      if (res.data.response.status == 0) {
        Store.addNotification({
          title: "Error!",
          message: res?.data?.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
        localStorage.removeItem("auth_token");
        localStorage.removeItem("state");
        navigate("/login");
      }
    });

    getSingleCourse(param.id)
      // setDetail()
      .then((res) => {
        data = res.data.response;
        setDetail(res.data.response);
        setVedioUrl(res.data.response?.videos[0]?.video_url);
        setGetStatus(res.data.response.isFavourite);
        setLoader(false);

        timer = setTimeout(() => {
          getRating({ course_id: data?._id }).then((res) => {
            if (res.data.response.found == false) {
              setModalShow(true);
            }
          });
        }, 10000);
      });
    // Rating api Starts

    return () => clearTimeout(timer);

    // Ends
  }, []);

  // Add Rating
  function AddRatingModel(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ marginLeft: "42%" }}
          >
            Add Rating
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Formik
              initialValues={{
                rating: "",
                coach_id: getDetail?.coach?._id,
                course_id: getDetail?._id,
                submited: true,
              }}
              validationSchema={Yup.object({
                rating: Yup.string().required("Please add rating"),
              })}
              onSubmit={(values) => {
                // console.log("====values===>", values);
                setRatingButton(true);
                addRating(values)
                  .then((res) => {
                    setModalShow(false);
                    Store.addNotification({
                      title: "Success",
                      message: res?.data?.message,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animate__animated", "animate__fadeIn"],
                      animationOut: ["animate__animated", "animate__fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                      },
                    });
                    setRatingButton(false);
                  })
                  .catch((err) => {
                    if (err) {
                      Store.addNotification({
                        title: "Error!",
                        message: err?.response?.data?.message,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                          duration: 5000,
                          onScreen: true,
                        },
                      });
                      setRatingButton(false);
                    }
                  });
              }}
            >
              {(props) => (
                <Form>
                  <div className="row">
                    <div className="col-md-12">
                      <h5>Rating</h5>
                      <select
                        style={{ width: "700px", height: "40px" }}
                        name="rating"
                        onChange={(e) => (props.values.rating = e.target.value)}
                      >
                        <option readOnly>Choose Rating</option>
                        <option value="1">1 Star</option>
                        <option value="2">2 Star</option>
                        <option value="3">3 Star</option>
                        <option value="4">4 Star</option>
                        <option value="5">5 Star</option>
                      </select>
                      {props.values.rating == "" &&
                        props.errors.rating &&
                        props.touched.rating && (
                          <div style={{ color: "red" }}>
                            {props.errors.rating}
                          </div>
                        )}
                    </div>
                  </div>
                  <br></br>
                  <div className="row">
                    <div className="col-md-4">
                      {addRatingButton == false ? (
                        <button
                          style={{
                            backgroundColor: "blue",
                            border: "none",
                            color: "white",
                            width: "150px",
                            height: "40px",
                            marginLeft: "620px",
                          }}
                          type="submit"
                        >
                          Submit
                        </button>
                      ) : (
                        <button
                          style={{
                            backgroundColor: "blue",
                            border: "none",
                            color: "white",
                            width: "150px",
                            height: "40px",
                            marginLeft: "620px",
                          }}
                          type="button"
                        >
                          wait please :)
                        </button>
                      )}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        </Modal.Body>
      </Modal>
    );
  }
  // Ends

  return (
    <>
      <style>{css}</style>
      <InsideHeader />
      <div style={{ marginTop: "-26px" }}>
        <br />
      </div>

      {getLoader === true ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={getLoader}
        >
          <Spinner animation="grow" />
        </Backdrop>
      ) : (
        <>
          <div className="inside-header-bottom">
            <div className="container">
              <div className="inside-header-bottom-grid">
                <div className="course-details-left-top">
                  <ul
                    className="breadcrumb wow fadeInUp"
                    data-wow-duration=".5s"
                    data-wow-delay=".2s"
                    style={{
                      visibility: "visible",
                      animationDuration: "1s",
                      animationDelay: "0.4s",
                    }}
                  >
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <i className="fas fa-chevron-right"></i>
                    <li className="breadcrumb-item active">
                      <Link to="/search">Course</Link>
                    </li>

                    {/* <i className="fas fa-chevron-right"></i> */}
                    {/* <li className="breadcrumb-item active">Maths</li> */}
                  </ul>
                  {/* <h3 className="course-category wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s" style={{ visibility: "visible", animationDuration: "1s", animationDelay: "0.4s" }}>Maths</h3> */}
                  <h2
                    className="course-title wow fadeInUp"
                    data-wow-duration="1s"
                    data-wow-delay=".2s"
                    style={{
                      visibility: "visible",
                      animationDuration: "1s",
                      animationDelay: "0.4s",
                    }}
                  >
                    {getDetail.course_title}
                  </h2>
                  <p
                    className="course-top-para wow fadeInUp"
                    data-wow-duration="1s"
                    data-wow-delay=".2s"
                    style={{
                      visibility: "visible",
                      animationDuration: "1s",
                      animationDelay: "0.4s",
                      inlineSize: "600px",
                      overflowWrap: "break-word",
                    }}
                  >
                    {getDetail.course_description}
                  </p>
                  {bookCourseButton == true ? (
                    <a
                      className="global-button w-100 mb-10 wow fadeInUp cdetails"
                      data-wow-duration="1s"
                      data-wow-delay=".3s"
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.3s",
                      }}
                      onClick={() => {
                        book_course();
                        window.location.href = "/mycourse";
                      }}
                    >
                      {" "}
                      Free Videos
                    </a>
                  ) : (
                    <a
                      className="global-button w-100 mb-10 wow fadeInUp cdetails"
                      data-wow-duration="1s"
                      data-wow-delay=".3s"
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.3s",
                      }}
                    >
                      please wait! :)
                    </a>
                  )}
                  {/* <a
                    className='global-button w-100 mb-10 wow fadeInUp cdetails'
                    style={{
                      visibility: 'visible',
                      animationDuration: '1s',
                      animationDelay: '0.3s',
                    }}
                    onClick={() => {
                      book_course();
                    }}
                    // to={`/bookcoach/${getDetail?.coach?._id}/${param.id}`}
                  >
                    Buy Course
                  </a> */}

                  {/* <a href="" className="global-button w-100 mb-10 wow fadeInUp cdetails" data-wow-duration="1s" data-wow-delay=".3s" style={{ visibility: "visible", animationDuration: "1s", animationDelay: "0.3s" }}>Join Classes</a> */}
                  <div
                    className="coach-review-course wow fadeInUp"
                    data-wow-duration="1s"
                    data-wow-delay=".2s"
                    style={{
                      visibility: "visible",
                      animationDuration: "1s",
                      animationDelay: "0.4s",
                    }}
                  >
                    <div className="coach-photo">
                      <img src={getDetail?.coach?.coach_image} />
                      <h3>
                        <Link to={`/coachprofile/${getDetail?.coach?._id}`}>
                          {getDetail?.coach?.coach_name}
                        </Link>
                      </h3>
                    </div>
                    <div className="course-review">
                      <i className="fas fa-eye"></i> {getDetail?.review?.length}
                      +
                    </div>
                    <div className="enrolled-student">
                      <h4> {getDetail.enrolledStudent} enrolled students</h4>
                    </div>
                    <div>
                      <h4>
                        {" "}
                        {getDetail?.rating > 0 ? getDetail?.rating : "0"}/5{" "}
                        <i className="fas fa-star"></i>
                      </h4>
                    </div>
                  </div>
                </div>

                {/* <div
                  className='course-details-right-top wow fadeInUp'
                  data-wow-duration='1s'
                  data-wow-delay='.2s'
                  style={{
                    visibility: 'visible',
                    animationDuration: '1s',
                    animationDelay: '0.4s',
                  }}
                >
                 
                  <ReactPlayer
                    controls
                    playing={true}
                    config={{
                      file: { attributes: { controlsList: 'nodownload' } },
                    }}
                    style={{ position: 'relative', zIndex: '23' }}
                    width='100%'
                    height={350}
                    // playIcon={<button>Play</button>}
                    url={vedioUrl}
                  />
                </div> */}
              </div>
            </div>
          </div>
          <div className="container">
            <div className="course-bottom">
              <div className="course-bottom-left">
                <div
                  className="whatYouWillLearn wow fadeInUp"
                  data-wow-duration="1.5s"
                  data-wow-delay=".2s"
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: "0.4s",
                  }}
                >
                  <h2 className="insiMainTitle">What you will learn</h2>
                  <ul>
                    {getDetail?.what_you_will_learn?.length > 0
                      ? getDetail?.what_you_will_learn.map((item, i) => {
                          return (
                            <li key={i}>
                              <i className="fas fa-check-circle"></i> {item}
                            </li>
                          );
                        })
                      : "Not Found"}
                  </ul>
                </div>

                {/* <!-- Course Lessons Start --> */}
                <div
                  className="course-c wow fadeInUp"
                  data-wow-duration="2s"
                  data-wow-delay=".2s"
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: "0.4s",
                  }}
                >
                  <div className="lessons-top1">
                    <h2 className="insiMainTitle">Course Content</h2>
                    <ul className="lessons-list">
                      {getDetail?.course_content?.length > 0
                        ? getDetail?.course_content.map((item, i) => {
                            return <li key={i}> {item}</li>;
                          })
                        : "Not Found"}
                    </ul>
                  </div>
                </div>
                {/* <!-- Course Lessons End --> */}

                <div
                  className="course-c wow fadeInUp"
                  data-wow-duration="2s"
                  data-wow-delay=".2s"
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: "0.4s",
                  }}
                >
                  <h2 className="insiMainTitle">Faq's</h2>
                  {/* <!-- Course Accordion Start --> */}
                  <div
                    className="course-accordion accordion"
                    id="accordionCourse"
                  >
                    {getDetail?.faq?.length > 0
                      ? getDetail?.faq?.map((item, i) => {
                          return (
                            <div className="accordion-item" key={i}>
                              <button
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse${i}`}
                              >
                                {item.question}
                              </button>
                              <div
                                id={`collapse${i}`}
                                className="accordion-collapse collapse"
                                data-bs-parent="#accordionCourse"
                              >
                                <div className="accordion-body" key={i}>
                                  <p>{item.answer} </p>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : "Not Found"}
                  </div>
                  {/* <!-- Course Accordion End --> */}
                </div>
              </div>
              <div className="course-bottom-left">
                <div className="sidebar-details-wrap">
                  {/* <!-- Sidebar Details Video Description Start --> */}
                  <div className="sidebar-details-video-description">
                    <div className="sidebar-description">
                      <div className="side-image">
                        <img src="/images/math.jpg" />
                      </div>
                      <div className="price">
                        <span className="sale-price">
                          {getDetail.currency_type}
                          <br></br>
                          {getDetail.price}
                        </span>
                      </div>
                      <div className="buttonOnetoOne">
                        {bookCourseButton == true ? (
                          <a
                            className="global-button w-100 mb-10 wow fadeInUp"
                            data-wow-duration="1s"
                            data-wow-delay=".3s"
                            style={{
                              visibility: "visible",
                              animationDuration: "1s",
                              animationDelay: "0.3s",
                            }}
                            onClick={() => {
                              book_course();
                              window.location.href = "/mycourse";
                            }}
                          >
                            {" "}
                            Free Videos
                          </a>
                        ) : (
                          <a
                            className="global-button w-100 mb-10 wow fadeInUp"
                            data-wow-duration="1s"
                            data-wow-delay=".3s"
                            style={{
                              visibility: "visible",
                              animationDuration: "1s",
                              animationDelay: "0.3s",
                            }}
                          >
                            please wait! :)
                          </a>
                        )}

                        {!getstatus ? (
                          addFavButton == true ? (
                            <a
                              onClick={addFavourite}
                              className="global-button w-100 wow fadeInUp bor-btn"
                              data-wow-duration="1s"
                              data-wow-delay=".3s"
                              style={{
                                visibility: "visible",
                                animationDuration: "1s",
                                animationDelay: "0.3s",
                              }}
                            >
                              Add to favourite
                            </a>
                          ) : (
                            <a
                              className="global-button w-100 wow fadeInUp bor-btn"
                              data-wow-duration="1s"
                              data-wow-delay=".3s"
                              style={{
                                visibility: "visible",
                                animationDuration: "1s",
                                animationDelay: "0.3s",
                              }}
                            >
                              please wait! :)
                            </a>
                          )
                        ) : removeFavButton == true ? (
                          <a
                            onClick={removeFavourite}
                            className="global-button w-100 wow fadeInUp bor-btn"
                            data-wow-duration="1s"
                            data-wow-delay=".3s"
                            style={{
                              visibility: "visible",
                              animationDuration: "1s",
                              animationDelay: "0.3s",
                            }}
                          >
                            remove{" "}
                          </a>
                        ) : (
                          <a
                            className="global-button w-100 wow fadeInUp bor-btn"
                            data-wow-duration="1s"
                            data-wow-delay=".3s"
                            style={{
                              visibility: "visible",
                              animationDuration: "1s",
                              animationDelay: "0.3s",
                            }}
                          >
                            wait please! :){" "}
                          </a>
                        )}
                      </div>
                      <ul className="description-list">
                        <li>
                          <i className="fa fa-clock-o"></i> Duration{" "}
                          <span>{getDetail?.duration}</span>
                        </li>
                        <li>
                          <i className="fa fa-sliders"></i> Level{" "}
                          <span>{getDetail.level}</span>
                        </li>
                        <li>
                          <i className="fas fa-book"></i> Lectures{" "}
                          <span>
                            {getDetail?.videos?.length > 0
                              ? getDetail?.videos?.length
                              : 0}
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-language"></i> Language{" "}
                          <span>{getDetail.language}</span>
                        </li>
                        <li>
                          <i className="fas fa-graduation-cap"></i> Enrolled{" "}
                          <span>{getDetail.enrolledStudent} Enrolled</span>
                        </li>
                      </ul>

                      {getDetail?.is_slot == true ? (
                        <Link
                          className="btn btn-outline-primary w-100"
                          to={`/bookcoach/${getDetail?.coach?._id}/${param.id}`}
                        >
                          {" "}
                          Book Coach
                        </Link>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {/* <!-- Sidebar Details Video Description End --> */}
                  {/* <!-- Sidebar Course Start --> */}
                  {/* <!-- Sidebar Course End --> */}
                </div>
              </div>
            </div>
          </div>
          <div className="commtent-wrapper">
            <div className="container">
              {/* <!-- Commtent Box Start --> */}
              <div className="comment-box">
                <h3 className="comment-title">
                  Comment ({getCommentDetail?.length})
                </h3>

                {getCommentDetail?.length > 0 ? (
                  <ul className="commtent-items">
                    {getCommentDetail?.length > 0
                      ? getCommentDetail
                          .filter((item, index) => index < 3)
                          .map((item, i) => {
                            return (
                              <li>
                                {/* <!-- Single Commtent Start --> */}
                                <div className="single-comment" key={i}>
                                  <div className="comment-author">
                                    {item?.user_id?.profile_pic === " " ? (
                                      <img src="/images/profile.png" />
                                    ) : (
                                      <img src={item?.user_id?.profile_pic} />
                                    )}
                                  </div>
                                  <div className="comment-content">
                                    <h4 className="name">{item?.name}</h4>
                                    <p>{item?.comment}</p>
                                    {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,</p> */}
                                    <div className="meta">
                                      <span className="date">
                                        {item?.date.slice(0, 10)}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                {/* <!-- Single Commtent End --> */}
                              </li>
                            );
                          })
                      : "Not Found"}
                  </ul>
                ) : (
                  "No Comments"
                )}
              </div>
              {/* <!-- Commtent Box End --> */}
              {/* <!-- Commtent Form Start --> */}
              <div className="comment-form">
                <h3 className="comment-title">Leave a Comment</h3>
                <div className="comment-form-wrapper">
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      course_id: getDetail._id,
                      name: userDetails.name,
                      email: userDetails.email,
                      comment: "",
                    }}
                    validationSchema={Yup.object({
                      comment: Yup.string()
                        .required("required!")
                        .matches(
                          /^\s*\S[\s\S]*$/,
                          "* This field cannot contain  blankspaces"
                        ),
                    })}
                    onSubmit={(values, { resetForm }) => {
                      setButton(false);

                      comment(values)
                        .then((res) => {
                          setButton(true);
                          getCommentById(param.id)
                            .then((res) => {
                              setCommentDetail(res.data.response);

                              resetForm({ values: "" });
                            })

                            .catch((err) => {
                              console.log(err);
                            });
                        })
                        .catch((err) => {
                          console.log(err);
                          setButton(true);
                        });
                    }}
                  >
                    <Form>
                      <div className="row">
                        <div className="col-md-6">
                          {/* <!-- Single Form Start --> */}
                          <div className="single-form">
                            <MyTextInput
                              type="text"
                              className="form-control"
                              placeholder="Your Name*"
                              name="name"
                              readOnly
                            />
                          </div>
                          {/* <!-- Single Form End --> */}
                        </div>
                        <div className="col-md-6">
                          {/* <!-- Single Form Start --> */}
                          <div className="single-form">
                            {/* {userDetails.father_email==''?   <MyTextInput type="email" className="form-control" placeholder="Your Email*" name='email' readOnly/>:   <MyTextInput type="email" className="form-control" placeholder="Your Email*" name='father_email' readOnly/>} */}
                            <MyTextInput
                              type="email"
                              className="form-control"
                              placeholder="Your Email*"
                              name="email"
                              readOnly
                            />
                          </div>
                          {/* <!-- Single Form End --> */}
                        </div>
                        <div className="col-md-12">
                          {/* <!-- Single Form Start --> */}
                          <div className="single-form">
                            <MyTextArea
                              type="textarea"
                              className="form-control"
                              placeholder="Your Message*"
                              name="comment"
                            />
                          </div>
                          {/* <!-- Single Form End --> */}
                        </div>
                        <div className="col-md-12">
                          {/* <!-- Single Form Start --> */}
                          <div className="single-form">
                            {Button ? (
                              <button
                                className="btn btn-primary btn-hover-heading-color"
                                type="submit"
                              >
                                Post Comment
                              </button>
                            ) : (
                              <button
                                className="btn btn-primary btn-hover-heading-color"
                                type="button"
                                disabled
                              >
                                Wait Please!
                              </button>
                            )}
                          </div>
                          {/* <!-- Single Form End --> */}
                        </div>
                      </div>
                    </Form>
                  </Formik>
                </div>
              </div>
              {/* <!-- Commtent Form End --> */}
            </div>
          </div>

          <Footer />
        </>
      )}
      {/* Modal For Book Course */}
      {/* Ends */}
      <MyVerticallyCenteredModal show={show} onHide={() => setShow(false)} />
      {/* Add Rating */}
      <AddRatingModel show={modalShow} onHide={() => setModalShow(false)} />
      {/* Ends */}
    </>
  );
}
