import React, { useState } from 'react'
import Footer from './Footer'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik';
import { MyTextInput } from '../services/web/inputServices';
import * as Yup from 'yup';
import { Store } from 'react-notifications-component';
import { forgot } from '../services/web/webServices';
export default function ForgotPassword() {
   const navigate = useNavigate();
   const [button, setButton] = useState(true);
   return (
      <>
         <div className="  login-main-outer">
            {/* <!-- Preloader Start  --> */}

            {/* <div className="preloader">
         <div className="loader"></div>
      </div> */}

            <div className="login-container">
               <div className="log-s">
                  <div className="login-left">
                     <div className="comlog abs-login1">
                        <img src="images/q1.png" />
                     </div>
                     <div className="comlog  abs-login2">
                        <img src="images/q2.png" />
                     </div>
                     <div className="comlog  abs-login3">
                        <img src="images/q3.png" />
                     </div>
                     <div className="comlog  abs-login4">
                        <img src="images/q4.jpg" />
                     </div>
                     <div className="comlog  abs-login5 forgottimg">
                        <img src="images/q1.png" />
                     </div>
                     <h3>Engineering, Sales, ICT, and Self development / coaching expertise.</h3>
                  </div>
                  <div className="login-right  share-que">
                     <div className="login-logo">
                        <Link to="/"><img src="images/logo.webp" alt="" /></Link>
                     </div>
                     <div className="login-ins-sec">
                        <div className="section-title  ">
                           <h2 className="title">Forgot Password</h2>
                        </div>

                        <Formik
                           initialValues={{
                              email: ""
                           }}
                           validationSchema={Yup.object({
                              email: Yup.string()
                                 .email("Invalid email address")
                                 .required("Email Id is required"),
                           })}
                           onSubmit={(values, { resetForm }) => {
                              setButton(false);

                              forgot(values)
                                 .then((res) => {
                                    setButton(true);
                                    Store.addNotification({
                                       title: "Success",
                                       message: res?.data?.message,
                                       type: "success",
                                       insert: "top",
                                       container: "top-right",
                                       animationIn: ["animate__animated", "animate__fadeIn"],
                                       animationOut: ["animate__animated", "animate__fadeOut"],
                                       dismiss: {
                                          duration: 5000,
                                          onScreen: true,
                                       },
                                    });
                                    resetForm({ values: "" });
                                    // localStorage.setItem(
                                    //    "auth_token",
                                    //    res?.data?.result?.authToken
                                    // );
                                    // navigate("/resetpassword");
                                 })
                                 .catch((err) => {
                                    setButton(true);
                                    if (err) {
                                       Store.addNotification({
                                          title: "Error!",
                                          message: err?.response?.data?.message,
                                          type: "danger",
                                          insert: "top",
                                          container: "top-right",
                                          animationIn: ["animate__animated", "animate__fadeIn"],
                                          animationOut: ["animate__animated", "animate__fadeOut"],
                                          dismiss: {
                                             duration: 5000,
                                             onScreen: true,
                                          },
                                       });
                                    }
                                 });
                           }}
                        >
                           <Form>

                              <div className="form-group">
                                 <label>Email</label>
                                 <MyTextInput
                                    type="email"
                                    name="email"
                                    placeholder="Email *"
                                 // required="true"
                                 />
                              </div>
                              {button == true ? <button className="btn btn-primary btn-hover-heading-color cmn-btn" type="submit">Reset Password</button> :<button className="btn btn-primary btn-hover-heading-color cmn-btn" type="button">wait please :)</button>}

                           </Form>
                        </Formik>



                     </div>
                  </div>
               </div>
            </div>

         </div>
         <Footer />
      </>
   )
}
